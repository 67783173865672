import React, { useState } from "react";
import { useAuth } from "../auth/auth.js";
// import { Redirect, Link } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { default as MaterialLink } from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';


import Paper from '@material-ui/core/Paper';

function Alert(props) {
    return <MuiAlert {...props} />;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin:'auto',
    },
    alert:{
        fontWeight: "bold",
        color: "white",
    },
    switch:{
        color:theme.palette.primary.main,
        textDecoration:"none",
        cursor:"text",
        '&:hover':{
            textDecoration:"underline",
        },
    }
}));

const Recover = () => {
    const classes = useStyles();
    const auth = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [snackbar,setSnackbar] = useState(false);
    const [open,setOpen] = useState(false);
    const [showPassword,setShowPassword] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        //better input checking!
        console.log(e);
        if(email!=""){ 
            auth.sendPasswordResetEmail(email)
                .then(()=>{console.log("done");setOpen(true)})
                .catch(e => { setError(e.message);setSnackbar(true)})
        }
    }

    if (auth.user === null) {
        return (
            <div className="container">
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
        )
    } else if (auth.user) {
        return (<Redirect to="/" />)
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper} elevation={3}>
                    <Typography component="h1" variant="h5">
                        Reinicia tu contraseña
                    </Typography>
                    <Box mt={2}>
                        <Typography component="h1" variant="subtitle1">
                            Ingresa el correo electronico que tienes asociado a tu cuenta y te mandaremos un mail con las instrucciones para reiniciar tu contraseña
                        </Typography>
                    </Box>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="correo@credifintech.com.mx"
                            name="email"
                            autoFocus
                            onChange={e => setEmail(e.target.value)}
                        />

                        <Collapse in={open}>
                            <Typography component="h1" variant="subtitle1">
                                Se ha enviado el correo
                            </Typography>
                        </Collapse>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e)=>handleSubmit(e)}
                        >
                            {"Enviar correo"+(open?" de nuevo":"")}
                    </Button>
                        <Grid container>
                            <Grid item xs>

                            </Grid>
                            <Grid item>
                                <Link to="/signin" className={classes.switch}>{"Regresar a inicio de sesión"}</Link>
                                {/* <MaterialLink href="#" variant="body2">
                            {" Registrate"}
                        </MaterialLink> */}
                            </Grid>
                        </Grid>
                    </form>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={snackbar}
                        autoHideDuration={6000}
                        onClose={()=>setSnackbar(false)}
                    >
                        <Alert onClose={()=>setSnackbar(false)} className={classes.alert} variant="filled" severity="error">
                            {error}
                        </Alert>     
                    </Snackbar>
                </Paper>
            </Container>
        );  
    }
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <MaterialLink color="inherit" href="https://credifintech.com.mx/">
                Credifintech
        </MaterialLink>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Recover;
