import firebase from '../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";

const CampaignsInfo = () => {
    
    const uploadCampaignFile = (file, path, uid) => {
        return firebase
            .storage()    
            .ref(path)
            .put(file, {
                 customMetadata: {
                    creator: uid
                }
            })
    }

    return {
        uploadCampaignFile,
    };
}

export default CampaignsInfo;
