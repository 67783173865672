export const promiseWithDelay = (delay, value) => {
    return new Promise(resolve => {
        setTimeout(resolve, delay, value);
    })
}

const firstCapRestLow = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const allFirstCapRestLow = (string) =>{
    return string.split(" ").map(e=>{return firstCapRestLow(e)})
}

export const summarize = (txt,length) =>{
    let str=txt;
    if(txt.length>length)
        str=txt.substring(0,length)+"..."
    return str;
}

export const summarizeOrPad = (txt, length, pad) => {
    if(txt.length>length)    
        return summarize(txt, length)
    const rem = length - txt.length;
    return txt +  pad.repeat(rem);
}

export const beautifyFirstName = (name) =>{
    return allFirstCapRestLow(name)[0];
}

export const beautifyName = (name) =>{
    if(!name)
        return "Sin Nombre"
    if(name.includes('/'))
        return name.split('/').map(e=>{return beautifyName(e)}).join(" ")
    return allFirstCapRestLow(name).join(" ")
}

const random_in_range = (mn, mx) => {
    return Math.random() * (mx - mn) + mn;
}

export const choice = (l) =>{
    return l[Math.floor(random_in_range(0,l.length))];
}

export const splitName = (unformattedName) =>{
    var name;
    if(unformattedName.includes('/'))
        name=unformattedName.replace(/\s+/g,' ').trim().split("/")
    else
        name=unformattedName.replace(/\s+/g,' ').trim().split(" ")
    const primerApellido=name.shift();
    const segundoApellido=name.shift();
    const nombre=name.join(" ");
    return {primerApellido,segundoApellido,nombre};
}

//Improve
export const timeAgo = (date) =>{

    if(!date)
        return "unos segundos"

    const now=new Date();
    const secondsAgo = Math.round((now-date)/1000);

    const MINUTE = 60,
        HOUR = MINUTE * 60,
        DAY = HOUR * 24,
        WEEK = DAY*7,
        MONTH = WEEK*4,
        YEAR = MONTH*12

    if (secondsAgo < MINUTE) {
        return "unos segundos";
    } else if (secondsAgo < HOUR) {
        let n=Math.floor(secondsAgo / MINUTE);
        return n+(n>1?" minutos":" minuto");
    } else if (secondsAgo < DAY) {
        let n=Math.floor(secondsAgo / HOUR);
        return n+(n>1?" horas":" hora")
    } else if (secondsAgo < WEEK){
        let n=Math.floor(secondsAgo / DAY);
        return n+(n>1?" días":" día")
    } else if (secondsAgo < MONTH){
        let n=Math.floor(secondsAgo / WEEK);
        return n+(n>1?" semanas":" semana")
    } else if (secondsAgo < YEAR){
        let n=Math.floor(secondsAgo / MONTH);
        return n+(n>1?" meses":" mes")
    } else {
        let n=Math.floor(secondsAgo / YEAR);
        return n+(n>1?" años":" año")
    }

}

export const removeAccentMarks = (s) => {
    const o = {
            'á':'a',
            'é':'e',
            'í':'i',
            'ó':'o',
            'ú':'u', 
            'Á':'A',
            'É':'E',
            'Í':'I',
            'Ó':'O',
            'Ú':'U',
    };   
    return s.split("").map(c => o[c] || c).join("");
}

export const pad = (str, size) => {
    var s = str+"";
    while (s.length < size) s = "0" + s;
    return s;
}

/**
 * Function to delay the execution of another function
 * @param {Miliseconds tu execute a function} time 
 * @returns execute a function that is passed
 */
export const delay = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }    


export const copyToClipboard = (text)=>{
    var textArea = document.createElement("textarea");
  
    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //
  
    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
  
    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';
  
    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;
  
    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
  
    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
  
  
    textArea.value = text;
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }
  
    document.body.removeChild(textArea);
}
