import React, { useContext, useState } from 'react';
import { Asesor } from './asesor';
import CoordCard from './coordcard';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box';
// import { useCoordInfo } from '../coordinfo/coordinfo';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import RolesContainer from './rolescontainer';
import { CoordInfoProvider,useCoordInfo } from '../coordinfo/coordinfo';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import AddAsesor from './addasesor';
import Button from '@material-ui/core/Button';
import Alert from './alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    cardroot: {
        minWidth: 275,
    },
    expand: {
        justifyContent: 'flex-end',
    },
    btn: {
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    alert: {
        fontWeight: "bold",
        color: "white",
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

const FloatingButton = ({ fab,onClick }) => {
    return (
        <Fab onClick={onClick} color="primary" aria-label="add" className={fab}>
            <AddIcon />
        </Fab>
    );
}


const CoordWrapper = () => {
    const classes = useStyles();
    const [value, setValue] = useState("coord");

    // const theme=useTheme();
    // const [show,setShow] = useState(false);
    // const transitionDuration = {
    //     enter: theme.transitions.duration.enteringScreen,
    //     exit: theme.transitions.duration.leavingScreen,
    // };

    // const zoom=(
    //     <Zoom 
    //         in={value==='coord'}
    //         timeout={transitionDuration}
    //         style={{
    //           transitionDelay: `${value==="coord" ? transitionDuration.exit : 0}ms`,
    //         }}
    //         unmountOnExit
    //     >
    //         <FloatingButton {...{fab:classes.fab}}/>
    //     </Zoom>
    // )

    return (
        <CoordInfoProvider>
            <Box>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={(e, v) => setValue(v)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab value="coord" label="Coordinador" />
                        <Tab value="regs" label="Contacto" />
                    </Tabs>
                </Paper>
                {value === 'coord' && <Coord value={value}/>}
                {value === 'regs' && <RolesContainer><Asesor userEmail/></RolesContainer>}

            </Box>
        </CoordInfoProvider>
    )
};

const Coord = (props) => {
    const [show,setShow] = useState(false);
    const [updated,setUpdated] = useState("");
    

    //const [value,setValue] = useState(null);
    const {value, width} = props;    
    const { data, loading, setPostalCodes,removeFromCoordList,setUserCoord,error,setError } = useCoordInfo();
    const classes = useStyles();


    const onComplete = (id) =>{
        setUserCoord(id)
            .then(()=>{
                setUpdated("Se ha asignado el asesor correctamente");
                setShow(!show);
            })
            .catch(e=>{
                setError("Ha ocurrido un error asignando al asesor");
            })
        
    }

    var jsx;

    if (loading) {
        jsx = (
            <Box mt={8}>
                <LinearProgress color="primary" />
            </Box>
        )
    }
    else if (data.length == 0) {
        jsx = (
            <Container>
                <Typography component="h4" variant="h4">
                    No tienes ningun asesor a tu cargo. Agrega a los asesores correspondientes!
                </Typography>
            </Container>
        )
    } else {
        jsx = (
            data.map(e => { const p = { "asesor": e.data(), "uid": e.id, setPostalCodes,removeFromCoordList,setError,setUpdated }; return (<CoordCard key={e.id} {...p} />) })
        )
    }

    return (
        <Box>
            <RolesContainer>
                {jsx}
                <Alert 
                    show={updated!=="" || error!=="" } 
                    setShow={(e)=>{setUpdated("");setError("")}} 
                    type={updated!==""?"success":"error"}
                    message={updated!==""?updated:error}
                    duration={3000}
                    overFab
                ></Alert>
            </RolesContainer>
            <FloatingButton onClick={()=>setShow(!show)} {...{ fab: classes.fab }} />
            <AddAsesor show={show} setShow={()=>setShow(!show)} onComplete={onComplete}/>
        </Box>
    );
}

export default CoordWrapper;



