import React, { useState, useEffect } from 'react';
import { fade,makeStyles } from '@material-ui/core/styles';
import Paginate from '../custompaginate.jsx';
import Box from '@material-ui/core/Box';
import AdminInfo from '../../admininfo/admininfo';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Navbar from '../navbar';
import BlockIcon from '@material-ui/icons/Block';
import {useViewport} from '../../viewport/viewport';
import {summarize} from '../../util/util';

const useStyles = makeStyles(theme => ({
    noPadding: {
        padding:'0',
    },    
    root: {
        padding: theme.spacing(2),
    },
}));

const Row = ({elem, reload}) => {
    const {width} = useViewport();
    const history = useHistory();
    const classes = useStyles();
    const {email, name, disabled} = elem.data();

    const handleClick = () => {
        history.push("/admin/users/"+elem.id);
    }

    return (
        <ListItem 
            dense
            button
            onClick={handleClick}
        >
            <ListItemAvatar>
                <Avatar>
                {
                    disabled?
                        <BlockIcon />
                    :
                        <PersonIcon />
                }
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={name} 
                secondaryTypographyProps={{noWrap:true}}
                secondary={email}
                disabled
            />          
        </ListItem>
    );
}


const UserList = () =>{
    const {fetchUsers} = AdminInfo();
    const classes = useStyles();

    return (
        <Box>
            <Navbar/>
            <Box mt={3}>
                <Container>
                    <Paper elevation={3} className={classes.root}>
                        <Paginate
                            getData = {fetchUsers}
                            renderElem = {(e,k,reload) =>{
                                return <Row 
                                    key={k}
                                    elem={e}
                                />
                            }} 
                            enableSearch={true}
                            searchPlaceholder={"Buscar Usuarios"}
                            limit={10}
                            headerName={"Lista de Usuarios"}
                            headerDescription={"lista de usuarios de sinar"}
                            descriptionColor={"#757575"} 
                            backPage={"/"}
                            emptyMessage={"No hay usuarios!"}
                            downloadUsersReport = {true}
                        />
                    </Paper>
                </Container>
            </Box>
        </Box>

    )

}

export default UserList;
