import React, { Fragment, useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Navbar from '../navbar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { beautifyName } from '../../util/util';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import Header from '../pageheader';
import Alert from '../alert';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Divider from '@material-ui/core/Divider';
import DragAndDropFiles from '../dragdropfiles';
import CampaignsInfo from '../../campaignsinfo/campaignsinfo';
import firebase from '../../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import { forEach } from 'lodash';

import green from '@material-ui/core/colors/green';

const color = green[500];

const useStyles = makeStyles((theme) => ({
    dragndrop: {
    },
    divider: {
        margin: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    inputWrapper: {
        height: "500px",
        width: "100%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
    },

    labelInput: {
        width: "100%",
        height: "100%",
        borderStyle: "dashed",
    },
    fileInput: {
        width: "100%",
        height: "100%",
        display: "none",
    },

}));

const AddNewFiles = () => {


    const classes = useStyles();
    const [error, setError] = useState("");
    const [updated, setUpdated] = useState("");
    const [loading, setLoading] = useState(false);
    const [progressBarPercentage, setProgressBarPercentage] = useState(0);
    const [currentUploadingFile, setCurrentUploadingFile] = useState('');
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const history = useHistory();


    /**
     * 
     * @param {files to upload to cloud storage} files 
     * This jsx file is a component function. It is oriented to show a UI inteface and upload files to cloud storage.
     */
    const handleOnAccept = async (files) => {
        let filesUploadedCorrectly, filesNotUploaded = 0;
        setLoading(true);
        filesUploadedCorrectly = await uploadFiles(files, setProgressBarPercentage, setCurrentUploadingFile, setShowProgressBar, setUploadedFiles);
        setLoading(false);
        let responseMessage = filesNotUploaded == 0 ? "." : (" y " + filesNotUploaded + " no fueron cargados.");
        setUpdated("Se cargaron exitosamente " + filesUploadedCorrectly + " archivos" + responseMessage);

        ResetValuesProgressBar();
    }

    const ResetValuesProgressBar = () => {
        setShowProgressBar(false);
        setCurrentUploadingFile('');
        setProgressBarPercentage(0);
    };

    const promptText = "Arrastra el archivo con cualquier extensión. La información será recibida por un medio seguro.";

    return (
        <Box>

            <Box mt={3}>
                <Container component="main">
                    <Paper className={classes.paper} elevation={3}>
                        <Header
                            loading={false}
                            pageName={"Subir archivo"}
                            backPage={"/"}
                            pageDescription={"Permite subir un archivo anónimo"}
                            descriptionColor={"#757575"}
                        />
                        <Divider className={classes.divider} />
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >

                            {showProgressBar ?
                            //se esta cargando archivo, no puedes subir mas
                                <Box mt={3} display={"flex"} justifyContent={"center"}>
                                    <Paper className={classes.paper}>
                                        <Typography variant='body1' color='primary' >{"Cargando archivo: " + currentUploadingFile}</Typography>
                                        <Grid>
                                            <LinearProgress variant="determinate" color='secondary' value={progressBarPercentage} />
                                            {progressBarPercentage === 100 ?
                                                <Typography variant='body2' color='primary' align='right' >
                                                    <CheckCircleIcon />
                                                </Typography>

                                                : <Fragment />}
                                        </Grid>
                                    </Paper>
                                </Box>
                                :
                                //no se esta cargando archivo, entonces puedes subir
                                <Grid item xs={10} sm={8}>
                                    <DragAndDropFiles
                                        className={classes.dragndrop}
                                        multiple={true}
                                        promptText={promptText}
                                        acceptButtonText={"Subir archivo"}
                                        acceptButtonLoading={loading}
                                        onAccept={handleOnAccept}
                                    />
                                </Grid>
                            }

                        </Grid>

                    </Paper>

                    <Box mt={3} mx={3}>
                        <Paper>

                            {uploadedFiles.length !== 0 ?
                                <Fragment>
                                    <Typography variant='h5' color='primary'>Archivos Cargados Correctamente</Typography>
                                    {uploadedFiles.map(file =>
                                        <Typography variant='body1' color={color}>{file}  <CheckCircleIcon /> </Typography>
                                    )}
                                </Fragment>
                                :
                                <Fragment />
                            }
                        </Paper>
                    </Box>
                </Container>
            </Box>
            <Alert
                show={updated !== "" || error !== ""}
                setShow={() => { setUpdated(""); setError("") }}
                type={updated !== "" ? "success" : "error"}
                message={updated !== "" ? updated : error}
                duration={3000}
            ></Alert>
        </Box>
    )
}

async function uploadFiles(files, progressBar, currentUploadingFile, showProgressBar, filesReady) {
    let filesUploadedCorrectly = 0;

    for (const file of files) {
        const path = `defaultFiles/${file.name}`;
        progressBar(0);
        currentUploadingFile('');

        try {
            await new Promise((resolve, reject) => {
                const uploadTask = firebase.storage().ref(path).put(file, {
                    customMetadata: {
                        creator: 0
                    }
                });

                uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                    showProgressBar(true);
                    currentUploadingFile(file.name);
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    progressBar(progress);
                    if (progress === 100)
                        filesReady(current => [...current, file.name]);
                }, reject, resolve);
            });

            filesUploadedCorrectly++;
        } catch (error) {
            console.log("Error uploading anonymous files: " + error.message);
        }
    }

    return filesUploadedCorrectly;
}


export default AddNewFiles;
