import React from "react";

import TextField from "@material-ui/core/TextField";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

const OutlinedBox = ({ children, label, disabled }) => {
return (
    <TextField
        disabled={disabled}
        fullWidth
        variant="outlined"
        label={label}
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
            inputComponent: InputComponent
        }}
        inputProps={{
            children: children
       
        }}
    />
);
};
export default OutlinedBox;
