import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    flexWrap: {
        display: "flex",
        flexWrap: "wrap"            
    },
    notFound: {
        padding: theme.spacing(3),
    },
    outlinedBox: {
        margin: theme.spacing(10),    
    },
    asesoresMargin: {
        marginRight: theme.spacing(2),
    },
    asesores: {
    },
    asesor: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        width: "100%",
    },
    title: {
        margin: theme.spacing(4, 0, 0),
    },
    root: {
        marginBottom: 2,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxHeight: 200,
        overflowY: "scroll",
        paddingTop: theme.spacing(2),
    },
    demo: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    disableUser: {
        //backgroundColor: "#e53935",
        '&:hover': {
              backgroundColor: '#ab000d',
        },
        '&:active': {
              backgroundColor: '#ff6f60',
        },
        color: "white",
        backgroundColor: "#e53935",
  
    },
    enableUser: {
        '&:hover': {
              backgroundColor: '#00600f',
        },
        '&:active': {
              backgroundColor: '#388e3c',
        },
        color: "white",
        backgroundColor: "#388e3c",
    },
    chipInput: {
        minHeight: "56px",
    },
    chipInputTextfield:{
        marginTop: theme.spacing(1),
        marginBottom: "18px",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    headerIcons:{
        paddingRight:theme.spacing(2),
    },
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(3,0,8),
    },
    chips:{
        marginTop: theme.spacing(1),
        display:"block",
    },
    divider: {
        margin: theme.spacing(2),
    },

  }));
