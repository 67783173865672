import React, { useState, useEffect, createContext, useContext } from "react";
import { useAuth } from '../auth/auth';
import firebase from '../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import { choice } from '../util/util';
import { splitName } from '../util/util';

export const contactInfoContext = createContext();

export const useContactInfo = () => {
    return useContext(contactInfoContext);
};

//TODO optimize re-renders!
//TODO better understanding of hooks and useEffect
export const ContactInfoProvider = ({ children }) => {
    const auth = useAuth();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [wheel, setWheel] = useState(false);
    const [error, setError] = useState("");

    const updateSurveyMessage = (id, prevSurveyOption, surveyOption, folio) => {
        const uid = auth.user.multiFactor.user.uid;
        const increment = firebase.firestore.FieldValue.increment(1);
        const decrement = firebase.firestore.FieldValue.increment(-1);



        const updatedContact = {
            surveyMessage: surveyOption.option,
        };
        if (folio)
            updatedContact["folio"] = folio;
        if (prevSurveyOption.category === surveyOption.category)
            return firebase.firestore().runTransaction(function (transaction) {
                const userRef = firebase
                    .firestore()
                    .collection("users")
                    .doc(uid);
                const contactRef = userRef
                    .collection("assigned")
                    .doc(id)
                return transaction.get(userRef).then(function (userDoc) {
                    transaction
                        .update(contactRef, updatedContact)
                });
            })
        else {
            const updatedUserDoc = {}
            updatedUserDoc[prevSurveyOption.category] = decrement;
            updatedUserDoc[surveyOption.category] = increment;
            return firebase.firestore().runTransaction(function (transaction) {
                //TODO: Use getAll in transaction!
                const userRef = firebase
                    .firestore()
                    .collection("users")
                    .doc(uid);
                const contactRef = userRef
                    .collection("assigned")
                    .doc(id)
                return transaction.get(userRef).then(function (userDoc) {
                    transaction
                        .update(userRef, updatedUserDoc)
                        .update(contactRef, updatedContact)
                });
            })
        }
    }

    // const noAnswer = () =>{
    //     const uid = auth.user.multiFactor.user.uid;
    //     return firebase.firestore().runTransaction(function(transaction) {
    //         const userRef=firebase
    //             .firestore()
    //             .collection("users")
    //             .doc(uid);
    //         const contactRef=userRef
    //             .collection("assigned")
    //             .doc(id)
    //         return transaction.get(contactRef).then(function(userDoc) {
    //             transaction
    //                 .update(contactRef,updatedContact)
    //         });
    //     })
    // }

    const fetchCompleted = ({ dayStart, dayEnd, startAfter, endAt }) => {
        const uid = auth.user.multiFactor.user.uid;
        let completed = firebase.firestore()
            .collection("users")
            .doc(uid)
            .collection("assigned")
            .where("completed", "==", true)
            .orderBy("completedAt", "desc")
        if (dayStart && dayEnd)
            completed = completed
                .where("completedAt", ">=", dayStart)
                .where("completedAt", "<", dayEnd);
        if (startAfter)
            completed = completed
                .startAfter(startAfter)
                .limit(11);
        if (endAt)
            completed = completed
                .endAt(endAt)
                .limitToLast(10);
        if ((!startAfter) && (!endAt))
            completed = completed
                .limit(11)
        return completed
            .get()
            .then(r => {
                return r.docs
            })
    }

    const getInterested = (limit, { startAfter, endAt }) => {
        const uid = auth.user.multiFactor.user.uid;
        let q = firebase.firestore()
            .collection("users")
            .doc(uid)
            .collection("assigned")
            .where("surveyMessage", "==", "Necesita más información")
            .where("completedAt", ">=", new Date(2020, 7, 4))
            .orderBy("completedAt", "desc");
        if (startAfter)
            q = q.startAfter(startAfter)
                .limit(limit)
        if (endAt)
            q = q.endAt(endAt)
                .limitToLast(limit - 1)
        if (!startAfter && !endAt)
            q = q.limit(limit)
        return q
            .get()
            .then(r => {
                return r.docs;
            }).catch(e => {
                console.log(e);
            });
    }


    const fetchAssigned = (uid) => {
        return firebase.firestore()
            .collection("users")
            .doc(uid)
            .collection("assigned")
            .where("completed", "==", false)
            .limit(1)
            .get()
            .then(q => { return q.docs })
            .catch(e => console.log(e, "probably permission error"))
    };

    const addToAssignedCollection = (uid, contact) => {
        contact["completed"] = false;
        contact["assignedAt"] = firebase.firestore.FieldValue.serverTimestamp();
        delete contact["USADO_TM"];
        return firebase.firestore()
            .collection("users")
            .doc(uid)
            .collection("assigned")
            .add(contact)
            .then(res => { return { ...contact, id: res.id } });
    }

    const assign = async (user) => {
        const cps = [...user.postalCodes]
        while (cps.length != 0) {
            const cp = choice(cps);
            const contact = await fetchAndMarkFirstContact(cp, user.multiFactor.user.uid)
            if (contact)
                return contact
            cps.splice(cps.indexOf(cp), 1)
        }
        return false;
    }

    //use transactions to avoid nasty race conditions
    const fetchAndMarkFirstContact = async (cp, uid) => {
        return firebase.firestore()
            .runTransaction(async transaction => {
                const query = firebase
                    .firestore()
                    .collection("Persona")
                    .where("CP", "==", cp)
                    .where("TELEFONO", ">", "")
                    .where("USADO_TM", "==", false)
                    .limit(1)
                const qsnap = await query.get();
                if (qsnap.docs.length == 0)
                    return false;
                const result = qsnap.docs[0];
                // This code may get re-run multiple times if there are conflicts.
                return transaction
                    .get(result.ref)
                    .then(res => {
                        const contact = res.data();
                        transaction.update(res.ref, {
                            USADO_TM: true
                        });
                        return addToAssignedCollection(uid, contact);
                    })
            });
    }

    //TODO: improve this! make a surveyMessage class!!!
    // const categorizeSurveyMessage=(surveyMessage)=>{
    //     // "No responde"
    //     // "El cliente ha fallecido"
    //     // "No es el cliente"
    //     // "El número no existe"
    //     // "No vive ahí"
    //     // "No quiere que le vuelvan a marcar"
    //     // "Se inicia trámite de crédito"
    //     // "Necesita más información"
    //     const negative=["El cliente ha fallecido","El número no existe","No quiere que le vuelvan a marcar","No responde"]
    //     const later=["Necesita más información"]
    //     const positive=["Se inicia trámite de crédito"]
    //     if(positive.includes(surveyMessage))
    //         return "positiveAnswers";
    //     else if(later.includes(surveyMessage))
    //         return "laterAnswers";
    //     return "negativeAnswers";
    // }

    //TODO: as a transaction!!
    const completeContact = async (contact, surveyAnswer) => {
        try {
            const uid = auth.user.multiFactor.user.uid;
            const id = contact["id"];
            const surveyMessage = surveyAnswer?.option;
            const surveyType = surveyAnswer?.category + "Answers";
            const increment = firebase.firestore.FieldValue.increment(1);
            const decrement = firebase.firestore.FieldValue.increment(-1);
            const timestamp = firebase.firestore.FieldValue.serverTimestamp();
            const updatedUserDoc = {
                completedRecords: increment,
                assignedCount: decrement,
            };
            updatedUserDoc[surveyType] = increment;
            const updatedContact = {
                completedAt: timestamp,
                completed: true,
                surveyMessage: surveyMessage,
            };

            if (surveyAnswer?.option === "No responde") {
                const handleNoResponse = firebase.functions().httpsCallable(
                    "callable-contactFun-noResponse"
                );
                await handleNoResponse({ personaId: contact.personaId });
            }

            if (contact.folio) updatedContact["folio"] = contact.folio;

            const userRef = firebase.firestore().collection("users").doc(uid);
            const contactRef = userRef.collection("assigned").doc(id);

            await firebase.firestore().runTransaction(async (transaction) => {
                transaction.update(userRef, updatedUserDoc).update(contactRef, updatedContact);
            });

            return "Contact completed successfully";
        } catch (error) {
            console.log("Error completing contact:", error);
            throw error;
        }
    };

    //implement survey type
    function incrementCounter(uid, surveyType) {
        const increment = firebase.firestore.FieldValue.increment(1);
        const toAdd = {
            "completedRecords": increment,
        };
        toAdd[surveyType] = increment;
        return firebase.firestore()
            .collection("users")
            .doc(uid)
            .update(toAdd)
    }

    const assignNewRegister = () => {
        setLoading(true);
        const user = auth.user;
        fetchAssigned(user.multiFactor.user.uid)
            .then(assigned => {
                if (assigned.length === 0) {
                    //handle case when contact is false (which means there are no more available registers corresponding to those cps)
                    assign(user).then(contact => {
                        setData(contact);
                        setLoading(false);
                    }).catch(e => setError(e))
                } else {
                    const contact = { ...assigned[0].data(), id: assigned[0].id }
                    setData(contact);
                    setLoading(false);
                }
            }).catch(e => setError(e))
    }

    const formatSiviRequest = (contact) => {
        const {
            NOMBRE,
            CONVENIO,
            IDENTIFICADOR,
            CAPACIDAD,
            LIQUIDO,
            CUENTA_1,
            CURP,
            RFC,
            TELEFONO,
            TELEFONO2
        } = contact;
        //format convenio
        let idTipoEmpleado = 0;
        let matricula = "";
        let nss = "";
        let rfc = RFC ? RFC : "";
        switch (CONVENIO) {
            case 'IMSS_PENSIONADOS':
                nss = IDENTIFICADOR;
                idTipoEmpleado = 1032;
                break;
            case 'IMSS_ACTIVOS':
                matricula = IDENTIFICADOR;
                idTipoEmpleado = 1043;
                break;
            case 'IMSS_JUBILADOS':
                matricula = IDENTIFICADOR;
                idTipoEmpleado = 1050;
                break;
            case 'SNTE_18_MICHOACAN':
                idTipoEmpleado = 1060;
                break;
            case 'SEP_CDMX':
                idTipoEmpleado = 15;
                break;
            case 'PEMEX':
                idTipoEmpleado = 1048;
                break;
            default:
                console.log("convenio not recognized:" + CONVENIO);
        }

        // if liquido, clave and capacidad
        const cuenta1 = CUENTA_1 ? CUENTA_1 : null;
        let clabe;
        if (cuenta1) {
            const { CLABE, NUM_CUENTA } = cuenta1;
            if (CLABE)
                clabe = CLABE;
            else if (NUM_CUENTA)
                clabe = NUM_CUENTA;
            else
                clabe = "";
        }
        else
            clabe = "";
        const capacidad = CAPACIDAD ? CAPACIDAD : "";
        const curp = CURP ? CURP : "";
        const liquido = LIQUIDO ? String(LIQUIDO[0]) : "";
        const emailAsesor = auth.user.email;
        const telefono = TELEFONO ? TELEFONO : "";
        const telefono2 = TELEFONO2 ? TELEFONO2 : "";

        /*TODO: add Telefono*/
        return {
            ...splitName(NOMBRE),
            rfc,
            nss,
            matricula,
            liquido,
            capacidad,
            clabe,
            curp,
            idTipoEmpleado,
            emailAsesor,
            telefono,
            telefono2
        };
    }

    /*
        {
            "primerApellido": "SAR",
            "segundoApellido": "CAS",
            "nombre": "JES GAB",
            "rfc": "CACJ850818415",
            "curp": "CACJ850818HSLBZL03",
            "nss": "12345678911",
            "matricula": "9874455",
            "liquido": "5000.00",
            "capacidad": "1700.00",
            "clabe": "123456789012345678",
            "idTipoEmpleado":2,
            "emailAsesor": "JULIANCABANILLAS878@GMAIL.COM" 
        }
    */
    const sendInfoToSivi = (data) => {
        // console.log(formatSiviRequest(data));
        let xhr = new XMLHttpRequest();
        let url = process.env.REACT_APP_SIVI_URL;

        return new Promise(function (resolve, reject) {
            xhr.onreadystatechange = function () {
                if (xhr.readyState !== 4) return
                if (xhr.status >= 200 && xhr.status < 300)
                    resolve(xhr)
                else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    })
                }
            }
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json")
            xhr.send(JSON.stringify(formatSiviRequest(data)));
        });
    }

    useEffect(() => {
        const assignContact = firebase
            .functions()
            .httpsCallable('callable-contactFun-assign');
        setLoading(true);
        const user = auth.user;
        const unsubscribe = firebase
            .firestore()
            .collection("users")
            .doc(auth.user.multiFactor.user.uid)
            .collection('assigned')
            .where("completed", "==", false)
            .limit(1)
            .onSnapshot(
                qr => {
                    setWheel(false);
                    if (qr.docs.length === 0) {
                        //The first time to assign a contact
                        setLoading(true)
                        console.log("running cloud function");
                        assignContact().then(r => {
                            const { code, message } = r.data;
                            console.log(code, message);
                            if (code === 'no-more-cps') {
                                setData(false);
                                setLoading(false)
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                    else {
                        //The next time that the user login to check the 'persona' assigned
                        const r = qr.docs[0];
                        setData({ ...r.data(), id: r.id })
                        setLoading(false)
                    }
                },
                err => {
                    setError(err)
                }
            );
        return () => unsubscribe()

    }, [])

    const ret = {
        loading,
        data,
        error,
        wheel,
        setWheel,
        sendInfoToSivi,
        setError,
        completeContact,
        getInterested,
        fetchCompleted,
        updateSurveyMessage
    }

    return <contactInfoContext.Provider value={ret}>{children}</contactInfoContext.Provider>;

}
