import React, { useState,useEffect } from "react";
import { useAuth } from "../auth/auth.js";
// import { Redirect, Link } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { default as MaterialLink } from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from './alert';

import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert:{
        fontWeight: "bold",
        color: "white",
    },
    materialLink:{
        color:theme.palette.primary.main,
        textDecoration:"none",
        cursor:"text",
        '&:hover':{
            textDecoration:"underline",
        },
    }
}));

const SignIn = () => {
    const classes = useStyles();
    const auth = useAuth();
    const [emailError, setEmailError] = useState(false);
    const [email, setEmail] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword,setShowPassword] = useState(false);
    const [loading,setLoading] = useState(false);

    const handleEmailValidation = () => {
        if(
            /.+@(credifintech(.com)?.mx|viraal.com.mx|consubanco.com)/
                .test(email)
        )
            return true
        else{
            setEmailError(true);
            setError(
                "El correo tiene que ser uno de la forma @credifintech.com.mx ó bien @viraal.com.mx"
            );
            return false;
        }
    }

    const onEmailChange = e => {
        if(emailError)
            setEmailError(false);
        setEmail(e.target.value);
    }

    const onPasswordChange = e => {
        if(passwordError)
            setPasswordError(false);
        setPassword(e.target.value);
    }

    const handleSignin = () => {
        auth.signin(email, password)
            .catch(e => {
                let decoded="";
                switch(e.code){
                    case 'auth/wrong-password':
                        decoded="Contraseña incorrecta.";
                        setPasswordError(true);
                        break;
                    case 'auth/user-not-found':
                        decoded="No se encontró usuario asociado a ese correo.";
                        setEmailError(true);
                        break;
                    case 'auth/invalid-email':
                        decoded="El email no es valido.";
                        setEmailError(true);
                        break;
                    case 'auth/user-disabled':
                        decoded="Usuario se ha deshabilitado."
                        break;
                    default:
                        decoded="Ha ocurrido un error inesperado. Contacte al desarrollador";
                }
                setError(decoded);
            })

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if(handleEmailValidation())
            handleSignin();

    }

    if (auth.user === null) {
        return (
            <div className="container">
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
        )
    } else if (auth.user) {
        return (<Redirect to="/" />)
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper} elevation={3}>
                    <Typography component="h1" variant="h5">
                        Inicia Sesión
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="correo@credifintech.com.mx"
                            name="email"
                            autoFocus
                            error = {emailError}
                            onChange={onEmailChange}
                        />
                        <FormControl
                            fullWidth
                            variant="outlined"
                            className={classes.form}
                        >
                            <InputLabel
                                htmlFor="password"
                                error = {passwordError}
                            >
                                Contraseña
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                labelWidth={85}
                                error = {passwordError}
                                onChange={onPasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>


                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                            disabled={loading&&(error==="")}
                        >
                            {(loading&&(error===""))?<CircularProgress size={25}/>:"Inicia Sesión"}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/recover" className={classes.materialLink}>
                                    ¿Olvidaste tu contraseña?
                                </Link>
                            </Grid>
                            <Grid item>
                                ¿No tienes cuenta? <Link to="/register" className={classes.materialLink}>{"Regístrate"}</Link>
                            </Grid>
                        </Grid>
                    </form>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                    <Alert 
                            show={ error!=="" } 
                            setShow={()=>{setLoading(false);setError("");}} 
                            message={error}
                            type="error"
                            duration={3000}
                        ></Alert>
                </Paper>
            </Container>
        );
    }
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <MaterialLink color="inherit" href="https://credifintech.com.mx/">
                Credifintech
        </MaterialLink>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default SignIn;
