import React, { useContext, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import AvailableAsesores from '../coordinfo/availables';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '0px',
        padding: '0px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(0),
        width: '100%',
    },
}));


const AddAsesor = ({show,setShow,onComplete}) => {
    const classes=useStyles();
    const [value,setValue] = useState(null);

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={show} onClose={setShow}>
            <DialogTitle>Agrega asesor</DialogTitle>
            <DialogContent>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <AvailableAsesores onChange={(e,v)=>setValue(v)}/>
                        </FormControl>
                    </form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={setShow}>
                    Cancelar
        </Button>
                <Button color="primary" onClick={()=>onComplete(value.id)} disabled={value===""}>
                    Completar
        </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddAsesor;

// const select = (
//     <Autocomplete
//         id="size-small-outlined"
//         options={top100Films}
//         getOptionLabel={(option) => option.title}
//         defaultValue={top100Films[1]}
//         renderInput={(params) => (
//             <TextField {...params} variant="outlined" label="Size small" placeholder="Favorites" />
//         )}
//     />
// )
