import React, { useContext, useState, useEffect } from 'react';
import Chart from "react-google-charts";
import { promiseWithDelay } from "../../util/util";
import Header from '../pageheader';
import Box from '@material-ui/core/Box';
import Navbar from '../navbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles'; 
import Divider from '@material-ui/core/Divider';
import PerformanceReport from '../../charts/performance';
import CubejsTest from './cubejs'; 


const useStyles = makeStyles( theme => ({
    paper: {
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2),
    },
}))

const ChartTest = () => {
    const [data, setData] = useState([]);
    const classes = useStyles();
    const { totalCalls } = PerformanceReport(); 

    const chart = <CubejsTest/>;

    return (
        <Box>
            <Navbar/>
            <Box mt = {3}>
                <Container component = "main">
                    <Paper 
                        className={classes.paper}
                        elevation={3}
                    >
                        <Header
                            loading = {data === null}
                            pageName = {"Prueba"}
                            backPage = {"/"}
                            pageDescription = {"Esto es una prueba"}
                            descriptionColor = {"#757575"}
                        /> 
                        <Divider className = {classes.divider}/>
                        {
                            data
                            ? chart
                            : <Box/>

                        }
                    </Paper>
                </Container>
            </Box>
        </Box>
    );
}

export default ChartTest
