import React from "react";
import { Redirect,Route } from "react-router-dom";
import { useAuth } from "../auth/auth.js";
import LinearProgress from '@material-ui/core/LinearProgress'; 
import Box from '@material-ui/core/Box';
import Loading from './loadingpage';
import AlreadyLogged from './alreadylogged';
import { ContactInfoProvider } from '../contactinfo/contactinfo';

export const LoggedOutRoute = ({ component: RouteComponent, ...rest }) => {
  const auth=useAuth();

  const handleProps = (user,routeProps) => {
    if(user===false){
      return (<RouteComponent {...routeProps}/>)
    } else if(user===null){
      return (
        <Box mt={8}>
          <Loading/>
        </Box>
      ) 
    } else{ /*logged in!*/
      return <Redirect to={"/"}/>;
    }
  }

  return <Route
      {...rest}
      render={routeProps=>handleProps(auth.user,routeProps)}
    />
};

export const AdminRoute = ({ component: RouteComponent, ...rest }) => {
  const auth=useAuth();

  const handleProps = (user,routeProps) => {
    if(user===null){
      return (
        <Box mt={8}>
          <Loading/>
        </Box>
      )
    } else if(user===false){
      return (<Redirect to={"/signin"}/>)
    }else if(!user.multiFactor.user.uid){
      return (<Redirect to={"/unverified"}/>)
    }else if (!user.isAdmin){
      return <Redirect to={"/"}/>
    }
    return (
        <ContactInfoProvider>
          <RouteComponent {...routeProps}/>
        </ContactInfoProvider>
      )
  }

  return <Route
      {...rest}
      render={routeProps=>handleProps(auth.user,routeProps)}
    />
};

export const VerifiedRoute = ({ component: RouteComponent, ...rest }) => {
    const auth=useAuth();

    const handleProps = (user,routeProps) => {
      if(user===null){
        return (
          <Box mt={8}>
            <Loading/>
          </Box>
        )
      } else if(user===false){
        return (<Redirect to={"/signin"}/>)
      }else if(!user.multiFactor.user.uid){
        return (<Redirect to={"/unverified"}/>)
      /*} else if(!user.phoneNumber){
          return <Redirect to={"/addphone"}/>*/
      } else{
        return (
          <ContactInfoProvider>
            <RouteComponent {...routeProps}/>
          </ContactInfoProvider>
        )
      }
    }

    return <Route
        {...rest}
        render={routeProps=>handleProps(auth.user,routeProps)}
      />
};

export const NoPhoneRoute = ({ component: RouteComponent, ...rest }) => {
  const auth=useAuth();
  const handleProps = (user,routeProps) => {
    if(user===null){
      return (
        <Box mt={8}>
          <Loading/>
        </Box>
      )
    } else if(user===false){
      return <Redirect to={"/signin"}/>
    } else if(!user.multiFactor.user.uid){
      return <Redirect to={"/unverified"}/>
    } else if(user.phoneNumber){
      return <Redirect to={"/"}/>
    } else{
      return <RouteComponent {...routeProps}/>
    }
  }

  return (
    <Route
      {...rest}
      render={routeProps=>handleProps(auth.user,routeProps)}
    />
  );
}


export const UnverifiedRoute = ({ component: RouteComponent, ...rest }) => {
  const auth=useAuth();
  const handleProps = (user,routeProps) => {
    if(user===null){
      return (
        <Box mt={8}>
          <Loading/>
        </Box>
      )
    } else if(user===false){
      return <Redirect to={"/signin"}/>
    } else if(user.multiFactor.user.uid){
      return <Redirect to={"/"}/>
    } else{
      console.log("unverified route");
      return <RouteComponent {...routeProps}/>
    }
  }

  return (
    <Route
      {...rest}
      render={routeProps=>handleProps(auth.user,routeProps)}
    />
  );
};

