import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container'

const RolesContainer=(props) => {
    return (
        <Box mt={3}>
            <Container>
                {props.children}
            </Container>
        </Box>
    );
}


export default RolesContainer;