import {
    Box, Container, Divider, Paper,
    FormLabel,
    FormGroup,
    Input,
    FormControl,
    Button,
    Grid
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react'
import Navbar from '../navbar';
import Header from '../pageheader';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '../alert';
import firebase from '../../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    formControl: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        alignContent: "center"
    },
    formControlCellPhone: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        alignContent: "center"
    },
    formGroup: {
        marginBottom: "10px",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
    },
    buttonSave: {
        height: "40px",
        marginTop: "20px"
    },
    boxNotFoundData: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
    }

}));


function SearchInformation() {

    const classes = useStyles();
    const matches = useMediaQuery('(max-width:555px)');
    const [code, setCode] = useState("");
    const [telephone, setTelephone] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [tableRows, setTableRows] = useState([]);
    const [loadingInformation, setLoadingInformation] = useState(false);

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }

    const handleTelephoneChange = (e) => {
        setTelephone(e.target.value);
    }

    const handleSearchClick = async () => {
        setLoadingInformation(true);
        const getInformation = firebase
            .functions()
            .httpsCallable('callable-contactFun-fetchPersonas');

        const requestInfo = {}

        if (telephone !== "") {
            requestInfo.TELEFONO = telephone
        }
        if (code !== "") {
            requestInfo.IDENTIFICADOR = code;
        }

        try {
            const informationRequest = await getInformation(requestInfo);
            const information = informationRequest.data;
            if (information.code === "not-found") {
                setMessageAlert(information.message);
                setShowAlert(true);
                setTableRows([]);
            }
            if (information.code === "success")
                setTableRows(information.data);
            //console.log(information.data);

        } catch (e) {
            console.log(e);
        } finally {
            setLoadingInformation(false);
        }
    }

    const handleAlertClose = () => {
        setShowAlert(false);
    }

    return (
        <Box>
            <Navbar />
            <Box mt={3}>
                <Grid>
                    <Paper>
                        <Header
                            loading={false}
                            pageName={"Buscar Clientes"}
                            backPage={"/"}
                            pageDescription={"Busca clientes por diferentes filtros"}
                            descriptionColor={"#757575"}
                        />
                        <Divider className={classes.divider} />
                        <FormGroup className={matches ? classes.formControlCellPhone : classes.formControl}>
                            <FormControl className={classes.formGroup}>
                                <FormLabel>Busqueda: </FormLabel>
                                <Input color='primary' placeholder='Ingresa datos a buscar' value={code} onChange={(e) => handleCodeChange(e)} name='codigo'></Input>
                            </FormControl>
                            <FormControl className={classes.formGroup}>
                                <FormLabel>Teléfono: </FormLabel>
                                <Input type='tel' color='primary'
                                 placeholder='Ingresa un diez digitos' value={telephone}
                                 onChange={(e) => handleTelephoneChange(e)} name='telefono'></Input>
                            </FormControl>
                            <FormControl className={classes.formGroup}>
                                <Button color='primary' variant='contained'
                                    onClick={(e) => handleSearchClick(e)}
                                    className={classes.buttonSave}
                                    disabled={loadingInformation}
                                > <SearchIcon /> Buscar</Button>
                            </FormControl>
                        </FormGroup>

                        <Divider />

                        <Container>
                            <Box py={3} textAlign={"center"}>
                                {showAlert ?
                                    <Alert
                                        show={showAlert}
                                        type={"error"}
                                        message={messageAlert}
                                        duration={5000}
                                        setShow={handleAlertClose} />
                                    :
                                    loadingInformation ?
                                        <CircularProgress />
                                        :
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Campañas</TableCell>
                                                        <TableCell align="center">Convenio</TableCell>
                                                        <TableCell align="center">CURP</TableCell>
                                                        <TableCell align="center">Extra</TableCell>
                                                        <TableCell align="center">Identificador</TableCell>
                                                        <TableCell align="center">Nombre</TableCell>
                                                        <TableCell align="center">Oferta de Crédito Nuevo</TableCell>
                                                        <TableCell align="center">Teléfono</TableCell>
                                                        <TableCell align="center">Teléfono 2</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tableRows.map((row) => (
                                                        <TableRow key={row.CURP}>
                                                            <TableCell component="th" scope="row">
                                                                {row.CAMPAIGNS.map(campaign => <span key={campaign} > {campaign} </span>)}
                                                            </TableCell>
                                                            <TableCell align="center">{row.CONVENIO}</TableCell>
                                                            <TableCell align="center">{row.CURP}</TableCell>
                                                            <TableCell align="center">{row.EXTRA}</TableCell>
                                                            <TableCell align="center">{row.IDENTIFICADOR}</TableCell>
                                                            <TableCell align="center">{row.NOMBRE}</TableCell>
                                                            <TableCell align="center">{row.OFERTA_CREDITO_NUEVO}</TableCell>
                                                            <TableCell align="center">{row.TELEFONO}</TableCell>
                                                            <TableCell align="center">{row.TELEFONO2}</TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                }

                            </Box>

                        </Container>

                    </Paper>
                </Grid>
            </Box>
        </Box>
    )
}

export default SearchInformation;