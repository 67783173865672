import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

const convenios = [
	["1001","SNTE 1 AGUASCALIENTES" ],
	["1002","SNTE 3 BAJA CALIFORNIA SUR"],
	["1003","GOBIERNO DEL ESTADO BAJA CALIFORNIA SUR"],
	["1004","SNTE 37 BAJA CALIFORNIA"],
	["1005","SNTE 4 CAMPECHE"],
	["1007","GOBIERNO ACTIVOS CDMX"],
	["1008","LICONSA"],
	["1009","DICONSA"],
	["1010","SEGALMEX CDMX"],
	["1011","TESCO CDMX"],
	["1012","TRANSBALCO CDMX"],
	["1013","GOBIERNO ACTIVOS CHIAPAS"],
	["1014","SNTE 35 FEDERAL COAHUILA"],
	["1015","SNTE 5 FEDERAL COAHUILA"],
	["1016","GOBIERNO ACTIVOS COLIMA"],
	["1017","SNTE 12 DURANGO"],
	["1018","CONALEP DURANGO"],
	["1019","TRIBUNAL SUPERIOR DE JUSTICIA DEL ESTADO DE DURANGO"],
	["1020","AYUNTAMIENTO DE CANATLAN, DURANGO"],
	["1021","AYUNTAMIENTO DE NOMBRE DE DIOS DURANGO"],
	["1022","INSTITUTO DE CULTURA DURANGO"],
	["1023","SECRETARIA DE SALUD DURANGO"],
	["1024","UNIVERSIDAD TECNOLOGICA (METROPOLITANA) ESTADO DE MEXICO"],
	["1025","UNIVERSIDAD TECNOLOGICA (NEZA) ESTADO DE MEXICO"],
	["1026","UNIVERSIDAD TECNOLOGICA (TEPEACA) ESTADO DE MEXICO"],
	["1030","SNTE 17 ESTADO DE MEXICO"],
	["1031","GOBIERNO ACTIVOS ESTADO DE MEXICO"],
	["1032","IMSS PENSIONADOS LEY FEDERAL"],
	["1034","IMSS CONFIANZA ESTATUTO A Y MANDO FEDERAL"],
	["1035","IMSS PARITARIA FEDERAL"],
	["1038","UNAM FEDERAL"],
	["1039","BECAS PARA EL BIENESTAR BENITO JUAREZ (PROSPERA) FEDERAL"],
	["1040","TRIBUNAL FEDERAL DE JUSTICIA"],
	["1041","INE FEDERAL"],
	["1042","INBA FEDERAL"],
	["1043","IMSS ACTIVOS FEDERAL"],
	["1044","ISSEMYM"],
	["1045","ISSSTE ACTIVOS Y JUBILADOS FEDERAL"],
	["1046","CFE ACTIVOS Y JUBILADOS FEDERAL"],
	["1047","PEMEX ACTIVOS Y JUBILADOS FEDERAL"],
	["1048","PEMEX JUBILADOS FEDERAL"],
	["1049","ISSSTE FEDERAL"],
	["1050","IMSS JUBILADOS FEDERAL"],
	["1052","SECRETARIA DE EDUCACION GUERRERO"],
	["1053","SNTE 14 GUERRERO"],
	["1054","COBAEH HIDALGO"],
	["1056","GOBIERNO DEL ESTADO HIDALGO"],
	["1058","SNTE 16 JALISCO"],
	["1060","SNTE 18 MICHOACAN"],
	["1061","GOBIERNO DEL ESTADO JALISCO"],
	["1063","COBAEM MORELOS"],
	["1064","GOBIERNO DEL ESTADO MORELOS"],
	["1065","SECRETARIA DE SALUD MORELOS"],
	["1067","SNTE 22 IEEPO OAXACA"],
	["1068","COBAO OAXACA"],
	["1069","PENSIONADOS DE GOBIERNO OAXACA"],
	["1070","GOBIERNO DEL ESTADO OAXACA"],
	["1071","CAMINOS Y AEROPISTAS OAXACA"],
	["1072","CONGRESO DE OAXACA"],
	["1074","SNTE 22 OAXACA"],
	["1075","SNTE 23/51 PUEBLA"],
	["1076","SNTE 23 PUEBLA"],
	["1077","GOBIERNO ACTIVOS DEL ESTADO PUEBLA"],
	["1079","SNTE 24 USEBEQ QUERETARO"],
	["1080","SERVICIOS EDUCATIVOS QUINTANA ROO"],
	["1081","SNTE 26 SAN LUIS POTOSI"],
	["1083","CECYTE SAN LUIS POTOSI"],
	["1084","OFICIALIA MAYOR SAN LUIS POTOSI"],
	["1085","SNTE 28 SEES FEDERAL SONORA"],
	["1087","GOBIERNO ACTIVOS DEL ESTADO SONORA"],
	["1088","SNTE 29 TABASCO"],
	["1090","SERVICIO DE SALUD TAMAULIPAS"],
	["1092","SECRETARIA DE EDUCACION TAMAULIPAS"],
	["1093","CONALEP TAMAULIPAS"],
	["1094","UNIVERSIDAD AUTONOMA DE TAMAULIPAS UAT"],
	["1095","GOBIERNO DEL ESTADO TAMAULIPAS"],
	["1096","SERVICIO DE SALUD TLAXCALA"],
	["1098","PODER JUDICIAL DEL ESTADO VERACRUZ"],
	["1100","ICATVER (SOLO ADMINISTRATIVOS) VERACRUZ"],
	["1101","CAEV VERACRUZ"],
	["1102","GOBIERNO ACTIVOS Y JUBILADOS VERACRUZ"],
	["1103","SNTE 32 VERACRUZ"],
	["1104","SNTE 58 SEDUZAC ZACATECAS"],
	["1105","GOBIERNO ACTIVOS ZACATECAS"],
	["1106","CECYTE DURANGO"],
	["1107","COMISIÓN ESTATAL DE SERVICIOS PÚBLICOS DE MEXICALI"],
	["1110","COMISIÓN ESTATAL DE SERVICIOS PÚBLICOS DE TÉCATE"],
	["1111","CONALEP CHIHUAHUA"],
	["1112","CONALEP MICHOACÁN"],
	["1113","GOBIERNO DEL ESTADO DE NUEVO LEÓN"],
	["1114","GOBIERNO DEL ESTADO DE OAXACA - CENTRALIZADO"],
	["1115","GOBIERNO DEL ESTADO DE OAXACA - DESCENTRALIZADO"],
	["1116","HOSPITAL GENERAL DE MÉXICO"],
	["1117","HOSPITAL INFANTIL DE MÉXICO"],
	["1118","HOSPITAL JUAREZ"],
	["1119","INSTITUTO NACIONAL DE CANCEROLOGÍA"],
	["1120","INSTITUTO NACIONAL DE CARDIOLOGÍA IGNACIO CHAVEZ"],
	["1121","INSTITUTO NACIONAL DE ENFERMEDADES RESPIRATORIAS"],
	["1122","INSTITUTO NACIONAL DE MEDICINA GENÓMICA"],
	["1123","INSTITUTO NACIONAL DE NEUROLOGÍA"],
	["1124","SERVICIOS DE SALUD JALISCO"],
	["1125","PODER EJECUTIVO DEL ESTADO DE QUINTANA ROO"],
	["1126","SADER"],
	["1127","SERVICIOS DE SALUD CDMX"],
	["1128","SERVICIOS DE SALUD DE GUERRERO"],
	["1129","SERVICIOS DE SALUD DE COAHUILA"],
	["1130","SERVICIOS DE SALUD DE SAN LUIS POTOSI"],
	["1131","SERVICIOS DE SALUD DE PUEBLA"],
	["1132","SNTE SECC 44 DURANGO ESTATAL"],
	["1133","SERVICIOS DE SALUD DE TABASCO"],
	["1134","UNIVERSIDAD POPULAR DE LA CHONTALPA"],
	["1135","CECYTE OAXACA"],
	["1136","COBACH BAJA CALIFORNIA"],
	["1137","SECRETARIA DE EDUCACION DE ZACATECAS"],
	["1138","INSTITUTO DE SERVICIOS EDUCATIVOS Y PEDAGOGICOS DE BAJA CALIFORNIA"],
	["1139","FISCALIA GENERAL DE TAMAULIPAS"],
	["1140","FISCALIA GENERAL DE TABASCO"],
	["1141","GOBIERNO DEL ESTADO DE YUCATAN"],
]

const ConvenioSelector = () => {

    const [selected, setSelected] = useState(null);

    const options = convenios.map(e => {
        return {
            id: e[0],    
            tipoDeEmpleado: e[1],
        };
    })

    const renderInput = params => {
        return (
            <TextField
                {...params}
                label = {"Convenios"}
                variant = "outlined"
            />
        );
    }

    const handleChange = (e, nValue) => {
        setSelected(nValue);    
    }

    const shouldBeSelected = (option, target) => {
        return option.tipoDeEmpleado.includes(target);
    }

    return (
        <Autocomplete
            onChange = { handleChange }

            options = {options}
            getOptionLabel = {option => option.tipoDeEmpleado } 

            getOptionSelected = { shouldBeSelected }
            
            renderInput = { renderInput }
            noOptionsText = { "No se encontró convenio" }
        />
    );
}

export default ConvenioSelector;
