import React, {useState, useRef, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useViewport} from '../viewport/viewport';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import {summarize} from '../util/util';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { forEach } from 'lodash';

const useStyles = makeStyles((theme) => ({
    inputButtonWrapper: {
        marginTop: theme.spacing(2),
    },
    cloudIcon: {
        marginTop: theme.spacing(2),
    },
    inputButton: {
        marginBottom: theme.spacing(2),
    },
    chip: {
        marginBottom: theme.spacing(1),
    },
    textFileItem: {
        margin: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2),        
    },
    hide: {
        display: "none",
    },
    dropArea: {
        border: "2px",
        borderStyle: "dashed",
        borderColor: "#ccc",
        borderRadius: "20px",
        width: "100%",
        background: "#f2f2f2",
        paddingTop: theme.spacing(2),
    },
    dropAreaDrag: {
        border: "2px",
        borderStyle: "dashed",
        borderColor: "#ccc",
        borderRadius: "20px",
        width: "100%",
        paddingTop: theme.spacing(2),
        background: "#ddd",
    },
    button: {
        display: "inline-block",
        padding: "10px",
        background: "#ccc",
        cursor: "pointer",
        borderRadius: "5px",
    },
}));

const FileItem = ({file, handleDelete, width}) => {
    const classes = useStyles();
    const avatar = ( 
        <Avatar>
            <DescriptionOutlinedIcon/>
        </Avatar>
    )
    return (
        <Grid item xs={11}>
            <Chip
                style={{maxWidth:width - 20+"px"}}
                className={classes.chip}
                label={file.name}
                aria-label={file.name}
                size="medium"
                avatar={avatar}
                onDelete={handleDelete}
            />
        </Grid>
    );
}

const DragAndDropFiles = ({
    multiple, 
    extensions,
    onAccept,
    promptText,
    acceptButtonText,
    acceptButtonLoading
}) => {
    const [drag, setDrag] = useState(0);
    const [files, setFiles] = useState([]);
    const [dragDropWidth, setDragDropWidth] = useState(null);
    const {width, height} = useViewport();
    const dragDropRef = useRef(null);
    const inputFileRef = useRef(null);
    const classes = useStyles();

    if(!acceptButtonText)
        acceptButtonText = `Subir archivo${files.lenght>0?'s':''}`;

    const handleOnAccept = () => {
        onAccept(files);
        inputFileRef.current.value = "";
        setFiles([]);
    }

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(drag + 1);
    }
    
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(drag -1);
    }

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(0);
        setFiles([...e.dataTransfer.files]);
    }

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleButtonClick = e => {
        inputFileRef.current.click();
    }

    const handleInputFiles = e => {
        setFiles([...e.target.files]);
    }

    const handleDragDropResize = () => {
        setDragDropWidth(dragDropRef.current.offsetWidth);
    }

    const handleDeleteFile = (i) => {
        const nf = files.filter((e, j) => {
            return i !== j;
        });
        if(nf.length === 0)
            inputFileRef.current.value = "";
        setFiles(nf);
    }

    useEffect(() => {
        if(!dragDropRef.current) return
        handleDragDropResize();
        window.addEventListener("resize", handleDragDropResize)
        return () => window.removeEventListener("resize", handleDragDropResize)
    }, [])

    return (
        <Box 
            className={drag > 0 ? classes.dropAreaDrag : classes.dropArea}
            ref = {dragDropRef}
            onDragEnter = {handleDragEnter}
            onDrop = {handleDrop}
            onDragOver = {handleDragOver}
            onDragLeave = {handleDragLeave}
        >
            <Box
                onDragEnter = {handleDragEnter}
                onDragLeave = {handleDragLeave}
                onDragOver = {handleDragOver}
                onDrop = {handleDrop}
            >
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    { files.length === 0 &&
                        <Grid item>
                            <CloudUploadOutlinedIcon />
                        </Grid>
                    }
                    { files.length === 0 &&
                        <Grid item xs={6}>
                            <p>
                                { promptText?
                                    promptText
                                    :
                                    `Arrastra ${multiple?"los":"el"} archivo que quieras subir`
                                }
                            </p>
                            <Divider className={classes.divider}/>
                        </Grid>
                    }
                    <input
                        onChange={handleInputFiles}
                        ref={inputFileRef}
                        type="file"
                        multiple={multiple}
                        accept={extensions}
                        className={classes.hide}
                    />
                    <Grid item>
                        { files.length === 0?
                            <Button
                                className={classes.inputButton}
                                variant = "contained"
                                color = "primary"
                                onClick = {handleButtonClick}
                            >
                                o elige tu archivo    
                            </Button>
                            :
                            <Box>
                                <Grid
                                    style={{minWidth:dragDropWidth - 20}}
                                    container
                                    direction="column" 
                                    justify="center"
                                    alignItems="flex-start"
                                >
                                    {
                                        files.map((f,i)=>{
                                            return (
                                                <FileItem
                                                    file={f}
                                                    key = {i}
                                                    width = {dragDropWidth}
                                                    handleDelete = {() => handleDeleteFile(i)} 
                                                />
                                            );

                                        })
                                    }
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    className={classes.inputButtonWrapper}
                                >
                                    <Grid item sm = {6} xs={8}>
                                        <Button
                                            fullWidth
                                            className={classes.inputButton}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleOnAccept}
                                            disabled={acceptButtonLoading}
                                        >    
                                            {acceptButtonLoading?
                                                <CircularProgress
                                                    size = {25}
                                                />
                                                :
                                                acceptButtonText
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default DragAndDropFiles;
