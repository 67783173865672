import React, { useState,useEffect } from "react";
import {useHistory} from "react-router-dom";
import { useAuth } from "../auth/auth.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Alert from './alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

}));

const EmailSignIn = (props) => {

    const auth = useAuth();
    const classes=useStyles();
    const history = props.history;
    const [error,setError] = useState("");
    const [email,setEmail] = useState("");
    const [loading,setLoading] = useState(false);
    const [success,setSuccess] = useState("");

    useEffect(()=>{
        if(!auth.isCurrentEmailLink()){
            // history.push("/signin")
            console.log("not email link!!")
        }
    },[])

    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);
        auth.handleEmailLinkSignIn(email).then(result=>{
            setSuccess("Éxito. Espera un momento.");
        }).catch(e=>{
            let decoded="";
            switch(e.code){
                case 'auth/expired-action-code':
                    decoded="El código de acción ha expirado.";
                    break;
                case 'auth/invalid-email':
                    decoded="El email no es válido.";
                    break;
                //shouldn't happen! still, there because idk he he
                case 'not-signin-link':
                    decoded="El link no es uno válido.."
                    break;
                default:
                    decoded="El usuario correspondiente a este email ha sido deshabilitado.";
            }
            setError(decoded);
            setLoading(false);
        })
        // console.log("entro");
        // setTimeout(()=>{
        //     setError("alv");
        //     setLoading(false);
        //     console.log(error);
        // },2000)
    }

    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Typography component="h1" variant="h5">
                            Inicio de sesión con link
                </Typography>
                <form className={classes.form} noValidate>
                        <TextField
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="correo@credifintech.com.mx"
                            name="email"
                            autoFocus
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                            disabled={!(/.+@credifintech(.com)?.mx/.test(email))||loading}
                        >
                            {(loading&&(error===""))?<CircularProgress size={25}/>:"Inicia Sesión"}
                        </Button>
                </form>
                    <Alert 
                        show={ error!=="" || success!==""} 
                        setShow={()=>{setLoading(false);setError("");setSuccess("")}} 
                        message={error||success}
                        type={error!==""?"error":"success"}
                        duration={3000}
                    ></Alert>
            </Paper>
        </Container>
    );
  }

  export default EmailSignIn;