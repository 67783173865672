import React, { useState } from 'react';
import { useAuth } from "../auth/auth.js";
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';
import MailIcon from '@material-ui/icons/Mail';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import { useHistory } from "react-router-dom";
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CampainIcon from '@material-ui/icons/Archive.js';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    menuItem:{
        paddinfLeft: 0,
    }
}));

const DrawerGroupTitle = ({title}) => {
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom
                >
                    {title}
                </Typography>    
            </Grid>
        </Grid>
    );
}

const DrawerLink = ({onClick, icon, text}) => {
    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon>
                {icon}        
            </ListItemIcon>
            <ListItemText primary={text}/>
        </MenuItem>
    );
}

const DrawerGroup = ({title, links}) => {
    return (            
        <Box>
            <DrawerGroupTitle
                title = {title}    
            />
            {links.map((linkObject) => {
                const {
                    onClick,
                    icon,
                    text,
                } = linkObject;
                return (            
                    <DrawerLink
                        key = {text}
                        onClick = {onClick}
                        icon = {icon}
                        text = {text}
                    />
                );
            })}
        </Box>
    );
}

const CustomDrawer = ({open,setOpen}) => {
    const classes = useStyles();
    const auth = useAuth();
    const history = useHistory();

    const handleClick = path => {
        setOpen(!open);    
        history.push(path);
    }

    const principalLinks = [
        {
            onClick: () => {handleClick("/")},
            text: "Inicio",
            icon: <HomeIcon/>
        },
        {
            onClick: () => {handleClick("/interested")},
            text: "Interesados",
            icon: <SentimentVerySatisfiedIcon/>
        },
        {
            onClick: () => {handleClick("/recent")},
            text: "Recientes",
            icon: <HistoryIcon/>
        },
    ];

    const managementLinks = [
        {
            onClick: ()=>{handleClick("/reports/sinar")},        
            text: "Reporte SINAR",
            icon: <AssessmentIcon />
        },
    ]

    const adminLinks = [
        {
            onClick: () => {handleClick("/admin/search")},
            text: "Buscar Clientes",
            icon: <SearchIcon />
        },
        {
            onClick: () => {handleClick("/admin/users")},
            text: "Usuarios",
            icon: <SupervisorAccountIcon/>
        },
        {
            onClick: () => {handleClick("/admin/structure")},
            text: "Estructura",
            icon: <AccountTreeIcon/>,
        }, 
        {
            onClick: () => {handleClick("/admin/campaigns/manageCampaign")},
            text: "Campañas",
            icon: <CampainIcon/>
        },
        {
            onClick: () => {handleClick("/admin/creditProposals")},
            text: "Calcular créditos",
            icon: <FormatListNumberedIcon/>
        },
        

    ];

    const profileLinks = [
        {
            onClick: () => {handleClick("/profile")}, 
            text: "Perfil",
            icon: <PersonIcon/>
        },
        {
            onClick: () => {auth.signout()},
            text: "Cerrar Sesión",
            icon: <ExitToAppIcon/>
        },
    ];
    
    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Box className={classes.drawerHeader}>
                <IconButton onClick={()=>setOpen(!open)}>
                    <ChevronRightIcon />
                </IconButton>
            </Box>
            <MenuList>
                <DrawerGroup    
                    title = {"Principal"}    
                    links = {principalLinks}
                />
                <Divider/>
                <DrawerGroup
                    title = {"Gestion"}
                    links = {managementLinks}
                />
                <Divider/>
                {
                    auth.user.isAdmin &&
                    <Box>
                        <DrawerGroup
                            title = {"Administración"}
                            links = {adminLinks}
                        />
                        <Divider/>
                    </Box>
                }
                <DrawerGroup
                    title = {"Perfil"} 
                    links = {profileLinks}
                />
            </MenuList>
        </Drawer>
    )
}

export default CustomDrawer;
