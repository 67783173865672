import firebase from "../firebase/firebaseInit";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import { calculatePresentValue } from "../util/creditProposals/newCredits";
import { getAgeFromCURP } from "../util/utils";

export class RequestSIPRE {

    /**
     * 
     * @param {NSS and CURP is inside the variable in order to check the info in the SIPRE website} data 
     * @returns the avaliability of the credit
     */
    public checkPaymentavailability(data: any) {
        let xhr = new XMLHttpRequest();

        //let url=process.env.REACT_APP_SIVI_URL;
        //change after in order to choose depends of the env
        let url = process.env.REACT_APP_SIPRE_SERVICE_URL + '/getinfo';
        let params = this.formatSipreGetInfoRequest(data);
        return new Promise(function (resolve, reject) {
            xhr.onreadystatechange = function () {
                if (xhr.readyState !== 4) return
                if (xhr.status >= 200 && xhr.status < 300)
                    resolve(xhr)
                else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    })
                }
            }
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json")
            xhr.send(JSON.stringify(params));
        });
    }

    private formatSipreGetInfoRequest(contact: { CONVENIO: any; IDENTIFICADOR: any; CURP: any; }) {
        let {
            CONVENIO,
            IDENTIFICADOR,
            CURP
        } = contact;
        CURP = CURP ? CURP : "";
        let NSS = "";
        if (CONVENIO === 'IMSS_PENSIONADOS') {
            NSS = IDENTIFICADOR;
        }
        return {
            NSS,
            CURP
        };
    }

    private async getCreditInfo () : Promise<any> {
        let response = null;
        const createCreditInformation = firebase
        .functions()
        .httpsCallable('callable-creditProposals-getAllInfoFromActiveCredits');
        
        await createCreditInformation().then(r => {
            response = {...r.data.message};
        }).catch(e => {
            console.log("" + e);
        });
        return response;
    }

    private validateRestrictions(restrictions:any, age:number, creditProposal:number) {
        if(age < restrictions["minAge"] && age > restrictions["maxAge"] ) {
            return false;
        }

        if (creditProposal < restrictions["minCredit"]) {
            return false;
        }
        return true;
    }

    private addPriority(creditProposals: any, generalInfo: any): any {

        const creditsPriority:any = {};
        generalInfo["creditsPriority"].map(
            (priorityID:string, index:number) => {
                creditsPriority[priorityID] = index;
            });

        let response = creditProposals.map(
            (credit:any) => {
                return {...credit, "priority":creditsPriority[credit["ref"]]};
            }
        );
        return response;
    }

    private generateTextsOfCreditProposals(creditProposals: any, baseText: string) {
        let resultTexts: string[] = [];

        creditProposals.map( (credit: { [x: string]: number; }) => {
            if (credit["creditProposal"] > 0) {
                let newText = baseText.slice();
                newText = newText.replace("{priority}",(credit["priority"]+1).toString());
                newText = newText.replace("{amount}",credit["creditProposal"].toString());
                newText = newText.replace("{fixTerm}",credit["fixTerm"].toString());
                newText = newText.replace("{name}",credit["name"].toString());
                newText = newText.replace("{fixRate}", (credit["fixRate"]*100).toFixed(2));
                resultTexts.push(newText);
            }
        });
        //validate length of result
        if (resultTexts.length === 0) {
            resultTexts.push("No hay propuestas que se ajusten a este registro");
        }
        return resultTexts;
    }

    /**
     * calculateNewCreditPropolsals
     */
    public async calculateNewCreditProposals(paymentCapacity: number, curp:string ) {

        const creditInfo = await this.getCreditInfo();
        const generalInfo = creditInfo["generalInfo"];
        const ageNotValidated = getAgeFromCURP(curp);
        const age:number = ageNotValidated !== null ? ageNotValidated : 0;
        let resultTexts:string[] = [];

        let creditProposals = creditInfo["credits"].map(
            (credit: { [x: string]: any; }) => {
                let result = {...credit};
                const creditProposal = calculatePresentValue(paymentCapacity, credit["fixRate"], credit["fixTerm"]);
                if( curp!== null && this.validateRestrictions(credit["restrictions"], age, creditProposal)) {
                    return {...result,creditProposal};
                } else {
                    return {...result,"creditProposal":0};
                }
            });
        creditProposals = this.addPriority(creditProposals, generalInfo);
        resultTexts = this.generateTextsOfCreditProposals(creditProposals, generalInfo["textToPresentCreditResults"]);
        return resultTexts;
    }
    
    public async getSetTwilioValidationNumbers(phone: string[]) : Promise<any> {
        //request['ph']
        let response = null;
        const getSetTwilioValidationNumbers = firebase
        .functions()
        .httpsCallable('validate_number');
        const ph = phone;
        response = getSetTwilioValidationNumbers({ph}).then(r => {
            return r.data;
        }).catch(e => {
            console.log("" + e);
        });
        return response;
    }    
}

export default RequestSIPRE;


