import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Navbar from './navbar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../auth/auth';
import { beautifyName, summarize, pad } from '../util/util';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from "react-router-dom";
import Alert from './alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Header from './pageheader.jsx';
import { useContactInfo } from '../contactinfo/contactinfo';
import Paginate from './custompaginate';
import CircularProgress from '@material-ui/core/CircularProgress'
import contactOptions,{Option} from '../contactinfo/options';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2),
    }
}));

const CustAccordion = ({data, setError, setSuccess, setCopyText, sendInfoToSivi, updateSurveyMessage, reload}) => {
    const classes = useStyles();

    let { CP, NOMBRE, TELEFONO, TELEFONO2, CONVENIO, CAMPAIGNS } = data.data();
    const contact={...data.data(), id:data.id}
    const [expanded, setExpanded] = useState(false);
    const [siviWheel, setSiviWheel] = useState(false);
    const [discardWheel, setDiscardWheel] = useState(false);
    
    const handleSendButton = () =>{
        setSiviWheel(true);
        sendInfoToSivi(contact).then(r=>{
            const response=JSON.parse(r.response);
            if(response.message === "error"){
                setError(response.details);
                setSiviWheel(false);
            }
            else{
                const folio=pad(response.idSolicitud, 6);
                const prevSurveyOption = contactOptions.fromOption(contact.surveyMessage);
                const newSurveyOption = contactOptions.fromOption('Validar capacidad de crédito');
                updateSurveyMessage(contact.id, prevSurveyOption, newSurveyOption,folio).then(()=>{
                    reload();
                    setCopyText(folio);
                    setSuccess("Folio en SIVI: "+folio);
                    setExpanded(!expanded);
                    setSiviWheel(false);
                }).catch(e=>{
                    setSiviWheel(false);
                    setError("Hubo un error actualizando el registro");
                })
            }
        })
    }

    const handleDiscardButton = () => {
        setDiscardWheel(true); 
        const prevSurveyOption = contactOptions.fromOption(contact.surveyMessage);
        const newSurveyOption = new Option("No está interesado", "negative");
        updateSurveyMessage(contact.id, prevSurveyOption, newSurveyOption)
            .then(() => {
                reload();
                setExpanded(!expanded);
                setSuccess("Se ha actualizado el registro exitosamente");
                setDiscardWheel(false);
            }).catch(e => {
                setDiscardWheel(false); 
                setError("Hubo un error actualizando el registro");
            })
    }

    const handleExpanded = () => {
        setExpanded(!expanded);
    }

    return (
        <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={handleExpanded} 
            >
              <Typography className={classes.heading}>{beautifyName(NOMBRE)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                    <Grid item><Typography variant="body1">Código Postal: {CP}</Typography></Grid>
                    {CAMPAIGNS && <Grid item><Typography variant="body1">Campañas: {CAMPAIGNS.join(",")}</Typography></Grid>}
                    <Grid item><Typography variant="body1">Telefono: {TELEFONO}</Typography></Grid>
                    {TELEFONO2 && <Grid item><Typography color="primary" variant="body1">Telefono 2: {TELEFONO2}</Typography></Grid>}
                    <Grid item><Typography variant="body1">Convenio: {CONVENIO}</Typography></Grid>
                </Grid>
            </AccordionDetails>
            <AccordionActions>
                <Button 
                    size="small"
                    onClick={handleDiscardButton}
                >
                    {discardWheel ? <CircularProgress size={25}/> : "Descartar"}
                </Button>
                <Button 
                    size="small" 
                    color="primary"
                    onClick={handleSendButton}
                >
                    {siviWheel ? <CircularProgress size={25}/> : "Enviar a SIVI"}
                </Button>
            </AccordionActions>
        </Accordion>
    )
}

const Interested = () => {
    const classes = useStyles();
    const {getInterested, sendInfoToSivi, updateSurveyMessage} = useContactInfo()
    const auth = useAuth();
    const history=useHistory();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [copyText, setCopyText] = useState("");

    const handleAlertClose = () => {
        setSuccess("");
        setError("");
        setCopyText("");
    }


    return (
        <Box>
            <Navbar/>
            <Box mt={3}>
                <Container>                 
                    <Paper className={classes.root}>
                        <Paginate
                            getData={getInterested}
                            limit={3}
                            renderElem={(e,k,reload) => 
                                <CustAccordion 
                                    data={e} 
                                    key={k}
                                    reload={reload}
                                    setError={setError}
                                    setSuccess={setSuccess}
                                    setCopyText={setCopyText}
                                    sendInfoToSivi={sendInfoToSivi}
                                    updateSurveyMessage={updateSurveyMessage}
                                />}
                            headerName={"Interesados"}
                            headerDescription={"clientes interesados"}
                            backPage={"/"}
                            emptyMessage={"No tienes clientes interesados"} 
                            downloadUsersReport = {false}
                        />
                        <Alert
                            show={success!=="" || error!=""}
                            message={error!==""?error:success}
                            setShow={handleAlertClose}
                            duration={6000}
                            type={error!==""?"error":"success"}
                            copyText={copyText} 
                        />
                        </Paper>
                    </Container>
                </Box>
            </Box>
        )
    }

    export default Interested;

