import React,{useState, Fragment, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdminInfo from '../../admininfo/admininfo';

const CoordList = ({current, persistChange, role}) => {
    const DEFAULT = {uid:"",name:"",email:""}
    const [open, setOpen] = useState(false);    
    const [options, setOptions] = useState([DEFAULT]);
    const [val, setVal] = useState(current?current:DEFAULT);
    const loading = open && options.length === 1;
    const {fetchCoords, fetchRegionalCoords} = AdminInfo();
    

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (active) {
            role === "asesor"?
                fetchCoords().then(coords => {
                    setOptions(coords.concat([DEFAULT]));
                })
            :
                fetchRegionalCoords().then(coords => {
                    setOptions(coords.concat([DEFAULT]));
                })
        }

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([DEFAULT]);
        }
    }, [open]);

    const handleChange = (e, newVal) => {
        setVal(newVal);
        persistChange(newVal)
    }
    
    //TODO: Select no coord option!!!!!
    return (
        <Autocomplete
            disableClearable
            id="coord-list"
            value={val}
            open={open}
            onChange = {handleChange}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.uid ? option.email + ' (' + option.name + ')' : "Sin Coordinador"}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Coordinador"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export default CoordList;
