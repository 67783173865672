import React, { useState,useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import firebase from '../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import Grid from '@material-ui/core/Grid'; 
import Popper from '@material-ui/core/Popper';

const fetchAvailables = () => {
    // return firebase.firestore()
    //     .collection("users")
    //     .where("role", "==", "asesor")
    //     .where("coord", "==", "")
    //     .where("firstLogin","==",true)
    //     .get()
    
    const f = firebase
        .functions()
        .httpsCallable('callable-coordFun-allVerifiedWithoutCoord');
    return f();
}

const CustomPopper = function (props) {
  return (<Popper {...props} style={{ width:250 }} />)
}

//TODO: set error!!!!
const AvailableAsesores = ({onChange}) => {
    const [loaded, setLoaded] = useState(false);
    const [availables,setAvailables] = useState(null)


    useEffect(()=>{
        setLoaded(false);
        fetchAvailables()
            .then(res=>{setAvailables(res.data);setLoaded(true)})
            .catch(er=>console.log(er));
    },[])

    if (!loaded)
        return (
            <Grid container>
                <Grid item xs={5}></Grid>
                <CircularProgress />
            </Grid>
        )
    else if(loaded&&(availables.length===0))
        return (
            <Typography component="h6">
                ¡No hay asesores verificados sin coordinador!
            </Typography>
        )
    else 
        return (
            <Autocomplete
                id="add-asesor"
                size="medium"
                noOptionsText="No se encontró"
                PopperComponent={CustomPopper}                
                options={availables}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name+" ("+option.email+")"}
                onChange={onChange}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Asesores" placeholder="" />
            )}/>
        )
}

export default AvailableAsesores;
