import React from 'react'
import { useAuth } from "../auth/auth.js";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const AlreadyLogged = (props) => {
    const classes=useStyles();
    const auth = useAuth();

    return (
        <Container component="main" maxWidth="300">
            <Box className={classes.paper}>

                <Box>
                    <Typography component="h1" variant="h4">
                        El usuario {auth.user.email} ya está conectado
                    </Typography>
                </Box>

                <Box mt={5}>
                    <Typography component="h5" variant="h5">
                        Solo se permite el acceso a un único dispositivo por cuenta. 
                    </Typography>
                </Box>

                <Box mt={5}>
                    <Button onClick={()=>auth.signout()} variant="contained" color="primary">
                        cerrar sesión
                    </Button>
                </Box>

            </Box>
        </Container>
    )
}

export default AlreadyLogged;