import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { makeStyles ,withStyles } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import DirectionsIcon from '@material-ui/icons/Directions';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import BlockIcon from '@material-ui/icons/Block';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ColoredTypography from './coloredtypography';


const useStyles = makeStyles((theme) => ({
    textfield: {
        width:'90%',
    },
    divider: {
        margin: theme.spacing(2),
    },  
    root: {
        margin: 2,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));


const SearchHeader = (props) => {
    const {
        loading,
        backPage, 
        setSearchMode,
        handleSearch,
        searchPlaceholder,
    } = props;
    const [search, setSearch] = useState("");
    const history = useHistory();
    const classes = useStyles();


    const handleBackPage = () => {
        history.push(backPage);
    }

    const toggleSearchOff = () => {
        handleSearch("");
        setSearchMode(false);
    }

    const handleSearchInput = e => {
        setSearch(e.target.value);
    }

    const handleSearchButtonClick = _ => {
        handleSearch(search);
    }

    const handleSpecialKeys = e => {
        if(e.key === 'Enter' && search !== "")
            handleSearch(search);
        if(e.key === 'Escape')
            toggleSearchOff();
    }


    return (
        <Box className={classes.root}>
            {backPage && (
            <IconButton 
                className={classes.iconButton}
                aria-label="menu"
                onClick={handleBackPage}
            >
                <ArrowBackOutlinedIcon />
            </IconButton>)}
            <InputBase
                value={search}
                onChange={handleSearchInput}
                // onKeyPress={handleEnterKey}
                onKeyDown={handleSpecialKeys}
                className={classes.input}
                placeholder={searchPlaceholder}
                autoFocus
            />
                {
                    loading?
                        <Box className={classes.iconButton}>
                            <CircularProgress size={20}/>
                        </Box>
                            :
                        <IconButton 
                            className={classes.iconButton} 
                            aria-label="buscar"
                            disabled={search===""}
                            onClick={handleSearchButtonClick}
                        >
                            <SearchIcon />
                        </IconButton>
                }
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton 
                color="primary"
                aria-label="regresar"
                onClick={toggleSearchOff}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    )
}

const TextHeader = (props) => {
    const {
        loading,
        pageName,
        nameColor,
        backPage,
        pageDescription,
        descriptionColor,
        enableSearch,
        setSearchMode,
        endAdornment,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const color = nameColor?nameColor:"textPrimary";
    const descColor = descriptionColor?descriptionColor:"textSecondary";

    const toggleSearchOn = () => {
        setSearchMode(true); 
    }

    const redirectToBackPage = () => {
        history.push(backPage);
    }

    let end;
    if(enableSearch)
        end = (
            <IconButton
                onClick={toggleSearchOn}
            >
                <SearchIcon/>
            </IconButton> 
        )
    else if(endAdornment)
        end = endAdornment;
    else
        end = <Box/>
    
    return (
        <Grid container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid
                container
                item
                justify="flex-start"
                xs
            >
                
                {
                    backPage && (<Grid item>
                            <IconButton onClick={redirectToBackPage}>
                                <ArrowBackOutlinedIcon/>
                            </IconButton>
                        </Grid>)
                }
                
                <Grid item xs={7}>
                    <ColoredTypography 
                        component="h1"
                        variant="h5"
                        color={color}
                    >
                        {pageName}
                        {loading&&<CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />}
                    </ColoredTypography>
                    <ColoredTypography
                        variant="caption"
                        component="div"
                        color={descColor}
                    >
                      {pageDescription}
                    </ColoredTypography>
                </Grid>
            </Grid>
            <Grid>
                {end}
            </Grid>
        </Grid>
    )
}


const Header = (props) => {
    const {
        loading, 
        pageName, 
        nameColor,
        backPage, 
        pageDescription, 
        descriptionColor,
        searchPlaceholder,
        enableSearch,
        handleSearch,
        endAdornment,
    } = props;
    const classes = useStyles();
    const [searchMode, setSearchMode] = useState(false);

    const toggleSearchOff = _ => {
        setSearchMode(false);
    }

    if(!searchMode)
        return <TextHeader
                    loading={loading}
                    pageName={pageName}
                    nameColor={nameColor}
                    backPage={backPage}
                    pageDescription={pageDescription}
                    descriptionColor={descriptionColor}
                    enableSearch={enableSearch}
                    setSearchMode={setSearchMode}
                    endAdornment={endAdornment}
                />
    return (
        <SearchHeader
            loading={loading}
            backPage={backPage}
            setSearchMode={toggleSearchOff}
            handleSearch={handleSearch}
            searchPlaceholder={searchPlaceholder}
        />
    )

}

export default Header;
