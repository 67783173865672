import React, {useState, Fragment, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AdminInfo from '../../admininfo/admininfo';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({

    popperBorderStyle: {
        zIndex: 1000000,
        "& .MuiAutocomplete-listbox": {
            border: "2px solid silver",
            fontSize: 18,
      }
    },
}))

const CampaignsList = ({
    campaigns,
    persistChange,
    textfieldClassName,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    const { fetchCampaigns } = AdminInfo();

    useEffect(() => {
        let active = true;

        if(!loading){
            return undefined;
        }

        if(active){
            fetchCampaigns()
                .then(cmps => {
                    setOptions(
                        cmps.map(d => {
                            return d.name;
                        })
                    );
                })
        }

        return () => {
            active = false;
        };

    }, [loading])
    
    useEffect(() => {
        if(!open) {
            setOptions([]);
        }
    }, [open]);

    return (
      <Autocomplete
        multiple
        id="campaigns-selector"
        open = {open}
        PaperComponent = {
            props => {
                return <Paper
                    {...props}
                />
            }
            
        }
        PaperComponent = {
            props => {
                return <Paper
                    {...props}
                    elevation={8}
                />
            }
        }
        PopperComponent = {
            props => {
                return <Popper
                    {...props}
                    className={classes.popperBorderStyle}
                />
            }
        } 
        onOpen = {
            () => setOpen(true)
        }
        onClose = {
            () => setOpen(false)
        }
        onChange = {
            (_, selected) => {
                const d = {}
                selected.forEach(cmp => {
                    d[cmp] = "00000";    
                })
                persistChange(d);    
            }
        }
        renderOption={(option, { selected }) => (
            <Fragment>
                <Checkbox
                    icon={
                        <CheckBoxOutlineBlankIcon
                            fontSize="small" 
                        />
                    }
                    checkedIcon={
                        <CheckBoxIcon
                            fontSize="small" 
                        />    
                    }
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
              {option}
            </Fragment>
      )}
        options={options}
        value={campaigns}
        loading = {loading}
        renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip 
                        color="primary"
                        label={option}
                        {...getTagProps({ index })} 
                    />
                )
            )
        }
        renderInput = {(params) => (
            <TextField
                {...params}
                variant="outlined"
                label="Campañas"
                className = {textfieldClassName}
            />
        )}
      />        
    );
}

export default CampaignsList;
