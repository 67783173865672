import ReactDOM from 'react-dom';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { Spin } from 'antd';
import React from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Row, Col, Statistic, Table } from 'antd';

const COLORS_SERIES =  [
    "#00876c",
    "#379469",
    "#58a066",
    "#78ab63",
    "#98b561",
    "#b8bf62",
    "#dac767",
    "#deb256",
    "#e09d4b",
    "#e18745",
    "#e06f45",
    "#dc574a",
    "#d43d51",
]; 
const commonOptions = {
  maintainAspectRatio: false,
};


const cubejsApi = cubejs(
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTY3OTMxOTAsImV4cCI6MTYxNjg3OTU5MH0.lEs1pufrtRpsiaxgU_YuS2yypZmjoyRaFidp0wnK9V8',
  { apiUrl: 'http://localhost:4000/cubejs-api/v1' }
);

const renderChart = ({ resultSet, error, pivotConfig }) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <Spin />;
  }

  const data = {
  labels: resultSet.categories().map((c) => c.category),
  datasets: resultSet.series().map((s, index) => ({
    label: s.title.replace(", Reporte Sinar Count",""),
    data: s.series.map((r) => r.value),
    backgroundColor: COLORS_SERIES[index],
    fill: false,
  })),
};
const options = {
  ...commonOptions,
  scales: {
    xAxes: [
      {
        type: "time",
        time: {
          unit: "month",
          displayFormats: {
            hour: "MMM DD"
          },
          tooltipFormat: "MMM D"
        },
        stacked: true,
      },
    ],
  },
};
return <Bar data={data} options={options} />;

};

const ChartRenderer = () => {
  return (
    <QueryRenderer
      query={{
  "measures": [
    "ReporteSinar.count"
  ],
  "dimensions": [
    "ReporteSinar.respuesta"
  ],
  "timeDimensions": [
    {
      "dimension": "ReporteSinar.completedat",
      "granularity": "month"
    }
  ],
  "order": {}
}}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) => renderChart({
        ...props,
        chartType: 'bar',
        pivotConfig: {
  "x": [
    "ReporteSinar.completedat.month",
  ],
  "y": [
    "measures"
  ],
  "fillMissingDates": true,
  "joinDateRange": false
}
      })}
    />
  );
};

export default ChartRenderer;
