import firebase from 'firebase/compat/app';
import "firebase/compat/analytics";
import 'firebase/compat/functions'; // Import the functions module
import "firebase/compat/performance";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// Initialize Firebase app
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();

// Conditionally enable emulator for local development
//firebase.functions().useEmulator('http://localhost',5001); //Uncomment to use local cloud functions
firebase.analytics();

export default firebase;
