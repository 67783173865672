import React,{useState} from "react";
import Button from '@material-ui/core/Button';
import { useAuth } from "../auth/auth.js";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhoneInput from 'react-phone-input-2'
import Collapse from '@material-ui/core/Collapse';
import 'react-phone-input-2/lib/material.css'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    }
}));

const AddPhone = () => {
    const [show, setShow] = useState(false);
    const [code, setCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const auth = useAuth();
    const classes = useStyles();
    const codeRegex = /\d{6}/;


    const inputContainerStyle = {
        font:"inherit",
        marginTop: 10,
    }

    const inputStyle = {
        borderRadius: 4,
        width: "100%",
    }

    const inputButtonStyle = {
        borderRadius: 4,
    }

    const handleSignout = () => {
        auth.signout();
    }

    const handleMainButton = () => {
        setLoading(true);
        const p = new Promise((resolve, reject) => {
            setTimeout(function(){
                resolve("¡Éxito!"); // ¡Todo salió bien!
            }, 3000);
        })
        p.then(()=>{
            setShow(true);
        });

    }

    return (
        <Container component = "main" maxWidth = "xs">
            <Paper className={classes.paper} elevation={3}>
                <Typography component="h1" variant="h5">
                    Agrega tu telefono    
                </Typography>

                <form className={classes.form} noValidate>
                    <PhoneInput 
                        countryCodeEditable = {false} 
                        containerStyle={inputContainerStyle}
                        inputStyle={inputStyle}
                        buttonStyle={inputButtonStyle}
                        masks={{mx:'(..) ..-..-..-..'}}
                        onlyCountries={['mx']} 
                        country={'mx'}
                        onChange={(e,d)=>console.log(e,d)}
                        required
                        label="telefono celular"
                    >
                        Hola
                    </PhoneInput>
                    <Collapse in={show}>
                        <TextField
                            type="text"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="código de verificación"
                            // onChange={e => setEmail(e.target.value)}
                        />
                    </Collapse>
                    
                    <Button
                        className={classes.form}
                        disabled = {loading && !show}
                        variant="contained"
                        color="primary"
                        fullWidth  
                        onClick={handleMainButton}
                    >
                        {show ? 
                            "Verificar" : 
                            loading?
                                <CircularProgress size={25}/>:
                                "Enviar código de confirmación"
                        } 
                    </Button>


                    {/*<Button
                        //disabled = {show && codeRegex.test(code)}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        // onClick={handleSubmit}
                        // disabled={loading&&(error==="")}
                    >
                    </Button>*/}
                </form>


            </Paper>
        <Button
            onClick={handleSignout}
            variant="contained"
            color="primary"
        >
            cerrar sesión
        </Button>
        </Container>

    )
}

export default AddPhone;
