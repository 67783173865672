import React, { useState, useEffect } from 'react';
import { fade,makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import Header from '../pageheader';
import Alert from '../alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import AdminInfo from '../../admininfo/admininfo';
import {timeAgo, summarize} from '../../util/util';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import Collapse from '@material-ui/core/Collapse';
import CoordList from './coordlist';
import CampaignsList from './campaignslist';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import OutlinedBox from '../outlinedbox';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Link from '@material-ui/core/Link';


import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import BlockIcon from '@material-ui/icons/Block';
import ColoredTypography from '../coloredtypography';
import {useViewport} from '../../viewport/viewport.js';

import Navbar from '../navbar';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const useStyles = makeStyles((theme) => ({
    flexWrap: {
        display: "flex",
        flexWrap: "wrap"            
    },
    notFound: {
        padding: theme.spacing(3),
    },
    outlinedBox: {
        margin: theme.spacing(10),    
    },
    asesoresMargin: {
        marginRight: theme.spacing(2),
    },
    asesores: {
    },
    asesor: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        width: "100%",
    },
    title: {
        margin: theme.spacing(4, 0, 0),
    },
    root: {
        marginBottom: 2,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxHeight: 200,
        overflowY: "scroll",
    },
    demo: {
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    form: {
        marginTop: theme.spacing(1),
    },
    disableUser: {
        //backgroundColor: "#e53935",
        '&:hover': {
              backgroundColor: '#ab000d',
        },
        '&:active': {
              backgroundColor: '#ff6f60',
        },
        color: "white",
        backgroundColor: "#e53935",

    },
    enableUser: {
        '&:hover': {
              backgroundColor: '#00600f',
        },
        '&:active': {
              backgroundColor: '#388e3c',
        },
        color: "white",
        backgroundColor: "#388e3c",
    },
    chipInput: {
        minHeight: "56px",
    },
    chipInputTextfield:{
        marginTop: theme.spacing(1),
        marginBottom: "18px",
    },
    buttons: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3,0,8),
    },
    headerIcons:{
        paddingRight:theme.spacing(2),
    },
    chips:{

    },
    textfield: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3,0,8),
    },
    headerIcons:{
        paddingRight:theme.spacing(2),
    },
    chips:{
        marginTop: theme.spacing(1),
        display:"block",
    }
}));


const AdminProfile = ({match}) =>{
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState("");
    const [loadingError, setLoadingError] = useState("");
    const [error, setError] = useState("");
    const [userInfo, setUserInfo] = useState(null);
    const [removed, setRemoved] = useState([]);
    const {fetchUser, updateUserInfo} = AdminInfo();
    const { width } = useViewport();
    const history = useHistory();
    let email = "";

    const classes = useStyles();

    useEffect(()=>{
        setUserInfo(null);
        fetchUser(match.params.uid)    
            .then(d => {
                setUserInfo({...d.data, uid: match.params.uid})
            })
            .catch(e => {
                setUserInfo(false);
                console.log(e);
                setError("Hubo un error intentando cargar el usuario con uid " + match.params.uid + " favor de reportar el error.");
            })
    },[match.params.uid])

    const handleGoToAdminPage = () => {
        history.push('/admin/users');
    }

    const handleRemoveAsesores = uid => {
        if(!removed.includes(uid)){
            setRemoved(removed.concat([uid]));
            const cp = {...userInfo}
            cp.asesores = cp.asesores.filter(e => {return e.uid != uid})
            setUserInfo(cp);
        }
    }

    const addSinglePostalCode = (postalCode) => {
        if(postalCode.length !== 5 || !(/^\d+$/.test(postalCode)))    
            setError(`${postalCode} no es un código postal válido. Los códigos postales continenen exactamente cinco digitos.`)
        else if(!userInfo.postalCodes.includes(postalCode)){
            const cp = {...userInfo};
            cp.postalCodes.push(postalCode);
            setUserInfo(cp);
        }
    }

    const handleAddPostalCodes = (newChip)=>{
        for(let postalCode of newChip.split(/,| |\n/)){
            if(postalCode !== "")
                addSinglePostalCode(postalCode);
        }
    }

    const handleAddSearchIndex = (newChip)=>{
        if(!userInfo.postalCodes.includes(newChip)){
            const cp = {...userInfo};
            cp.searchIndices.push(newChip);
            setUserInfo(cp);
        }
    }

    const handleDeleteSearchIndex = (index, chip) => {
        const cp = {...userInfo};    
        cp.searchIndices = cp.searchIndices.filter((e, i) => {return i != index});
        setUserInfo(cp);
    }

    const handleNameChange = e => {
        const cp = {...userInfo};    
        cp.name = e.target.value
        setUserInfo(cp);
    }

    const handleSaveButtonClick = _ => {
        setLoading(true);
        console.log(userInfo);
        updateUserInfo(userInfo, removed)    
            .then(() => {
                setLoading(false);        
                setUpdated("Se ha actualizado el usuario exitosamente");
            }).catch(e => {
                setLoading(false);
                setError("Ha occurrido un error actualizando el usuario. Favor de reportar el error.");
            })
    }

    const handleDeletePostalCode = (index, chip) => {
        const cp = {...userInfo};    
        cp.postalCodes = cp.postalCodes.filter((e, i) => {return i != index});
        setUserInfo(cp);
    }

    const handleCoordChange = (newVal) => {
        const cp = {...userInfo};    
        cp.coord = newVal ? newVal : "";
        setUserInfo(cp);
    }

    const handleCampaignsChange = (newVal) => {
        const cp = {...userInfo};    
        cp.campaigns = newVal;
        setUserInfo(cp);
    }

    const handleRoleChange = (e, newVal) => {
        const cp = {...userInfo};
        cp.role = newVal; 
        setUserInfo(cp);
    }

    const handleDisabledChange = e => {
        const cp ={...userInfo};
        cp.disabled = !userInfo.disabled;
        setUserInfo(cp);
    }

    const formatRole = r => {
        if(r === "coord")
            return "Coordinador"
        else if(r === "asesor")
            return "Asesor"
        else
            return "Coordinador Regional"
    }

    const DisabledText = ({id,
        label,
        value,
        className,
        variant,
        wrapText,
    }) => { 
        const classes = useStyles();
        return (
            <FormControl
                fullWidth
                variant={variant}
                disabled
                className={className}
            >
                <InputLabel htmlFor={id}>
                    {label}
                </InputLabel>
                <OutlinedInput
                    id={id}
                    type="text"
                    value={value}
                    label={label}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="deshabilitado"
                                edge="end"
                                disabled
                            >
                                <LockOutlinedIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )
    }

    let content;
    if(userInfo === null)
        content =  (
            <Box mt={8} p={3}>
                {[...Array(20).keys()].map((e,i)=>{
                    return <Skeleton key={i} className={classes.skeleton} />
                })}
            </Box>
        )
    else if(userInfo === false)
        content = (
            <Box
                className = {classes.notFound}
            >
                <Link 
                    onClick = {handleGoToAdminPage}
                >
                    regresar
                </Link>
            </Box>
        )
    else
        content = (
            <Box p={3}>
                <Header 
                    loading={false} 
                    pageName={userInfo.name}
                    backPage={"/admin/users"}
                    pageDescription={userInfo.status.state === 'online'?"en línea":"útima conexión hace " + timeAgo(new Date(userInfo.status.last_changed))}
                    descriptionColor = {userInfo.status.state==='online'?"green":"red"} 
                />
                <Divider/>
                <Box className={classes.form}>
                    { userInfo.disabled?
                        <DisabledText
                            className={classes.textfield}
                            variant="outlined"
                            label="Nombre"
                            value={userInfo.name}
                            name="nombre-disabled"
                            fullWidth
                        />
                        :
                        <TextField
                            className={classes.textfield}
                            variant="outlined"
                            label="Nombre"
                            value = {userInfo.name} 
                            onChange = {handleNameChange}
                            margin="normal"
                            id="nombre"
                            name="nombre"
                            fullWidth
                        />
                    }
                    { userInfo.disabled?
                            <DisabledText
                                className={classes.textfield}
                                variant="outlined"
                                label="Indices de busqueda"
                                value={userInfo.searchIndices.join(", ")}
                                fullWidth
                            />
                            :
                            <ChipInput
                                classes={{inputRoot:classes.chipInput}}
                                className={classes.chipInputTextfield}
                                value={userInfo.searchIndices}
                                fullWidth
                                onAdd={(chip)=>handleAddSearchIndex(chip)}
                                onDelete={(chip,index)=>handleDeleteSearchIndex(index,chip)}
                                label = "Indices de busqueada"
                                variant = "outlined"
                                newChipKeyCodes={[188,13,32]}
                                alwaysShowPlaceholder
                                chipRenderer={(
                                    {
                                        value,
                                        handleClick,
                                        handleDelete,
                                        className
                                    },
                                    key
                                ) => (
                                        <Chip
                                            color="primary"
                                            key={key}
                                            className={className}
                                            onClick={e => { handleClick(e) }}
                                            onDelete={e => { handleDelete(e) }}
                                            label={value}
                                        />
                                    )}
                            />
                    }
                    <DisabledText
                        className={classes.textfield}
                        variant="outlined"
                        label="Email"
                        value = {userInfo.email}
                        id="email"
                    />
                    <DisabledText
                        className={classes.textfield}
                        variant="outlined"
                        label="Fecha de creación"
                        value = {moment(userInfo.created).locale('es').format('LL')}
                        id="fecha-de-creacion"
                    />
                     
                    { userInfo.disabled?
                        <DisabledText
                            className={classes.textfield}
                            variant="outlined"
                            label="Codigos Postales"
                            value={userInfo.postalCodes.join(", ")}
                        />
                        :
                        <ChipInput
                            classes={{inputRoot:classes.chipInput}}
                            className={classes.chipInputTextfield}
                            value={userInfo.postalCodes}
                            fullWidth
                            onAdd={(chip)=>handleAddPostalCodes(chip)}
                            onDelete={(chip,index)=>handleDeletePostalCode(index,chip)}
                            label = "Codigos Postales"
                            variant = "outlined"
                            newChipKeyCodes={[188,13,32]}
                            alwaysShowPlaceholder
                            chipRenderer={(
                                {
                                    value,
                                    handleClick,
                                    handleDelete,
                                    className
                                },
                                key
                            ) => (
                                    <Chip
                                        color="primary"
                                        key={key}
                                        className={className}
                                        onClick={e => { handleClick(e) }}
                                        onDelete={e => { handleDelete(e) }}
                                        label={value}
                                    />
                                )}
                        />
                    }
                    {userInfo.disabled? 
                        <DisabledText
                            className={classes.textfield}
                            variant="outlined"
                            label="Rol"
                            value={ formatRole(userInfo.role) }
                        />
                        :
                        <Autocomplete
                            disableClearable
                            value = {userInfo.role}
                            id="role-selector"
                            options={["asesor","coord", "regional"]}
                            onChange={handleRoleChange}
                            getOptionLabel={option => formatRole(option)}
                            renderInput={
                                (params) => 
                                    <TextField 
                                        {...params}
                                        label="Rol" 
                                        variant="outlined" 
                                        className = {classes.textfield}
                                    />
                            }
                        />
                    }
                    {
                        userInfo.disabled
                            ? <DisabledText
                                className={classes.textfield}
                                variant="outlined"
                                label="Campañas"
                                value={
                                    Object.keys(
                                        userInfo.campaigns
                                    ).join(", ")
                                }
                              />
                            : <CampaignsList
                                 textfieldClassName = {classes.textfield}
                                 campaigns = {
                                     Object.keys(
                                         userInfo.campaigns
                                     )
                                 }
                                persistChange = {
                                    handleCampaignsChange
                                }

                              />
                                
                    }
                    <Collapse
                        in={['coord', 'asesor'].includes(userInfo.role)}
                        className={classes.textfield}
                    >
                        { userInfo.disabled?
                            <DisabledText
                                variant="outlined"
                                label="Coordinador"
                                value={!userInfo.coord?"Sin Coordinador":`${userInfo.coord.email} (${userInfo.coord.name})`}
                            />
                            :
                            <CoordList
                                current={userInfo.coord}
                                persistChange = {
                                    handleCoordChange
                                }
                                role = {userInfo.role}
                            />
                        }
                    </Collapse>
                    { userInfo.asesores.length > 0 &&
                        <Box className={classes.textfield}>
                                <OutlinedBox
                                    disabled={userInfo.disabled}
                                    label={"Personas a su cargo"}  
                                >
                                            <List className={classes.root}>
                                                {userInfo
                                                    .asesores
                                                    .map((asesor, i) => {
                                                        const handleGoTo = () => {
                                                            history.push("/admin/users/"+asesor.uid);
                                                        };

                                                        const handleRemoveFromList = () => {
                                                            handleRemoveAsesores(asesor.uid);
                                                        };

                                                        return ( 
                                                                <Box key={asesor.uid+i}>
                                                                    <ListItem
                                                                        className={classes.asesor}
                                                                        disabled={userInfo.disabled}
                                                                    >
                                                                        <ListItemText
                                                                            primary={asesor.name}
                                                                            primaryTypographyProps = {{noWrap:true}}
                                                                            secondary={asesor.email}
                                                                            secondaryTypographyProps = {{noWrap:true}} 
                                                                        />
                                                                        <IconButton
                                                                            onClick={handleGoTo}     
                                                                            disabled={userInfo.disabled}
                                                                        >
                                                                            <ExitToAppIcon/>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            disabled={userInfo.disabled}
                                                                            onClick={handleRemoveFromList}
                                                                        >
                                                                            <CloseIcon/>
                                                                        </IconButton>
                                                                    </ListItem>
                                                                    {i != userInfo.asesores.length-1 && <Divider/>}
                                                                </Box>
                                                            )
                                                    })
                                                }
                                            </List>
                                </OutlinedBox>
                        </Box>
                    }
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        className={classes.buttons} 
                    >
                        <Grid item sm={3} xs={5}>
                            <Button
                                fullWidth
                                variant="contained"
                                className={userInfo.disabled?classes.enableUser:classes.disableUser}
                                onClick={handleDisabledChange}
                            >
                                {userInfo.disabled?"HABILITAR":"DESHABILITAR"}
                            </Button>
                        </Grid>
                        <Grid item sm={3} xs={5}>
                            <Button 
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleSaveButtonClick}
                            >
                                {loading ? <CircularProgress size={25}/>:"Guardar"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )


    return (
        <Box>
            <Navbar/>
            <Box mt={3}>
                <Container>
                    <Paper elevation={3}>
                        {content}
                    </Paper>
                </Container>
            </Box>
            <Alert 
                show={updated!=="" || error!=="" } 
                setShow={()=>{setUpdated("");setError("")}}
                type={updated!==""?"success":"error"}
                message={updated!==""?updated:error}
                duration={3000}
            ></Alert>
        </Box>
    )
}

export default AdminProfile;
