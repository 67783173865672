import React, { useState } from 'react';
import {
    Box, Container, Paper, ListItem, ListItemText, ListItemAvatar, Avatar
} from '@material-ui/core';
import Alert from '../../alert';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import "moment/locale/es";
import { useStyles } from "../hooks/style";
import Paginate from "../../custompaginate";
import firebase from "../../../firebase/firebaseInit";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";

import { useHistory } from "react-router-dom";
import BlockIcon from '@material-ui/icons/Block';
import PersonIcon from '@material-ui/icons/Person';

const Row = (elem) => {
    const history = useHistory();
    const { email, name, disabled } = elem.data();

    const handleClick = () => {
        history.push("/admin/users/" + elem.id);
    }

    return (
        <ListItem
            dense
            button
            onClick={handleClick}
        >
            <ListItemAvatar>
                <Avatar>
                    {
                        disabled ?
                            <BlockIcon />
                            :
                            <PersonIcon />
                    }
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={name}
                secondaryTypographyProps={{ noWrap: true }}
                secondary={email}
            />
        </ListItem>
    );
}

const ShowgeneralCreditProposals = () => {
    const classes = useStyles();
    const [error, seterror] = useState(null);

    async function getDataMethodForPaginateElement(limit, searchParameters) {
        let response = [];
        const createCreditInformation = firebase
            .functions()
            .httpsCallable('callable-creditProposals-getAllCredits');

        await createCreditInformation().then(r => {
            response = { ...r.data.message };
        }).catch(e => {
            console.log("" + e);
        });

        return new Promise((resolve, reject) => {
            const limit2 = limit;
            const searchParameters2 = searchParameters;
            resolve(response);
        });
    }

    function backPageFunction(params) {
        // You can define the behavior for the backPageFunction here
    }

    return (
        <Box>
            <Box mt={3}>
                <Container>
                    <Paper elevation={3}>
                        {!error && <Paginate
                            getData={getDataMethodForPaginateElement}
                            renderElem={(e, k, reload) => {
                                return <Row
                                    key={k}
                                    elem={e}
                                />
                            }}
                            enableSearch={true}
                            searchPlaceholder={'Buscar información de crédito'}
                            limit={10}
                            headerName={"Créditos"}
                            headerDescription={"Aqui se encuentra la información utilizada para calcular propuestas de crédito con base en la capacidad de crédito"}
                            descriptionColor={"#757575"} 
                            backPage={null}
                            emptyMessage={"No exiten clientes créditos"}
                            downloadUsersReport={false} 
                            />
                            }
                    </Paper>
                </Container>
            </Box>
        </Box>
    )
}

export default ShowgeneralCreditProposals;