function mround(number: number, multiple: number): number {
    return Math.floor(number / multiple) * multiple;
}

function calculatePV(rate: number, nper: number, pmt: number, fv = 0, type = 0) {
  // Calculate the discount factor
  const discountFactor = Math.pow(1 + rate, -nper);

  // Calculate the present value
  const presentValue = (-pmt * (1 - discountFactor) * (1 + rate * type) / rate) - (fv * discountFactor);

  return presentValue;
}


function iferror<T>(expression: () => T, value_if_error: T): T {
  try {
    return expression();
  } catch {
    return value_if_error;
  }
}

export function calculatePresentValue(paymentCapacity: number, interest: number, numberOfPayments: number): number {
  function innerCalculation(rate: number, value: number, numberOfPayments: number): number {
    return mround(calculatePV(rate, numberOfPayments, -value), 100);
  }
  let result = 0;

  if (paymentCapacity > 0) {
    result = iferror(() => innerCalculation(interest, paymentCapacity, numberOfPayments), 0);
  }
  return result;
}

export function paymentPerPeriod(interest: number, numberOfPayments: number, principal: number) {
  // Calculates the monthly payment for a loan.
  const monthlyInterest = interest / 12;
  return principal * monthlyInterest / (1 - (1 + monthlyInterest)**-numberOfPayments);
}