import React, { Fragment, useState, useEffect } from 'react'

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Header from '../pageheader';
import Divider from '@material-ui/core/Divider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import Alert from '../alert';
import firebase from '../../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";

const useStyles = makeStyles((theme) => ({
    dragndrop: {
    },
    divider: {
        margin: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    inputWrapper: {
        height: "500px",
        width: "100%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
    },

    labelInput: {
        width: "100%",
        height: "100%",
        borderStyle: "dashed",
    },
    fileInput: {
        width: "100%",
        height: "100%",
        display: "none",
    },
    contentFlexRowSpaceBetween: {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center"
    },
    formGroup: {
        marginBottom: "10px",
        marginTop: "10px",
        display:"flex",
        flexDirection: "column",
        alignContent:"center"
    },
    buttonSave: {
        height: "40px",
        marginTop: "20px"
    }

}));

function ReactivateCampaigns() {

    const classes = useStyles();
    const [saveChangesClick, setSaveChangesClick] = useState(false);
    const [campaingsData, setCampaignsData] = useState(null);
    const [reasignRegisters,setReasignedRegisters]=useState(true);
    const [campaingSelected,setCampaingSelected] = useState("si");
    const [messageAlert,setMessageAlert] = useState("");

    const handleChangeCampania = (event) => {
        setCampaingSelected(event.target.value);
    }

    const handleChangeReasignResources = (event) =>{
        setReasignedRegisters(event.target.value);
    }

    const handleSaveChangesClick = () =>{
        const requestData = {
            CAMPAIGNS: [campaingSelected],
            reocuppiedRegistries: reasignRegisters === "si" ? true : false
        }

        const reActivateCampaigns = firebase
        .functions()
        .httpsCallable('callable-campaigns-reactivateCampaigns');
    
    reActivateCampaigns(requestData)
        .then(result => {
            const data_r = result.data;
            setMessageAlert(data_r.message);
            setSaveChangesClick(true);
        })
        .catch(error => {
            console.error(error);
        });    
    }

    const handleAlertClose = () => {
        setSaveChangesClick(false);
    }

    const getCurrentCampaigns = async () => {
        const getCampaigns = firebase
            .functions()
            .httpsCallable('callable-campaigns-getCampaigns');
        try {
            const campaingsRequest = await getCampaigns();
            const campaings = await campaingsRequest.data;
            setCampaignsData(campaings.data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getCurrentCampaigns();
    }, [])

    return (
        <Box>
            <Box mt={3}>
                <Container>
                    <Paper elevation={3} className={classes.paper}>
                        <Header
                            loading={false}
                            pageName={"Reactivar/Desactivar Camapaña"}
                            backPage={"/"}
                            pageDescription={"Control de campañas"}
                            descriptionColor={"#757575"}
                        />
                        <Divider className={classes.divider} />

                        <FormControl className={classes.contentFlexRowSpaceBetween}>
                            <FormGroup className={classes.formGroup}>
                                <FormLabel component="legend">Campañas: </FormLabel>
                                <Select
                                    labelId="selected_campaing-label"
                                    id="selected_campaign"
                                    value={campaingSelected}
                                    label="Elige la campania"
                                    onChange={(event) => handleChangeCampania(event)}
                                >
                                    <MenuItem value=""><em>Seleccione una campaña</em></MenuItem>
                                    {campaingsData !==null ?
                                        campaingsData.map(campaign =>
                                            <MenuItem key={campaign.name} value={campaign.name}>{campaign.name}</MenuItem>
                                        ) : <MenuItem key={"Loading..."} value="">Cargando datos...</MenuItem>}
                                </Select>
                            </FormGroup>

                            <FormGroup className={classes.formGroup}>
                                <FormLabel component="legend">Reasignar registros ya ocupados: </FormLabel>

                                <RadioGroup className={classes.contentFlexRowSpaceBetween} value={reasignRegisters} onChange={handleChangeReasignResources} aria-label="registers_reasigned" name="registers_asigned">
                                    <FormControlLabel value="si" control={<Radio />} label="Si" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormGroup>

                            <FormGroup>
                                <Button color='primary' variant="contained" className={classes.buttonSave} onClick={() => handleSaveChangesClick()} >Guardar Cambios</Button>
                                {saveChangesClick ?
                                    <Alert
                                        show={saveChangesClick}
                                        // type={error!==""?"error":"success"}
                                        message={messageAlert}
                                        duration={10000}
                                        setShow={handleAlertClose}
                                    // copyText={copyText}
                                    /> : <Fragment />}
                            </FormGroup>
                        </FormControl>
                    </Paper>
                </Container>
            </Box>

        </Box>
    )
}

export default ReactivateCampaigns;
