import React, { useEffect,useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Navbar from './navbar';
import Box from '@material-ui/core/Box';
import { makeStyles,lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { beautifyName, pad } from '../util/util';
import Alert from './alert';
import "moment/locale/es";
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import SaveIcon from '@material-ui/icons/Save';
import Toolbar from '@material-ui/core/Toolbar';
import { useContactInfo } from '../contactinfo/contactinfo';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { MuiPickersUtilsProvider,DatePicker,} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import contactOptions from '../contactinfo/options';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3,0,8),
    },
    headerIcons:{
        paddingRight:theme.spacing(2),
    },
    chips:{
        marginTop: theme.spacing(1),
        display:"block",
    },
    rows: {
        '& > *': {
          borderBottom: 'unset',
        },
      },
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:{
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
    title: {
        flex: '1 1 100%',
    },
    positive:{
      fontWeight:"bold",
      color:"white",
      backgroundColor:green[500],
      "&:hover, &:focus": {
        backgroundColor:green[500], 
      },
    },
    later:{
      fontWeight:"bold",
      backgroundColor:amber[500],
    },
    negative:{
      fontWeight:"bold",
      backgroundColor:red[500],
    },
    datePicker:{
      textAlign:"center",
      minWidth:"300px",
    },
    table: {
      minWidth: "500px",
      overflowX: "auto",
    },
    tableCell: {
      paddingRight: 4,
      paddingLeft: 5,
    },
    textarea:{
      resize: "none",
      font:"inherit",
      border:"none",
      width:"90px"
    },
    select:{
      width:"100px",
    },
    dropdownIcon:{
      position:"left",
    },
    skeleton: {
      width: "100%",
    },
    noRegisters:{
      
    },
}));

const RecentContacts = () => {
  const selected=[];
  const classes=useStyles();
  const [page,setPage]=useState(0);
  const [docs,setDocs]=useState(null);
  const [dialog,setDialog]=useState(false);
  const [selectedDoc,setSelectedDoc]=useState(null);
  const [selectedOption,setSelectedOption]=useState(null);
  const [updated,setUpdated]=useState("");
  const [copyText, setCopyText] = useState("");
  const [error,setError]=useState("");
  const [loading,setLoading]=useState(true);
  const [hideNext,setHideNext]=useState(true);
  const {fetchCompleted,updateSurveyMessage,sendInfoToSivi} = useContactInfo();
  const now=new Date();
  const today=new Date(now.getFullYear(),now.getMonth(),now.getDate())
  const fiveDaysAgo=new Date(today.getFullYear(),today.getMonth(),today.getDate()-5);

  // const options=[
  //   {key:1,value:"No responde"},
  //   {key:2,value:"El cliente ha fallecido"},
  //   {key:3,value:"No es el cliente"},
  //   {key:4,value:"El número no existe"},
  //   {key:5,value:"No vive ahí"},
  //   {key:6,value:"No quiere que le vuelvan a marcar"},
  //   {key:7,value:"Se inicia trámite de crédito"},
  //   {key:8,value:"Necesita más información"},
  // ];



  const handleUpdate = (doc,surveyOption) => {
    setDialog(true);
    setSelectedDoc(doc);
    setSelectedOption(surveyOption)
  }

  const handleDialogAccept = () =>{
    setDialog(false);
    const id=selectedDoc.id;
    const contact=selectedDoc.data();
    const prevOption = contactOptions.fromOption(contact.surveyMessage);

    if(selectedOption.category === 'positive'){
        sendInfoToSivi(contact).then(r=>{
          const response=JSON.parse(r.response);
          if(response.message==="error"){
            setError(response.details);
          }
          else{
            const folio=pad(response.idSolicitud,6);
            updateSurveyMessage(id, prevOption, selectedOption, folio).then(()=>{
              setCopyText(folio);
              setUpdated("Folio en SIVI: "+folio);
            }).catch(()=>{
              setError("Hubo un error actualizando el registro");
            })
          }
        })
    } else{
      updateSurveyMessage(id, prevOption, selectedOption).then(()=>{
        setUpdated("Se ha actualizado el registro");
      }).catch(()=>{
        setError("Hubo un error actualizando el registro");
      })
    }
  }

  const handleDialogClose = () =>{
    setDialog(false);
  }

  const handleChangePage = (event,newPage) =>{
    setLoading(true);
    let params={dayStart:fiveDaysAgo,dayEnd:now};
    if(newPage>page){
      params["startAfter"]=docs[docs.length-1];
    }
    else{
      params["endAt"]=docs[0];
    }
    fetchCompleted(params)
      .then(d=>{
        setLoading(false);
        if(d.length===11 || params["endAt"])
          setHideNext(false);
        else
          setHideNext(true);
        setPage(newPage);
        setDocs(d.slice(0,10));
      })
      .catch(e=>{
        setLoading(false);
        setError(e);
      })
  }

  const handleAlertClose = () => {
      setUpdated("");
      setError("");
      setCopyText("");
  }

  useEffect(()=>{
    setLoading(true);
    fetchCompleted({dayStart:fiveDaysAgo,dayEnd:now}).then(d=>{
      setLoading(false);
      if(d.length===11)
        setHideNext(false);
      else
        setHideNext(true);
      setDocs(d.slice(0,10));
    }).catch(e=>{
      setError(e);
    })
  },[updated])

  let content;
  if(docs===null)
    content=(
      <Box px={3} className={classes.table} style={{ tableLayout: 'fixed' }}>
        {
          [...Array(10).keys()].map((e,i)=>{
            return <Skeleton key={i} className={classes.skeleton} animation="wave"/>
          })
        }
      </Box>
    )
  else if(docs.length>0)
    content=(
      <Table className={classes.table} style={{ tableLayout: 'fixed' }}  aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell size="small" className={classes.tableCell} align="center"></TableCell>
                <TableCell size="small" className={classes.tableCell} align="center">Nombre</TableCell>
                <TableCell size="small" className={classes.tableCell} align="center">Completado en</TableCell>
                <TableCell size="small" className={classes.tableCell} align="center">Mensaje</TableCell>
                <TableCell size="small" className={classes.tableCell} align="center">Categoría</TableCell>
                <TableCell size="small" className={classes.tableCell} align="center">Convenio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              docs && docs.map((doc,index) => {
                return (
                  <Row 
                    key={index}
                    doc={doc}
                    handleUpdate={handleUpdate}
                  />
                ) 
              })
            }
          </TableBody>
      </Table>
    )
  else
    content=(
      <Box px={3} py={4} className={classes.noRegisters}>
        <Typography variant="h5">No has completado registros en los últimos 5 días.</Typography>
      </Box>
    )

  return (
      <Box>
        <Navbar/>
        <Box mt={3}>
          <Container component="main" >
            <Paper>
                <EnhancedTableToolbar numSelected={selected.length} loading={loading} fiveDaysAgo={fiveDaysAgo}/>
                <TableContainer component={Paper}>
                  {content}
                </TableContainer>

                <TablePagination
                  component={Paper}
                  count={-1}
                  rowsPerPageOptions={[10]}
                  rowsPerPage={10}
                  page={page}
                  nextIconButtonProps={hideNext?{disabled:true}:{}}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  labelDisplayedRows = {
                    ()=>{
                      if(!docs)
                        return "cargando..."
                      const nRows=docs.length===11?10:docs.length;
                      const from=(page*10);
                      const to=from+nRows;
                      return `${from+1}-${to} ${!hideNext?'de más':''} de ${to}`
                    }
                  }

                  onChangePage={handleChangePage}
                />

            {/* </Box> */}
            </Paper>
          </Container>
          <Alert 
            show={updated!=="" || error!=="" } 
            setShow={handleAlertClose}
            type={updated!==""?"success":"error"}
            message={updated!==""?updated:error}
            duration={6000}
            copyText={copyText}
          />
          <Dialog
            open={dialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{"Confirmación registro"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                  ¿Estas seguro de querer cambiar la respuesta a este registro? 
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleDialogClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleDialogAccept} color="primary" autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
  );
}

function Row({doc,handleUpdate}) {
    const row=doc.data();

    const originalOption = contactOptions.fromOption(row.surveyMessage); 

    const [option, setOption] = useState(originalOption);
    const [open,setOpen] = useState(false);
    const [anchorEl,setAnchorEl] = useState(null);
    const [showFolio, setShowFolio] = useState(false);
    const convenio = row.CONVENIO ? row.CONVENIO : "OTRO";
    const classes = useStyles();

    const options = contactOptions.map((e, i) => {return {key:i, value:e}});

    const summaryCompletedAt = moment.unix(row.completedAt.seconds).locale('es').format('LL');
    const completedAt = moment.unix(row.completedAt.seconds).locale('es').format('LLLL');

    const category=option.category;


    useEffect(()=>{
      setOption(contactOptions.fromOption(doc.data().surveyMessage));
    },[doc])

    const handleSave = () => {
      handleUpdate(doc,option);
    }

    const handleClose = e => {
      setOpen(false);
    }

    const handleChoose = v => {
      setOption(v);
      setOpen(false);
    }

    const handleFolio = e => {
        setShowFolio(!showFolio)
    }

    let chip;
    switch(category){
      case 'positive':
        chip=<Chip label={showFolio?row.folio:"positiva"}
                className={classes.positive}
                clickable
                onClick={handleFolio}
            />;
        break;
      case 'later':
        chip=<Chip label="luego" color="primary" className={classes.later}/>;
        break;
      default:
        chip=<Chip label="negativa" color="primary" classes={{colorPrimary: classes.negative}}/>;
        // break;
      // default:
        // chip=<Chip label="indefinida" />;
    }

    return (
        <React.Fragment>
        <TableRow className={classes.root}>
            <TableCell>
              <IconButton
                aria-label="guardar"
                size="small" 
                disabled={originalOption.option === option.option}
                onClick={handleSave}
              >
                <SaveIcon/>
              </IconButton>
            </TableCell>
            <TableCell size="small" className={classes.tableCell} align="center">{beautifyName(row.NOMBRE)}</TableCell>
            <TableCell size="small" className={classes.tableCell} align="center">{summaryCompletedAt}</TableCell>
            <TableCell size="small" className={classes.tableCell} align="center">

                <Grid container alignItems="center" spacing={0}>
                  <Grid item xs={10}> 
                    {option.option}
                  </Grid>
                  <Grid item xs={2}>
                    {originalOption.category === 'negative' && <IconButton 
                      onClick={(e)=>{setAnchorEl(e.currentTarget);setOpen(true)}}
                    >
                      <ArrowDropDownIcon className={classes.dropdownIcon}/>
                    </IconButton>}
                  </Grid>
                      <Menu
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        {options.map(o => {
                          return <MenuItem
                            key={o.key} 
                            value={o.value}
                            onClick={() => handleChoose(o.value)}
                          >
                            {o.value.option}
                          </MenuItem>
                        })}
                      </Menu>
                </Grid>
            </TableCell>
            <TableCell size="small" className={classes.tableCell} align="center">{chip}</TableCell>
            <TableCell size="small" className={classes.tableCell} align="center">{convenio}</TableCell>
        </TableRow>
        {/* <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                
            </Collapse>
            </TableCell>
        </TableRow> */}
        </React.Fragment>
    );
}

const EnhancedTableToolbar = ({ numSelected,loading,fiveDaysAgo}) => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(null);
    const [open,setOpen]=useState(false);
    const history=useHistory();

    const handleDateChange = (mDate)=>{
        setSelectedDate(new Date(mDate.year(),mDate.month(),mDate.date()));
    }

    const renderLabel = (date) => {
      if (!date) {
        return '';
      } else {
        return date.format("DD [de] MMMM [de] yyyy");
      }
    }

    /*
     * TODO redo al this shit!!
     *
     */

    return (
      <Toolbar
        className={classes.root}
      >
        <Grid container justify="flex-start" alignItems="flex-start">
            <Grid item>
                <IconButton onClick={()=>history.push("/")}>
                    <ArrowBackOutlinedIcon/>
                </IconButton>
            </Grid>

            <Grid item xs={7}>
                <Typography component="h1" variant="h5">
                    Recientes
                    {loading&&<CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />}
                </Typography>
                <Typography variant="caption" component="div" color="textSecondary">
                  {"Mostrando a partir de "+moment(fiveDaysAgo).locale('es').format('LL')}
                </Typography>
            </Grid>
        </Grid>

        {/* <MuiPickersUtilsProvider utils={MomentUtils}  locale="es">
            <DatePicker 
                        disableFuture
                        value={selectedDate}
                        onChange={handleDateChange} 
                        className={classes.datePicker}
                        format="DD [de] MMMM [de] yyyy"
                        label="filtrar por fecha"
                        labelFunc={renderLabel}
            />
        </MuiPickersUtilsProvider> */}
        {/* {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filtrar lista">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </Toolbar>
    );
  };

export default RecentContacts;
