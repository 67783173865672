import React, { useState, useEffect} from 'react';
import {
  makeStyles,
  Container,
  Collapse,
  Box,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Input,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Grid,
  IconButton,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  FileCopy as FileCopyIcon,
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import yellow from '@material-ui/core/colors/yellow';
import clsx from 'clsx';
import { beautifyName, splitName, copyToClipboard, pad, delay } from '../util/util';
import { ContactInfoProvider, useContactInfo } from '../contactinfo/contactinfo';
import RolesContainer from './rolescontainer';
import Alert from '../components/alert';
import contactOptions from '../contactinfo/options';
import {setHeader,setAvailableFields} from './leadVIewerCard/leadViewer';
import RequestSIPRE from '../sipreRobot/requests.ts';

const AsesorWrapper = () =>{
    return ( 
        <RolesContainer>
            <Asesor/>
        </RolesContainer>
    )    
}

/*            
border:"solid",     
borderWidth:'10px',
borderColor: "red", */

const useStyles = makeStyles(theme => {
    return {
        cardrootBorder: {
            minWidth: 275,
            marginTop: 8,
            bottomPadding: 0,
            border:"solid",     
            borderWidth:'10px',
            borderColor: yellow.A400,
        },
        cardroot:{
            minWidth: 275,
            marginTop: 8,
            bottomPadding: 0,
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        autoLeftMargin: {
            marginLeft: 'auto',
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        noYPadding: {
            paddingTop: 2,
            paddingBottom: 2,
        },
        noBottomPadding: {
            paddingBottom: 2,
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
    };
})

export const Asesor = () => {
    const { loading, data, error,setError,wheel,setWheel,completeContact,sendInfoToSivi,checkPaymentavailability, getCaptcha } = useContactInfo();
    const [updated, setUpdated] = useState("");
    const [more, setMore] = useState(false);
    const [openDialogToComplete,setOpenDialogToComplete]=useState(false);
    const [copyText, setCopyText] = useState("");

    /**
     * The next 6 hooks are for control the frontend related to robotSIPRE
     */
    const [paymentAvailabilityMessage, setPaymentAvailabilityMessage] = useState(null);
    const [activeCredits, setActiveCredits] = useState(null);
    const [isPaymentAvailabilityButtonToDialogActive, setisPaymentAvailabilityButtonToDialogActive] = useState(false);
    const [updateAsesorView, setUpdateAsesorView] = useState(false);
    const [creditProposals, setcreditProposals] = useState(null)
    const [surveyAnswer,setSurveyAnswer]=useState(null);
    const [phoneTypes, setPhoneTypes] = useState([null,null,null,null]);
    const classes = useStyles();
    
    
    const requestsToSipre = new RequestSIPRE();

    const handleChange = (event) => {
        console.log(event.target.value);
        setSurveyAnswer(event.target.value);
    };

    const handleComplete = (event,contact) => {
        if(surveyAnswer){
            setOpenDialogToComplete(false);
            setWheel(true);
            if(surveyAnswer.category === 'positive'){
                sendInfoToSivi(contact).then(r=>{
                    const response=JSON.parse(r.response);
                    if(response.message==="error"){
                        setError(response.details);
                        setWheel(false);
                    }
                    else{
                        contact["folio"] = pad(response.idSolicitud,6);
                        completeContact(contact,surveyAnswer).then(()=>{
                            setUpdated("Folio de SIVI: "+pad(response.idSolicitud,6));
                            setCopyText(contact["folio"]);
                            setSurveyAnswer(null);
                        })
                    }
                })
            }
            else
                completeContact(contact,surveyAnswer).then(()=>{
                    setUpdated("Se ha completado el registro exitosamente");
                    setSurveyAnswer(null);
                })
            setPaymentAvailabilityMessage(null);
            setcreditProposals(null);
            setisPaymentAvailabilityButtonToDialogActive(false);
            setUpdateAsesorView(!updateAsesorView);
            setPhoneTypes([null,null,null,null])
        }
    }

    /**
     * 
     * @param {boolean variable. If false, the button "Calcular capacidad" is able, true is disabled} disabledState 
     */
    const changeStatusOfCalcularButton = ()  =>{
        const buttonCalculate = document.getElementById("requestToRobotSipreButton");
        buttonCalculate.disabled = true;
        buttonCalculate.classList.add("Mui-disabled");
        buttonCalculate.tabIndex = -1;
    }

    /**
     * Method to Close the window to avoid  
     */
    const handleCloseDialogToComplete = () => {
        setOpenDialogToComplete(false);
    };

    const handleAlertClose = () => {
        setUpdated("");
        setError("");
        setCopyText("");
    };

    /**
     * Check if the data of the record have the NSS and CURP to do the request to SIPRE.
     * @returns  Return true if it has the necesary info to do the request, false if not.
     */
    const isValidToSipre = async ()=>{

        // Wait for the DOM to fully load before running the function
        await new Promise((resolve) => {
            if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", resolve);
            } else {
                resolve();
            }
        });
        /** First validate that data is not null and has the variables to check exists */
        if(data != null && data.hasOwnProperty('CONVENIO') && data.hasOwnProperty('IDENTIFICADOR') && data.hasOwnProperty('CURP')) {
            let {
                CONVENIO,
                IDENTIFICADOR,
                CURP
            } = data;
            if (CONVENIO === 'IMSS_PENSIONADOS' && IDENTIFICADOR && CURP    ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    /**
     * Render the availability elements for show the user the status of the request to SIPRE.
     */
    const handleRunLoginAndRequestToSipre = () => {
            delay(100).then(() => setUpdated("Se realizará la consulta, por favor espere."));
            changeStatusOfCalcularButton();
            requestsToSipre.checkPaymentavailability(data).then(async r => {
                if( !(r === "")) {
                    const response = JSON.parse(r.response);
                    if(response.Error === undefined) {
                        /* Mostrar capacidad de pago.
                        Cerrar box de dialogo
                        Deshabilitar botón.
                        */
                        if(response.PrestamosVigentes === undefined) {
                            handleAlertClose();
                            setError(response.Error + ", Si el problema persiste refresque la página");
                        }
                        else if ( response.PrestamosVigentes.length > 0) {

                            function createData(
                                strEntidadFinanciera,
                                strCAT,
                                strMonto,
                                strDescuento,
                                strPlazos,
                                strSaldo) {
                                return { strEntidadFinanciera,strCAT,strMonto,strDescuento,strPlazos,strSaldo };
                              }
                            let rows = [
                              ];

                            response.PrestamosVigentes.forEach(credit => {
                                rows.push(
                                    createData(credit.strEntidadFinanciera,
                                    credit.strCAT,
                                    credit.strMonto,
                                    credit.strDescuento,
                                    credit.strPlazos,
                                    credit.strSaldo)
                                );
                            });
                            setActiveCredits(rows);
                        }
                        setcreditProposals(await requestsToSipre.calculateNewCreditProposals(response.strCapacidad, data.CURP));
                        setPaymentAvailabilityMessage(response.strCapacidad);
                        setisPaymentAvailabilityButtonToDialogActive(false);
                    } else {
                        handleAlertClose();
                        setError(response.Error + ", Si el problema persiste refresque la página");
                    }
                }
            }); 
    }

    const handleValidatePhone = (event, contact) => {

        const phones = [contact.TELEFONO,contact.TELEFONO2,contact.TELEFONO3,contact.TELEFONO4];
        let phonesToCheck = [];

        const equivalencia = {
            "mobile": "Celular",
            "unknown": " Número desconocido",
            "landline": "Línea fija"
        };

        for (let index = 0; index < phones.length; index++) {
            const element = typeof phones[index] === "number" ? phones[index].toString():phones[index] ;
            if (element !== null  && element !== undefined) {
                phonesToCheck.push(element);
            }
        }
        try {
            requestsToSipre.getSetTwilioValidationNumbers(phonesToCheck).then(
                async (response) => {
                    delay(10000).then(
                        () => {
                            if (response === undefined || !("types" in response)) {
                                console.log(phones);
                                console.log(phonesToCheck);
                                console.log(JSON.stringify(response));
                            } else {
                                const convertedArray = response["types"].map(element => equivalencia[element] || element);
                                setPhoneTypes(convertedArray);
                            }
            });});
        } catch (error) {
            console.log(error);
        }
    }

    // const options=[
    //     {key:1,value:"No responde"},
    //     {key:2,value:"El cliente ha fallecido"},
    //     {key:3,value:"No es el cliente"},
    //     {key:4,value:"El número no existe"},
    //     {key:5,value:"No vive ahí"},
    //     {key:6,value:"No quiere que le vuelvan a marcar"},
    //     {key:7,value:"Se inicia trámite de crédito"},
    //     {key:8,value:"Necesita más información"},
    // ];

    const options = contactOptions.map((e,i) => {return {key:i,value:e}});

    let jsx;

    if (loading) {
        jsx = (
            <Box mt={8}>
                <LinearProgress color="primary" />
            </Box>
        )
    } else if (data === false) {
        jsx = <Container>
            <Typography component="h4" variant="h4">
                No hay ningún registro disponible para los códigos postales que tienes asociados. Pide a tu coordinador que te asigne más.
            </Typography>
        </Container>
    } else {
        const contact=data;
        let {
            CP,
            NOMBRE,
            TELEFONO,
            TELEFONO2,
            TELEFONO3,
            TELEFONO4,
            CONVENIO,
            CAMPAIGNS,
            CURP, 
            NSS,
            RFC,
            LIQUIDO_JC,
            EDAD, // snte 26
            CAP, // Snte18
            OFERTA, // Snte18
            TIPO_DE_NOMINA, // Salud tamps
            CIUDAD, // SNTE SLP
            LADA, // SNTE_18_MICHOACAN
            PlazoContratado, //CONSUBANCO
            Plazo, //CONSUBANCO
            TasaActual, //CONSUBANCO
            CatActual, //CONSUBANCO
            DescuentoOferta, //CONSUBANCO
            DescuentoActual, //CONSUBANCO
            MontoContratado, //CONSUBANCO
            MontoBruto, //CONSUBANCO
             
            MUNICIPIO_DOMICILIO, //SLP SNTE GRAL
            MUNICIPIO_CT, //SLP SNTE GRAL

            PLAZAS,
            ANTIGUEDAD,
            EMAIL,
        } = data;

        let subheader = setHeader(CP, CAMPAIGNS);
        let content;
        const creditsTable = (activeCredits &&     
            <Typography variant="body1">
                Cŕeditos Vigentes
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>Entidad financiera</TableCell>
                    <TableCell align="right">CAT</TableCell>
                    <TableCell align="right">Crédito inicial</TableCell>
                    <TableCell align="right">Descuento</TableCell>
                    <TableCell align="right">Plazos</TableCell>
                    <TableCell align="right">Saldo actual</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activeCredits.map((row) => (
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                        {row.strEntidadFinanciera}
                        </TableCell>
                        <TableCell align="right">{row.strCAT}</TableCell>
                        <TableCell align="right">{row.strMonto}</TableCell>
                        <TableCell align="right">{row.strDescuento}</TableCell>
                        <TableCell align="right">{row.strPlazos}</TableCell>
                        <TableCell align="right">{row.strSaldo}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer></Typography>);

        if("valid_fields" in contact)  {
            content = (
                        <CardContent className={classes.noYPadding}>
                            <Box><Typography variant="body1">Nombre: {beautifyName(NOMBRE)}</Typography></Box>
                            {CP && <Box><Typography variant="body1">Código Postal: {CP}</Typography></Box>}
                            {setAvailableFields(data)}
                            {TELEFONO && <Box><Typography variant="body1">Telefono: {TELEFONO}. {phoneTypes[0] && <Box>{phoneTypes[0]}</Box>}</Typography></Box>}
                            {TELEFONO2 && <Box><Typography color="primary" variant="body1">Telefono 2: {TELEFONO2}. {phoneTypes[1] && <Box>{phoneTypes[1]}</Box>}</Typography></Box>}
                            {TELEFONO3 && <Box><Typography color="primary" variant="body1">Telefono 3: {TELEFONO3}. {phoneTypes[2] && <Box>{phoneTypes[2]}</Box>}</Typography></Box>}
                            {TELEFONO4 && <Box><Typography color="primary" variant="body1">Telefono 4: {TELEFONO4}. {phoneTypes[3] && <Box>{phoneTypes[3]}</Box>}</Typography></Box>}
                            {CONVENIO && <Box><Typography variant="body1">Convenio: {CONVENIO}</Typography></Box>}
                            {contact["EXTRA"] && typeof contact["EXTRA"] == 'string' && <p/>}
                            {contact["EXTRA"] && typeof contact["EXTRA"] == 'string' && <Box><Typography variant="body1">Información adicional: {contact.EXTRA}</Typography></Box>}
                            {paymentAvailabilityMessage && <p/>}
                            {paymentAvailabilityMessage && <Box><Typography variant="body1">Capacidad de pago: {paymentAvailabilityMessage}</Typography></Box>}
                            {creditProposals && <p/>}
                            {creditProposals && <Box><Typography variant="body1">Propuestas de crédito: <br/> {creditProposals}</Typography></Box>}
                            {activeCredits && <p/>}
                            {creditsTable}
                        </CardContent>
            );   
        } else {
            content = (
                        <CardContent className={classes.noYPadding}>
                            <Box><Typography variant="body1">Nombre: {beautifyName(NOMBRE)}</Typography></Box>
                            {CP && <Box><Typography variant="body1">Código Postal: {CP}</Typography></Box>}
                            {CAMPAIGNS && <Box><Typography variant="body1">Campañas: {CAMPAIGNS.join(",")}</Typography></Box>}
                            {CAMPAIGNS && (CAMPAIGNS.includes("SNTE26_0321") || CAMPAIGNS.includes("SNTE12_0321") || CAMPAIGNS.includes("SNTE18_0321") || CAMPAIGNS.includes("PLEY_CHIHUAHUA_0421") || CAMPAIGNS.includes("PLEY_JALISCO_0321") || CAMPAIGNS.includes("PLEY_CDMX_0321") || CAMPAIGNS.includes("PLEY_Bajio_0321") || CAMPAIGNS.includes("JUB_SALTILLO_0421") || CAMPAIGNS.includes("PLEY_MTY_0421") || CAMPAIGNS.includes("PLEY_AGS_0421") || CAMPAIGNS.includes("PLEY_NORTE_0421") || CAMPAIGNS.includes("SNTE_PROPIO_0421") || CAMPAIGNS.includes("JUB_DGO_0421") || CAMPAIGNS.includes("PLEY_DGO_0421") || CAMPAIGNS.includes("PLEY_NORESTE_0421") || CAMPAIGNS.includes("SALUD_CD_VICTORIA_0521_GPF") || CAMPAIGNS.includes("PLEY_TOLUCA_0521") || CAMPAIGNS.includes("SALUD_TAMPS_0521_GPF") || CAMPAIGNS.includes("PLEY_SLP_0521") || CAMPAIGNS.includes("GOB_HGO_0521") || CAMPAIGNS.includes("IMSS_CENTRO_0621") || CAMPAIGNS.includes("SNTE12_0621_BY_LC") || CAMPAIGNS.includes("JUB_MTY_0621") || CAMPAIGNS.includes("SLP_SNTE26_0621_NVO") || CAMPAIGNS.includes("SNTE26_0721_REF_BY_2") || CAMPAIGNS.includes("SNTE_PROPIO_0721_2") || CAMPAIGNS.includes("PEMEX_0721_MTN_DOM") || CAMPAIGNS.includes("SNTE_18_MICH_0821") || CAMPAIGNS.includes("SNTE26_0122") || CAMPAIGNS.includes("JC_PENSIONADOS_0222") || CAMPAIGNS.includes("JC_PENSIONADOS_0322")  || CAMPAIGNS.includes("JC_OAXACA_0222") || CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_XTU") || CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_GRAL") || CAMPAIGNS.includes("SNTE26_0522_TC_NVO")) && <Box><Typography variant="body1">RFC: {RFC}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SALUD_TAMPS_0521_GPF") && <Box><Typography variant="body1">Tipo de Nomina: {TIPO_DE_NOMINA}</Typography></Box>}
                            {CAMPAIGNS && (CAMPAIGNS.includes("JC_PENSIONADOS_0222") || CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_XTU") || CAMPAIGNS.includes("PLEY_NAC_0522_JC_NVO"))&& CURP && <Box><Typography variant="body1">CURP: {CURP}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("JC_PENSIONADOS_0322") && CURP && <Box><Typography variant="body1">CURP: {CURP}</Typography></Box>}
                            {CAMPAIGNS && (CAMPAIGNS.includes("JC_PENSIONADOS_0222") || CAMPAIGNS.includes("PLEY_NAC_0522_JC_NVO")) && NSS && <Box><Typography variant="body1">Número de Seguridad Social: {NSS}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("JC_PENSIONADOS_0322") && NSS && <Box><Typography variant="body1">Número de Seguridad Social: {NSS}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("JC_LUIS_0322") && CURP && <Box><Typography variant="body1">CURP: {CURP}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("JC_LUIS_0322") && NSS && <Box><Typography variant="body1">Número de Seguridad Social: {NSS}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("JC_LUIS_0322") && LIQUIDO_JC && <Box><Typography variant="body1">Liquido: {LIQUIDO_JC}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SNTE18_0321") && <Box><Typography variant="body1">Capacidad: {CAP}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SNTE18_0321") && <Box><Typography variant="body1">Oferta: {OFERTA}</Typography></Box>}
                            {CAMPAIGNS && (CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_XTU") || CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_GRAL")) && EDAD && <Box><Typography variant="body1">Edad: {EDAD}</Typography></Box>}
                            {CAMPAIGNS && (CAMPAIGNS.includes("SLP_SNTE26_0621_NVO") || CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_XTU") || CAMPAIGNS.includes("PLEY_NORTE_0522_JC_NVO") || CAMPAIGNS.includes("SNTE26_0522_TC_NVO") || CAMPAIGNS.includes("PLEY_NAC_0522_JC_NVO")) && CIUDAD && <Box><Typography variant="body1">Ciudad: {CIUDAD}</Typography></Box>}
    
                            {CAMPAIGNS && CAMPAIGNS.includes("SLP_SNTE26_0621_NVO") && <Box><Typography variant="body1">Antigüedad: {ANTIGUEDAD}</Typography></Box>}
                        
                            {CAMPAIGNS && (CAMPAIGNS.includes("SLP_SNTE26_0621_NVO") || CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_XTU") || CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_GRAL")) && EMAIL && <Box><Typography variant="body1">Email: {EMAIL}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_GRAL") && MUNICIPIO_DOMICILIO && <Box><Typography variant="body1">Municipio domicilio: {MUNICIPIO_DOMICILIO}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SLP_SNTE26_0422_NVO_GRAL") && MUNICIPIO_CT && <Box><Typography variant="body1">Municipio ct: {MUNICIPIO_CT}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SNTE_18_MICH_0821") && PLAZAS && (PLAZAS.length > 0) && <Box><Typography variant="body1">Motivo (uno por plaza): {PLAZAS.map(e => { return e["MOTIVO"]}).join(",")}</Typography></Box>} 
                            {CAMPAIGNS && CAMPAIGNS.includes("SNTE_18_MICH_0821") && LADA && <Box><Typography variant="body1">Lada: {LADA}</Typography></Box>} 
                            
    
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && <p/>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && <Box><Typography variant="body1">Informacion del crédito actual</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && PlazoContratado && <Box><Typography variant="body1">Plazo: {PlazoContratado}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && TasaActual && <Box><Typography variant="body1">Tasa Actual: {TasaActual}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && CatActual && <Box><Typography variant="body1">Cat Actual: {CatActual}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && MontoContratado && <Box><Typography variant="body1">Monto Contratado: {MontoContratado}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && DescuentoActual && <Box><Typography variant="body1">Descuento: {DescuentoActual}</Typography></Box>}
    
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && <p/>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && <Box><Typography variant="body1">Propuesta del crédito</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && MontoBruto && <Box><Typography variant="body1">Monto Bruto: {MontoBruto}</Typography></Box>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && DescuentoOferta && <Box><Typography variant="body1">Descuento Oferta: {DescuentoOferta}</Typography></Box>}
    
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && <p/>}
                            {CAMPAIGNS && CAMPAIGNS.includes("SINAR_CSP") && <Box><Typography variant="body1">Telefonos del cliente</Typography></Box>}
                            {TELEFONO && <Box><Typography variant="body1">Telefono: {TELEFONO}. {phoneTypes[0] && <Box>{phoneTypes[0]}</Box>}</Typography></Box>}
                            {TELEFONO2 && <Box><Typography color="primary" variant="body1">Telefono 2: {TELEFONO2}. {phoneTypes[1] && <Box>{phoneTypes[1]}</Box>}</Typography></Box>}
                            {TELEFONO3 && <Box><Typography color="primary" variant="body1">Telefono 3: {TELEFONO3}. {phoneTypes[2] && <Box>{phoneTypes[2]}</Box>}</Typography></Box>}
                            {TELEFONO4 && <Box><Typography color="primary" variant="body1">Telefono 4: {TELEFONO4}. {phoneTypes[3] && <Box>{phoneTypes[3]}</Box>}</Typography></Box>}
                            {CONVENIO && <Box><Typography variant="body1">Convenio: {CONVENIO}</Typography></Box>}
                            {contact["EXTRA"] && typeof contact["EXTRA"] == 'string' && <p/>}
                            {contact["EXTRA"] && typeof contact["EXTRA"] == 'string' && <Box><Typography variant="body1">Información adicional: {contact.EXTRA}</Typography></Box>}
                            {paymentAvailabilityMessage && <p/>}
                            {paymentAvailabilityMessage && <Box><Typography variant="body1">Capacidad de pago: {paymentAvailabilityMessage}</Typography></Box>}
                            {activeCredits && <p/>}
                            {creditsTable}
                        </CardContent>
            );            
        }
        jsx = (
            <Card className={CAMPAIGNS?classes.cardrootBorder:classes.cardroot}>
                <CardHeader
                    className={classes.noBottomPadding}
                    action={
                        <IconButton aria-label="opciones">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={beautifyName(NOMBRE)}
                    subheader={subheader}
                />
                <CardActions className={classes.noYPadding} disableSpacing>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: more,
                        })}
                        onClick={(e) => setMore(!more)}
                        aria-expanded={more}
                        aria-label="mostrar mas"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>

                <Collapse in={more} timeout="auto" unmountOnExit>
                    {content}
                    <CardActions >
                        <Grid container>
                                <Grid item xs={4}>
                                    {isPaymentAvailabilityButtonToDialogActive && <Button 
                                        onClick={handleRunLoginAndRequestToSipre}
                                        color="primary" 
                                        variant="contained" 
                                        id='requestToRobotSipreButton'
                                        className={classes.autoLeftMargin}
                                        fullWidth
                                    >
                                        {wheel?<CircularProgress size={25}/>:"Capacidad de pago"}
                                    </Button>}
                                    <Button 
                                        onClick={e=>{handleValidatePhone(e,contact)}}
                                        color="primary" 
                                        variant="contained" 
                                        className={classes.autoLeftMargin}
                                        fullWidth
                                    >
                                        Validar teléfonos
                                    </Button>

                                </Grid>
                                <Grid item xs={4}/>
                                <Grid item xs={4}>
                                    <Button 
                                        onClick={()=>{
                                            setOpenDialogToComplete(true);
                                        }}
                                        color="primary" 
                                        variant="contained" 
                                        className={classes.autoLeftMargin}
                                        disabled={wheel}
                                        fullWidth
                                    >
                                        {wheel?<CircularProgress size={25}/>:"Completar"}
                                    </Button>
                                </Grid>
                        </Grid>            
                    </CardActions>
                </Collapse>
                <div>
                    <Dialog disableBackdropClick disableEscapeKeyDown open={openDialogToComplete} onClose={handleCloseDialogToComplete}>
                        <DialogTitle>Selecciona la respuesta</DialogTitle>
                        <DialogContent>
                            <form className={classes.container}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-dialog-select-label">
                                        Respuesta
                                    </InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={surveyAnswer || ''}
                                        label={surveyAnswer ? surveyAnswer.option : "Selecciona..."}
                                        onChange={handleChange}
                                        input={<Input />}
                                    >
                                        <MenuItem value="" disabled>
                                            <em>Selecciona...</em>
                                        </MenuItem>
                                        {options.map(e=>{return <MenuItem key={e.key} value={e.value}>{e.value.option}</MenuItem>})}
                                    </Select>
                                </FormControl>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialogToComplete} color="primary">
                                Cancelar
                            </Button>
                            <Button 
                                onClick={e=>{handleComplete(e,contact)}}
                                color="primary" 
                                disabled={!surveyAnswer || wheel}
                            >
                                    Completar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Card>

        );

    }
    return (
        <Box>
            {jsx}
            <Alert
                show={(updated!=="")||(error!=="")}
                type={error!==""?"error":"success"}
                message={updated||error}
                duration={10000}
                setShow={handleAlertClose}
                copyText={copyText}
            />
        </Box>
    );

    
}

export default AsesorWrapper;