import firebase from '../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";


export const AdminInfo = () =>{

    const fetchUsers = (limit, {searchBy,startAfter,endAt}) => {
        let query;
        if(searchBy)
            query=firebase
                .firestore()
                .collection('users')
                .where('searchIndices','array-contains',searchBy.toLowerCase())
                .orderBy('email')
        else
            query=firebase
                .firestore()
                .collection('users')
                .orderBy('email')
        if(startAfter)
            query=query
                .startAfter(startAfter)
                .limit(limit);
        if(endAt)
            query=query
                .endAt(endAt)
                .limitToLast(limit);
        if((!startAfter)&&(!endAt))
            query=query
                .limit(limit)
        return query
            .get()
            .then(res => {return res.docs});
    }

    const fetchCampaigns = () => {
        const q = firebase                
            .firestore()
            .collection('campaigns')
        return q.get()     
            .then(res => {
                return res.docs.map(e => {
                    const {name, description} = e.data();
                    return {
                        name,
                        description,
                    }
                })

            })
    }

    //TODO: How the hell should i handle regional coords
    //directly from firestore, or use a cloud function
    const fetchCoords = () => {
        const q = firebase
             .firestore()
             .collection('users')
             .where('role','==','coord')
        return q.get()
            .then(res => {
                return res.docs.map(e => {
                    const {email,name} = e.data();
                    return {
                        uid:e.id,
                        email,
                        name
                    }
                });
            })
    }
    
    const fetchUser = (uid) => {
        const f = firebase
            .functions()
            .httpsCallable('callable-controlPanel-getUser');
        return f({uid})
    }

    const updateUserInfo = (userInfo, removed) => {
        const f = firebase
            .functions()
            .httpsCallable('callable-controlPanel-updateUser');
        return f({userInfo, removed});
    }

    const fetchRegionalCoords = ()  => {
        const q = firebase
            .firestore()
            .collection("users")
            .where("role", "==", "regional")
        return q.get()    
            .then(res => {
                return res.docs.map(e => {
                    const {email, name} = e.data();
                    return {
                        uid: e.id, 
                        email, 
                        name
                    };
                })
            })
    }

    const fetchRegionalCoordsPaginate = (limit, {startAfter, endAt}) => {
        let query=firebase
                .firestore()
                .collection('users')
                .where("role", "==", "regional")
                .orderBy('email')
        if(startAfter)
            query=query
                .startAfter(startAfter)
                .limit(limit);
        if(endAt)
            query=query
                .endAt(endAt)
                .limitToLast(limit);
        if((!startAfter)&&(!endAt))
            query=query
                .limit(limit)
        return query
            .get()
            .then(res => {return res.docs})
            .catch(e => {console.log(e)})
    }

    const fetchUsersRespondingTo = (uid) => {
        const q = firebase
            .firestore()
            .collection("users")
            .where("coord", "==", uid)
        return q.get()
            .then(res => {
                return res.docs.map(e => {
                    const {email, name} = e.data();
                    return {
                        uid: e.id,
                        email,
                        name,
                    };
                })
            })
    }

    return {
        fetchUsers,
        fetchUser,
        fetchCoords,
        fetchCampaigns,
        fetchRegionalCoords,
        fetchRegionalCoordsPaginate,
        fetchUsersRespondingTo,
        updateUserInfo,
    };

}

export default AdminInfo;
