// Function to calculate age from a Mexican CURP
export function getAgeFromCURP (curp: string): number | null{
	// CURP format: 4 letters, 6 digits, 6 digits, 1 letter, 2 digits (YY), 2 digits (MM), 2 digits (DD), 1 letter (gender), 2 letters (state code)
	// For example: ROCA890101HDFXXN04
  
	// Check if the provided CURP is valid
	//Example RAMJ470330HMCMNN06
	
	const curpRegex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
	if (!curp.match(curpRegex)) {
	  console.error('Invalid CURP format.');
	  return null;
	}
  
	// Extract birthdate information from CURP
	const year = parseInt(curp.substring(4, 4  + 2), 10);
	const month = parseInt(curp.substring(6, 6 + 2), 10);
	const day = parseInt(curp.substring(8, 8 + 2), 10);
  
	// Determine the century (1900s or 2000s) based on the first character of the CURP
	const centuryIndicator = curp.charAt(16);
	const century = centuryIndicator === 'A' ? 2000 : 1900;
  
	// Get the current date
	const currentDate = new Date();
  
	// Calculate the birth year using the century and year from the CURP
	const birthYear = century + year;
  
	// Calculate the age
	let age = currentDate.getFullYear() - birthYear;
  
	// Adjust the age if the birth month and day haven't been reached yet this year
	const currentMonth = currentDate.getMonth() + 1;
	const currentDay = currentDate.getDate();
	if (currentMonth < month || (currentMonth === month && currentDay < day)) {
	  age--;
	}
  
	return age;
  }