import React from 'react';
import SignIn from './components/signin'
import Home from './components/home'
import Register from './components/register'
import {ProvideViewport} from "./viewport/viewport.js"; 
import {ProvideAuth} from "./auth/auth.js";
import {VerifiedRoute,UnverifiedRoute,LoggedOutRoute,AdminRoute,NoPhoneRoute} from "./components/routes"
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Unverified from './components/unverified'
import pink from '@material-ui/core/colors/pink';
import Recover from './components/recoverpassword';
import UserList from './components/admin/list';
import AdminProfile from './components/admin/profile';
import EmailSignIn from './components/emailsignin';
import Interested from './components/interested';
import AddPhone from './components/addphone';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import RecentContacts from './components/recent';
import Profile from './components/profile';
import NewCampaign from './components/campaigns/create';
import ChartTest from './components/reports/test';
import SinarReport from './components/reports/sinar';
import TreeExample from './components/admin/idfk';
import ConvenioSelector from './components/selectconvenio';
import AddNewFiles from './components/newFiles/addNewFiles';
import GeneralCreditProposals from "./components/creditProposals/generalCreditProposals";
import GeneralCampaigns from './components/campaigns/generalCampaigns';
import SearchInformation from './components/search/SearchInformation';

const themeLight = createMuiTheme({
  palette: {
    primary: {
      main: '#3d5caa',
    },
    secondary: pink,
  },
  default: "#f1f1f1",
});

function App() {
  return (
    <ProvideViewport>
        <ProvideAuth>
            <Router>
              <MuiThemeProvider theme={themeLight}>
                <CssBaseline />
                <Switch>
                  <LoggedOutRoute path='/emailLogin' component={EmailSignIn}/>
                  <Route path='/signin' component={SignIn} />
                  <Route path='/register' component={Register} />
                  <Route path='/recover' component={Recover}/>
                  <Route path='/addfiles' component={AddNewFiles}/>
                  <UnverifiedRoute path='/unverified' component={Unverified}/>
                  {/*<NoPhoneRoute path='/addphone' component={AddPhone}/>*/}
                  <AdminRoute path='/admin/search' component={SearchInformation} />
                  <AdminRoute path='/admin/campaigns/manageCampaign' component={GeneralCampaigns} />
                  {/* <AdminRoute path='/admin/campaigns/new' component={NewCampaign} /> */}
                  <AdminRoute path='/admin/users/:uid' component={AdminProfile}/>  
                  <AdminRoute path='/admin/users' component={UserList}/>
                  <AdminRoute path='/admin/structure' component={TreeExample}/>
                  <AdminRoute path='/admin/creditProposals' component={GeneralCreditProposals} />
                  <VerifiedRoute path='/recent' component={RecentContacts}/>
                  <VerifiedRoute path='/interested' component={Interested}/> 
                  <VerifiedRoute path='/profile' component={Profile}/>
                  <VerifiedRoute path='/reports/sinar' component={SinarReport}/>
                  <VerifiedRoute path="/test" component = {ConvenioSelector} />
                  <VerifiedRoute path='/' component={Home} />


                </Switch>
              </MuiThemeProvider>
            </Router> 
        </ProvideAuth>
    </ProvideViewport>
  );
}

export default App;
