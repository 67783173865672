import React,{useState, Fragment, useEffect} from 'react';
import Tree, { useTreeState } from 'react-hyper-tree'
import AdminInfo from '../../admininfo/admininfo';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../pageheader';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Navbar from '../navbar';
import Divider from '@material-ui/core/Divider';
import {useViewport} from '../../viewport/viewport';
import {summarize} from '../../util/util';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RemoveIcon from '@material-ui/icons/Remove';
import { useHistory } from "react-router-dom";
import Paginate from '../custompaginate.jsx';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2),
    },
}))


const UserLabel = ({node, onToggle}) => {
    let icon; 
    const classes = useStyles();
    const history = useHistory();
    const {width} = useViewport();
    const name = node.data.name ? node.data.name : "";
    const email = node.data.email ? node.data.email : "";
    if(node.options.loading)
        icon = <CircularProgress size = {24}/>;
    else if(node.data.depth === 2)
        icon = <RemoveIcon/>
    else if(node.options.opened)
        icon = <ExpandMoreIcon/>
    else 
        icon = <ChevronRightIcon/>

    const handleViewUser = () => {
        history.push("/admin/users/"+node.id);
    }

    return (
        <Box>        
            <ListItem
                dense
            >
                <IconButton
                    onClick = {onToggle}
                    disabled = {
                        node.options.loading 
                        || node.data.depth === 2
                    }
                    size = {"small"}
                >
                    {icon}
                </IconButton>
                <ListItemText
                    primaryTypographyProps = {
                        {noWrap: true}
                    }
                    primary = {name}
                    secondaryTypographyProps = {
                        {noWrap:true}
                    }
                    secondary = {email}
                />
                <IconButton
                    size = {"small"}
                    onClick = { handleViewUser }
                >
                    <ExitToAppIcon/>                
                </IconButton>
            </ListItem>
        </Box>
    )
}

const UserTree = ({regionalCoords}) => {
    const { fetchUsersRespondingTo } = AdminInfo();
    const { required, handlers } = useTreeState({
        data: regionalCoords? regionalCoords : {},
        id: "userStructureTree"
    });

    if(!regionalCoords)
        return (
            <LinearProgress /> 
        );
    else
        return (
            <Tree
                {...required}
                {...handlers}       
                disableHorizontalLines={false}
                disableVerticalLines={false}
                horizontalLineStyles={{
                    stroke: "#ccc",
                    strokeWidth: 2,
                }}
                verticalLineStyles={{
                    stroke: '#ccc',
                    height: 44,
                    strokeWidth: 2,
                }}
                gapMode={"margin"}
                verticalLineTopOffset={-20}
                verticalLineOffset={30}
                depthGap = {40}
                staticNodeHeight = {60}
                renderNode = {
                    ({node, onToggle}) => 
                        <UserLabel 
                            node = {node}
                            onToggle={onToggle}
                        />
                } 
            />
        );
}

const TreeExample = () => {
    const { 
        fetchRegionalCoordsPaginate,
        fetchRegionalCoords,
        fetchUsersRespondingTo,
    } = AdminInfo();
    const classes = useStyles();

    const userToNode = (user, depth) => {
        return {
            id: user.multiFactor.user.uid,
            depth,
            name: user.name,
            email: user.email,
            getChildren,
        };
    }

    const getChildren = ({node}) => {
        return fetchUsersRespondingTo(node.id)
            .then(users => {
                const res = users
                    .map(user => {
                       return userToNode(user, node.data.depth + 1);
                    });    
                return res;
            })
    }

    const renderBlock = (data) => {
        const regionals = data
            .map(e => {
                const {
                    name,
                    email,
                } = e.data();
                return userToNode({
                    uid: e.id,
                    name,
                    email,
                }, 0)
            });
        return (
            <UserTree
                regionalCoords = {regionals}
            />
        );
    }

    return (
        <Box>
            <Navbar/>
            <Box mt={3}>
                <Container>
                    <Paper 
                        elevation={3} 
                        className={classes.root}
                    >
                        <Paginate
                            getData = {fetchRegionalCoordsPaginate}
                            renderBlock = {renderBlock}
                            limit = {1}
                            headerName = {"Estructura de Usuarios"}
                            headerDescription = {"estructura jerarquica de los usuarios"}
                            descriptionColor = {"#757575"}
                            backPage = {"/"}
                            emptyMessage = {"No hay coordinadores regionales"}
                            downloadUsersReport = {false}
                        />
                        <Divider className={classes.divider}/>
                    </Paper>
                </Container>
            </Box>
        </Box>
    );

}

export default TreeExample;
