import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
    
        </Grid>
        <Grid container item xs={12} spacing={3} >
            <Grid item xs={4}>
              <Box></Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={classes.paper}><CircularProgress size={100} ></CircularProgress></Box>
            </Grid>
            <Grid item xs={4}>
              <Box></Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>

        </Grid>
      </Grid>
    </div>
  );

}