import React, { useState, useEffect, createContext,useContext } from "react";
import { useAuth } from '../auth/auth';
import firebase from '../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";

export const coordContext = createContext();

export const useCoordInfo = () => {
    return useContext(coordContext);
};

export const CoordInfoProvider = ({children}) => {
    const auth = useAuth();
    const [data,setData]=useState(null);
    const [loading,setLoading]=useState(true);
    const [error,setError]=useState("");
    // const [updated,setUpdated]=useState("");

    const setPostalCodes = (uid,cps) =>{
        return firebase.firestore()
            .collection("users")
            .doc(uid)
            .update({postalCodes:cps})
            // .then(()=>setUpdated("Se han actualizado los códigos postales correctamente"))
            // .catch(er=>setError("Ha ocurrido un error asignado los códigos postales"));
    }

    const removeFromCoordList = (uid) => {
        const f = firebase
            .functions()
            .httpsCallable('callable-coordFun-removeFromList');
        return f({uid});
    }

    const setUserCoord = (uid) =>{
        return firebase.firestore()
            .collection("users")
            .doc(uid)
            .update({
                coord:firebase.auth().currentUser.uid,
            })
    }

    useEffect(()=>{
        console.log("loading coord data");
        setLoading(true);
        const unsubscribe = firebase
            .firestore()
            .collection('users')
            .where("coord","==",auth.user.multiFactor.user.uid)
            .onSnapshot(
            qr => {
                setData(qr.docs)
                setLoading(false)
            },
            err => {
                setError(err)
            }
        )
        return () => unsubscribe()
    },[])

    const ret={ data,
                loading,
                setPostalCodes,
                removeFromCoordList,
                error,
                setUserCoord,
                setError,
            };

    return <coordContext.Provider value={ret}>{children}</coordContext.Provider>;
}
