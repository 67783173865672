import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Navbar from '../../navbar';
import Header from '../../pageheader';
import { makeStyles } from '@material-ui/core/styles';
import {useViewport} from '../../../viewport/viewport';
import {useAuth} from '../../../auth/auth';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
    },
}));

const DirectorSummary = ({}) => {
    const classes = useStyles();
    const {width, height} = useViewport();
    const auth = useAuth();
    const user = auth.user;
    const param = user.email.split("@")[0]
    return (
        <Box>
            <Navbar/>
            <Box mt={3}>
                <Container>
                    <Paper
                        className={classes.paper}
                        elevation={3}
                    >
                        <Header
                            loading= {false}     
                            pageName={"Reporte Director Digital"}
                            backPage={"/"}
                            pageDescription={"resumen de actividad para director digital"}
                            descriptionColor={"#757575"}
                        />
                        <Divider/>
                        <iframe 
                            style={{marginTop:"10px"}}
                            width="100%"
                            height={`${height - 100}`}
                            src={`https://datastudio.google.com/embed/reporting/6f9177db-d8d6-493e-b51f-5022d52a96d3/page/EyGxB?params=%7B%22df13%22:%22include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${param}%22%7D`}
                            frameBorder="0"
                        ></iframe>    
                    </Paper>
                </Container>
            </Box>
        </Box>
    )
}

export default DirectorSummary;
