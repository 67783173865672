import React, { useEffect,useState } from 'react';

import Navbar from '../navbar';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from "../../auth/auth.js";

import NewCampaign from './create'
import ReactivateCampaigns from './reactivateCampaigns';


function GeneralCampaigns () {

    
    
    // const classes = useStyles();
    const [tabToShow,setTabToShow]=useState("createCampaign");
    //since this is a protected route
    //user should be loaded.
    const auth = useAuth();

    //force token refresh on first login. (to update token emailVerified status)
    if(!auth.user.firstLogin)
        auth.firstLogin(auth.user);

    let content;
    if(auth.user.firstLogin===false)
        content=<Box mt={8}><LinearProgress color="primary"/></Box>;
    else{
        if(auth.user.role==="coord" || auth.user.role==="admin")
            content = <NewCampaign/>;
        else {

        }
    }


  return (
    <Box>
            <Navbar/>
            <Box>
                <Paper>
                    <Tabs
                        value={tabToShow}
                        onChange={(e, v) => setTabToShow(v)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab value="createCampaign" label="Crear Nueva Camapaña" />
                        <Tab value="ReActivateDisactivateCampaing" label="Activar/Desactivar Campaña" />
                    </Tabs>
                </Paper>
                {tabToShow === 'createCampaign' && <NewCampaign/>}
                {tabToShow === 'ReActivateDisactivateCampaing' && <ReactivateCampaigns/>}

            </Box>
        </Box>
  )
}

export default GeneralCampaigns;
