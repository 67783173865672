import React, { useContext, useState } from 'react';
import {useAuth} from '../../auth/auth';
import LeaderSummary from './leader/sinar';
import DirectorSummary from './director/sinar';
import AgentSummary from './agent/sinar';

// TODO: Improve!!
const SinarReport = ()  => {
    const auth = useAuth();        
    const user = auth.user;
        
    if(
        user.role === "regional" ||
        user.role === "lider"
    )
        return <LeaderSummary/>;
    else if(
        user.role === "coord" ||
        user.role === "director"
    )
        return <DirectorSummary/>;
    else
        return <AgentSummary/>;
}

export default SinarReport;
