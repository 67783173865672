import React, { useState } from "react";
import { useAuth } from "../auth/auth.js";
// import { Redirect, Link } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { default as MaterialLink } from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from './alert';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '500px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        fontWeight: "bold",
        color: "white",
    },
    switch: {
        color: theme.palette.primary.main,
        textDecoration: "none",
        cursor: "text",
        '&:hover': {
            textDecoration: "underline",
        },
    }
}));

const Register = ({ history }) => {
    const auth = useAuth();
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [error, setError] = useState("")
    const [showPassword,setShowPassword] = useState(false);
    const [loading,setLoading] = useState(false);
    
    //TODO: Error code translation!!!!
    //TODO: Some more validations!
    //TODO: Display name validation!
    
    const handleSignup = () => {
        auth.signup(email, password, name)
            .catch(e => {
                let decode="";
                switch(e.code){
                    //Thrown if there already exists an account with the given email address.
                    case "auth/email-already-in-use":
                        decode="El correo ya ha sido utilizado. Intenta iniciar sesión.";
                        setEmailError(true);
                        break;
                    //Thrown if the email address is not valid.
                    case "auth/invalid-email":
                        decode="El correo no es válido";
                        setEmailError(true);
                        break;
                    //Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.                        
                    case "auth/operation-not-allowed":
                        decode="Se ha deshablitado el registro usando correos y contraseñas. Contacta al administrador.";
                        break;
                    //Thrown if the password is not strong enough.
                    default:
                        setPasswordError(true);
                        decode="La contraseña es demasiado débil.";
                    
                }
                setError(decode);
            })
    }

    const handleEmailValidation = () => {
        if(
            /.+@(credifintech(.com)?.mx|viraal.com.mx|consubanco.com)/
                .test(email)
        )
            return true;
        else{
            setError(
                "El correo tiene que ser uno de la forma @credifintech.com.mx ó bien @viraal.com.mx"
            );
            setEmailError(true);
            return false;
        }
    }

    const handleNameValidation = () => {
        if(name.replace(/\s/g, '').length > 3)
            return true
        else{
            setError(
                "Ingresa tu nombre completo"
            );
            setNameError(true);
            return false;
        }
    }

    const onPasswordChange = e => {
        if(passwordError)
            setPasswordError(false);
        setPassword(e.target.value);
    }
    
    const onEmailChange = e => {
        if(emailError)
            setEmailError(false);
        setEmail(e.target.value);
    }
    
    const onNameChange = e => {
        if(nameError)
            setNameError(false);
        setName(e.target.value);
    }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        if(
            handleEmailValidation()
            && handleNameValidation()
        )
             handleSignup();
    }

    if (auth.user === null) {
        return (
            <div className="container">
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
        )
    } else if (auth.user) {
        return (<Redirect to='/login' />)
    } else {
        return (
            <Container component="main" maxWidth="xs">
                {/* <CssBaseline /> */}
                <Paper className={classes.paper} elevation={3}>
                    <Typography component="h1" variant="h5">
                        Regístrate
                    </Typography>

                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Nombre Completo"
                            name="name"
                            autoFocus
                            error = {nameError}
                            onChange={onNameChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            error = {emailError}
                            label="correo@credifintech.com.mx"
                            name="email"
                            autoFocus
                            onChange={onEmailChange}
                        />
                        <FormControl 
                            fullWidth 
                            variant="outlined" 
                            className={classes.form}
                        >
                            <InputLabel 
                                htmlFor="password"
                                error = {passwordError}
                            >
                                Contraseña
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                error = {passwordError}
                                labelWidth={85}
                                onChange={onPasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end"
                                        >
                                            {
                                                showPassword 
                                                ? <Visibility /> 
                                                : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={
                                    loading
                                    && (error === "")
                                }
                                onClick={handleSubmit}
                            >
                                {
                                    (loading && (error===""))
                                    ?<CircularProgress size={25}/>
                                    :"Registrate"}

                            </Button>
                            <Grid container>
                                <Grid item xs>

                                </Grid>
                                <Grid item>
                                    ¿Ya tienes cuenta? <Link to="/login" className={classes.switch}>{"Inicia Sesión"}</Link>
                                </Grid>
                            </Grid>
                    </form>
                        <Box mt={8}>
                            <Copyright />
                        </Box>
                        <Alert 
                            show={ error!=="" } 
                            setShow={()=>{setLoading(false);setError("")}}
                            type="error"
                            message={error}  
                            duration={3000}
                        ></Alert>
                </Paper>
            </Container>
        );
    }
}

function Copyright() {
    return (
                <Typography variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    <MaterialLink color="inherit" href="https://credifintech.com.mx/">
                        Credifintech
        </MaterialLink>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
    );
}

export default Register;
