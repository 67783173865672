import React, { useState, useEffect, SyntheticEvent} from 'react';
import {
    Box,
    Container,
    TextField,
    Button,
    Paper,
    Divider,
    Switch,
    FormControl,
    FormGroup,
    FormControlLabel
  } from '@material-ui/core';
import Header from '../../pageheader';
import Alert from '../../alert';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import "moment/locale/es";
import firebase from '../../../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import { useStyles } from "../hooks/style";

export const CreateCreditInfo = () => {

    const details2 = {
        title: "Crear información para calcular un crédito",
        status: "La información se utilizará para realizar una propuesta de crédito con base en la capacidad de pago de un cliente",
    };

    type formValuesType = { 
        name: string,
        maxAge:number,
        minAge:number,
        minCredit: number,
        fixRate:number,
        fixTerm:number
    };
    
    const textFieldNames = {
        name: "name",
        maxAge:"maxAge",
        minAge:"minAge",
        minCredit: "minCredit",
        fixRate: "fixRate",
        fixTerm: "fixTerm"
    }

    const textFieldHeaders = {
        name: "Casa Financiera",
        maxAge:"Edad máxima",
        minAge:"Edad mínima",
        minCredit: "Monto mínimo para autorización de crédito",
        fixRate: "Tasa anual sin IVA %",
        fixTerm: "Meses"
    }    
    
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState("");
    const [error, setError] = useState("");
    const [details, setDetails] = useState(details2);
    const [formValues, setFormValues] = useState<formValuesType>({
        name: "",
        maxAge: 0,
        minAge: 18,
        minCredit: 5000,
        fixRate: 0,
        fixTerm: 60});

    const classes = useStyles();

    const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setFormValues((prevValues: any) => ({
          ...prevValues,
          [name]: value,
        }));
    };

    /**
     * 
     * @param {boolean variable. If false, the button "Calcular capacidad" is able, true is disabled} disabledState 
     */
    const changeStatusOfCalcularButton = (active: boolean)  =>{
        const buttonCalculate = document.getElementById("submitButton");
        if (buttonCalculate !== null) {
            if (active) {
                buttonCalculate.classList.remove("Mui-disabled");
                buttonCalculate.tabIndex = 0;
            } else {
                //buttonCalculate.disabled = true;
                buttonCalculate.classList.add("Mui-disabled");
                buttonCalculate.tabIndex = -1;
            }
        }
    }


    const handleSubmit = async (event: { preventDefault: () => void; }) => {

        event.preventDefault();
        changeStatusOfCalcularButton(false);
        let request: { name: string,
            restrictions: {
                maxAge:number,
                minAge:number,
                minCredit: number
            },
            fixRate:number,
            fixTerm:number,
            enabled:boolean
        }  = {
            name : formValues.name,
            fixRate : formValues.fixRate * 1.16 / 100,
            restrictions : {
                maxAge: 1*formValues.maxAge,
                minAge:formValues.minAge,
                minCredit: formValues.minCredit,
            },
            fixTerm : formValues.fixTerm,
            enabled: true
        };

        const createCreditInformation = firebase
        .functions()
        .httpsCallable('callable-creditProposals-createCreditInformation');
        
        await createCreditInformation(request).then(r => {
            const response = {...r.data};
            setUpdated("Información agregada correctamente"); 
        }).catch(e => {
            setError("" + e);
        });
        changeStatusOfCalcularButton(true);
        setFormValues({
            name: "",
            maxAge: 0,
            minAge: 18,
            minCredit: 5000,
            fixRate: 0,
            fixTerm: 60});
    };


    let content = (
            <Box p={3}>
                <Header 
                    loading={false} 
                    pageName={details.title}
                    backPage={null}
                    pageDescription={details.status}
                    descriptionColor = {"green"}    
                />
                <Divider/>
                <Box className={classes.form}>

                    <form onSubmit={handleSubmit}>                    
                        <FormControl
                                className={classes.textfield}
                                margin="normal"
                                fullWidth
                        >
                            <TextField
                                required
                                label={textFieldHeaders.name}
                                id={textFieldNames.name}
                                name={textFieldNames.name}
                                className={classes.textfield}   
                                variant="outlined"
                                value={formValues.name}
                                onChange={handleInputChange}
                            />
                        
                            <Divider/>
                            <TextField
                                required
                                className={classes.textfield}
                                variant="outlined"
                                label={textFieldHeaders.minCredit}
                                margin="normal"
                                id={textFieldNames.minCredit}
                                name={textFieldNames.minCredit}
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                value={formValues.minCredit}
                                onChange={handleInputChange}

                                fullWidth
                            />
                            <TextField
                                required
                                className={classes.textfield}
                                variant="outlined"
                                label={textFieldHeaders.minAge}
                                margin="normal"
                                id={textFieldNames.minAge}
                                name={textFieldNames.minAge}
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                value={formValues.minAge}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <TextField
                                required
                                className={classes.textfield}
                                variant="outlined"
                                label={textFieldHeaders.maxAge}
                                margin="normal"
                                id={textFieldNames.maxAge}
                                name={textFieldNames.maxAge}
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                value={formValues.maxAge}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <Divider/>
                            <TextField
                                required
                                className={classes.textfield}
                                variant="outlined"
                                label={textFieldHeaders.fixRate}
                                margin="normal"
                                id={textFieldNames.fixRate}
                                name={textFieldNames.fixRate}
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                value={formValues.fixRate}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <TextField
                                required
                                className={classes.textfield}
                                variant="outlined"
                                label={textFieldHeaders.fixTerm}
                                margin="normal"
                                id={textFieldNames.fixTerm}
                                name={textFieldNames.fixTerm}
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                                value={formValues.fixTerm}
                                onChange={handleInputChange}
                                fullWidth
                            />
                            <Divider/>
                            <FormGroup>
                                <FormControlLabel className={classes.textfield} disabled control={<Switch />} label="Crédito desactivado" />
                            </FormGroup>
                            <Button type="submit" variant="contained" color="primary" id='submitButton'>
                                Crear
                            </Button>
                        </FormControl>  
                    </form>                  
                </Box>
            </Box>
        )

    
    return (
        <Box>
            <Box mt={3}>
                <Container>
                    <Paper elevation={3}>
                        {content}
                    </Paper>
                </Container>
            </Box>
            <Alert 
          show={updated !== "" || error !== ""}
          setShow={() => { setUpdated(""); setError(""); } }
          type={updated !== "" ? "success" : "error"}
          message={updated !== "" ? updated : error}
          duration={3000} overFab={undefined} copyText={undefined}            ></Alert>
        </Box>
    )
}

export default CreateCreditInfo;
