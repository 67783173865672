import React, { useEffect,useState } from 'react';
import Navbar from './navbar';
import Box from '@material-ui/core/Box';
import CoordWrapper from './coord';
import { useAuth } from "../auth/auth.js";
import AsesorWrapper from './asesor';
import LinearProgress from '@material-ui/core/LinearProgress';

const Home = () => {
    //since this is a protected route
    //user should be loaded.
    const auth = useAuth();
    const [screen,setScreen]=useState("mainScreen");


    //force token refresh on first login. (to update token emailVerified status)
    if(!auth.user.firstLogin)
        auth.firstLogin(auth.user);

    let content;
    if(auth.user.firstLogin===false)
        content=<Box mt={8}><LinearProgress color="primary"/></Box>;
    else{
        if(auth.user.role==="asesor")
            content=<AsesorWrapper/>
        else if(auth.user.role==="coord" || auth.user.role==="admin" || auth.user.role === "regional")
            content=<CoordWrapper/>
    }


    return (
        <Box>
            <Navbar/>
            {content}
        </Box>
    )

}

export default Home;
