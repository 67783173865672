import React, { useEffect,useState } from 'react';
import Navbar from '../navbar';
import Box from '@material-ui/core/Box';
import { useAuth } from "../../auth/auth.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import CreateCreditInfo from "./createCreditInfo/createCreditInfo";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import ShowgeneralCreditProposals from "./showCreditProposals/showCreditProposals";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    cardroot: {
        minWidth: 275,
    },
    expand: {
        justifyContent: 'flex-end',
    },
    btn: {
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    alert: {
        fontWeight: "bold",
        color: "white",
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

const GeneralCreditProposals = () => {
    const classes = useStyles();
    const [value, setValue] = useState("createCreditInfo");
    //since this is a protected route
    //user should be loaded.
    const auth = useAuth();

    //force token refresh on first login. (to update token emailVerified status)
    if(!auth.user.firstLogin)
        auth.firstLogin(auth.user);

    let content;
    if(auth.user.firstLogin===false)
        content=<Box mt={8}><LinearProgress color="primary"/></Box>;
    else{
        if(auth.user.role==="coord" || auth.user.role==="admin")
            content = <CreateCreditInfo/>;
        else {

        }
    }


    return (
        <Box>
            <Navbar/>
            <Box>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={(e, v) => setValue(v)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab value="createCreditInfo" label="Crear nuevo crédito" />
                        <Tab value="showgeneralCreditProposals" label="Mostrar créditos" />
                    </Tabs>
                </Paper>
                {value === 'createCreditInfo' && <CreateCreditInfo/>}
                {value === 'showgeneralCreditProposals' && <ShowgeneralCreditProposals/>}

            </Box>
        </Box>
    )

    

}

export default GeneralCreditProposals;
