import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Navbar from './navbar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../auth/auth';
import { beautifyName } from '../util/util';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import Header from './pageheader';
import Alert from './alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2),
    },
    saveButton: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3,0,8),
    },
    headerIcons:{
        paddingRight:theme.spacing(2),
    },
    chips:{
        marginTop: theme.spacing(1),
        display:"block",
    }
}));

const Profile = () => {
    const classes = useStyles();
    const auth = useAuth();
    const [error,setError]=useState("");
    const [updated,setUpdated]=useState("");
    const [loading, setLoading]=useState(false);
    const {email,name,postalCodes,completedRecords} = auth.user;
    const [newName,setNewName] = useState(name);
    const history=useHistory();


    const handleUpdateName = () =>{
        setLoading(true);
        auth.updateName(auth.user,newName)
            .then(()=>{
                setUpdated("Se ha actualizado el perfil");
                setLoading(false);
            })
            .catch(er=>{
                setError("Ha ocurrido un error actualizando el perfil");
                setLoading(false);
            })
    }


    return (
        <Box>
            <Navbar/>
            <Box mt={3}>
                <Container component="main">
                    <Paper className={classes.paper} elevation={3}>
                        <Header 
                            loading={false} 
                            pageName={"Ver Perfil"}
                            backPage={"/"}
                            pageDescription={"detalles del perfil"}
                            descriptionColor={"#757575"}
                        />
                        <Divider className={classes.divider}/>
                        <Box className={classes.form}>
                            <TextField
                                variant="outlined"
                                label="Nombre"
                                defaultValue={name}
                                margin="normal"
                                id="nombre"
                                name="nombre"
                                onChange={e=>setNewName(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                label="Email"
                                defaultValue={email}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                id="email"
                                name="email"
                                fullWidth
                                disabled
                            />
                            <ChipInput
                                className={classes.chips}
                                defaultValue={postalCodes}
                                placeholder={postalCodes.length>0?"":"No tienes ningun código postal asignado"}
                                fullWidth
                                label={postalCodes.length>0?"Codigos Postales":"No tienes ningun código postal asignado"}
                                labelwidth={80}
                                disabled
                                variant="outlined"  
                                chipRenderer={(
                                    {
                                        value,
                                        isFocused,
                                        isDisabled,
                                        isReadOnly,
                                        handleClick,
                                        handleDelete,
                                        className
                                    },
                                    key
                                ) => (
                                        <Chip
                                            disabled
                                            key={key}
                                            className={className}
                                            label={value}
                                        />
                                    )}
                            />
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                                className={classes.saveButton} 
                            >
                                <Grid item xs={3}>
                                    <Button 
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUpdateName}
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={25}/>:"Guardar"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Container>
            </Box>
            <Alert 
                show={updated!=="" || error!=="" } 
                setShow={()=>{setUpdated("");setError("")}}
                type={updated!==""?"success":"error"}
                message={updated!==""?updated:error}
                duration={3000}
            ></Alert>

        </Box>
    )
}

export default Profile;
