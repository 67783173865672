import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import MuiAlert from '@material-ui/lab/Alert';
import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {useViewport} from "../viewport/viewport.js";
import IconButton from '@material-ui/core/IconButton';
import {copyToClipboard} from '../util/util.js';

const useStyles = makeStyles((theme) => ({
    overFab:{
        [theme.breakpoints.down('xs')]: {
            bottom: 90,
        },
    },
    normal:{
        
    }
}));

const Alert = ({show,setShow,message,duration,type,overFab,copyText}) =>{
    const classes=useStyles();
    const [pressed, setPressed] = useState(false);
    const { width } = useViewport();

    const anchor=width<500?
            {
                vertical: 'bottom',
                horizontal: 'center',
            }
        :
            {
                vertical: 'top',
                horizontal: 'center',
            };

    const handleClose = () => {
        setShow(!show); 
        setPressed(false);
    }

    const handleCopy = () => {
        copyToClipboard(copyText)
        setPressed(true); 
    }

    return (
        <Snackbar
            anchorOrigin={anchor}
            open={show}
            className={overFab?classes.overFab:classes.normal}
            autoHideDuration={duration}
            onClose={handleClose}
        >
            <MuiAlert 
                onClose={handleClose} 
                variant="filled" 
                severity={type}
                action={
                    !copyText ? 
                        <IconButton size={"small"} onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    :
                        <Button 
                            onClick={handleCopy}
                            disabled={pressed}
                        >
                            {pressed?"COPIADO!":"COPIAR"}
                        </Button>

                }
            >
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

export default Alert;
