import React from 'react';
import { Box, Typography } from '@material-ui/core';

export function setHeader(CP, CAMPAIGNS) {
  let subheader;

  if (CAMPAIGNS) {
    const plural = CAMPAIGNS.length > 1 ? "s " : " ";
    subheader = "para campaña" + plural + CAMPAIGNS.join(",");
  } else if (CP) {
    subheader = "de código postal " + CP;
  }  else {
    subheader = "";
  }
  return subheader;
}

export function setAvailableFields(lead) {
  const resultJSX = [];
  for (let key in lead.valid_fields) {
    const value = lead.valid_fields[key];
    if (value && key in lead  && !(key === "NOMBRE" || key === "CONVENIO" || key === "TELEFONO" || key === "EXTRA")) {
      resultJSX.push(
        <Box key={key}>
          <Typography variant="body1">{key}: {lead[key]}</Typography>
        </Box>
      );
    }
  }

  return <React.Fragment>{resultJSX}</React.Fragment>; // Wrap the resultJSX array with a fragment or a parent element
}