import React, { useEffect, useState } from 'react';
import { useAuth } from "../auth/auth.js";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';
import {beautifyFirstName} from '../util/util';
import Box from "@material-ui/core/Box";
import CustomDrawer from './customdrawer';
import { useHistory } from "react-router-dom";

const drawerWidth=275;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    '&hover':{
      cursor:'text',
      color:theme.palette.secondary.main,
      // textDecoration:'underline',
    }
  },
}));

const Navbar = () => {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [open,setOpen]=useState(false);
  const history = useHistory();

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Typography onClick={()=>history.push('/')} variant="h6" className={classes.title}>
            credifintech
          </Typography>
          <Box>
            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={(e)=>setAnchorEl(e.currentTarget)}
              onClick={()=>setOpen(!open)}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={()=>setOpen(!open)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
            {/* <Menu
              id="menu-appbar"
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={()=>setAnchorEl(null)}
            >
              <MenuItem disabled>{beautifyFirstName(auth.user.name)}</MenuItem>
              <Divider variant="middle" />
              <MenuItem onClick={()=>{setAnchorEl(null);setScreen("profileScreen");}}>Ver Perfil</MenuItem>
              <MenuItem onClick={()=>{auth.signout();setAnchorEl(null)}}>Cerrar Sesión</MenuItem>
            </Menu> */}
          </Box>
        </Toolbar>
      </AppBar>
      <CustomDrawer open={open} setOpen={setOpen}/>
    </Box>
  )
}

export default Navbar;
