import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "../firebase/firebaseInit";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import Bowser from "bowser";
import array from 'lodash';
import {removeAccentMarks} from '../util/util';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email,password)
  };

  const updateFirstLogin = (user) =>{
    return firebase
      .firestore()
      .collection("users")
      .doc(user.multiFactor.user.uid)
      .update({
        firstLogin:true,
      })
  }

  const isCurrentEmailLink = () =>{
    return firebase.auth().isSignInWithEmailLink(window.location.href)
  }

  const handleEmailLinkSignIn = (email) =>{
    if (isCurrentEmailLink) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.

      // const email = window.prompt('Please provide your email for confirmation');

      // The client SDK will parse the code from the link for you.
      return firebase.auth().signInWithEmailLink(email, window.location.href)
    } else{
        return Promise.reject({
          code:'not-signin-link',
          message:'Link is not an email link.'
        });
    }
  }

  const firstLogin = (user) =>{
    return updateFirstLogin(user)
      .then(()=>{return refreshIdToken();})
  }

  const createSearchIndices = (email, name) => {
    const e = removeAccentMarks(email).toLowerCase();
    const n = removeAccentMarks(name).toLowerCase();
    const searchIndices = new Set();
    const fromEmail = e
        .split("@")[0]
        .split(".")  
        .filter(x => x !== "");
    const fromName = n
        .split(" ")
        .filter(x => x != "");
    fromEmail.forEach(x => searchIndices.add(x)) 
    fromName.forEach(x => searchIndices.add(x)) 
    return Array.from(searchIndices);
  }

  const addCustomUserFirestore = (user,name) => {
    return firebase
      .firestore()
      .collection("users")
      .doc(user.multiFactor.user.uid)
      .set({
        created:firebase.firestore.FieldValue.serverTimestamp(),
        email:user.email,
        name:name,
        role: "asesor",
        mode: "CAMPAIGNS",
        alternate : false,
        disabled: false,
        searchIndices: createSearchIndices(user.email, name),
        assignedCount:0,
        completedRecords:0,
        campaigns: {},
        coord:"",
        postalCodes:[],
        firstLogin:false,
        status: {
          state: 'offline',
          last_changed: firebase.firestore.FieldValue.serverTimestamp(),
        }
      }).then(()=>{return user})
  }

  const addCustomUserRTDB=(user)=>{
    return firebase
      .database()
      .ref('/status/'+user.multiFactor.user.uid)
      .set({
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP
      }).then(r=>{
        return user;
      })
  }

  const updateFirestoreName = (user,name) =>{
    return firebase.firestore()
      .collection("users")
      .doc(user.multiFactor.user.uid)
      .update({name:name})
  }


  //TODO: Checks (updated, only errors allowed) should not be done here.
  const updateName = (user,name) => {
    return updateDisplayName(user,name)
      .then(u=>{return updateFirestoreName(u,name)})
      .then(u=>{
        setUser({...user,name:name});
        return u;
      })
  } 

  //TODO: find way arround this
  const updateDisplayName = (user,name) => {
    return firebase.auth()
      .currentUser
      .updateProfile({
        displayName:name
      }).then(()=>{return user})
  }

  const sendEmailVerification = (user) => {
    return firebase
      .auth()
      .currentUser
      .sendEmailVerification()
      .then(()=>{return user})
  }

  const signup = (email, password, name) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {return response.user;})
      .then(user => {return addCustomUserRTDB(user)})
      .then(user => {return addCustomUserFirestore(user,name)})
      .then(user => {return updateDisplayName(user,name)}) 
      .then(user => {return sendEmailVerification(user)})
  };

  const signOutRTDB = (uid) =>{
    const rtdb=firebase.database();
    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: firebase.database.ServerValue.TIMESTAMP
    };
    var userStatusDatabaseRef = rtdb.ref('/status/' + uid);
    return userStatusDatabaseRef.set(isOfflineForDatabase);
  }

  const signout = async () => {
    // return firebase
    //   .auth()
    //   .signOut()
    //   .then(() => {
    //     goOffline(user.uid);
    //     setUser(false);
    //   });
    await signOutRTDB(user.multiFactor.user.uid);
    await firebase.auth().signOut();
    setUser(false);
    return;
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const refreshIdToken = () =>{
    return firebase.auth()
      .currentUser
      .getIdToken(true);
  }

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const fetchFirestoreUser = (user) => {
    return firebase
      .firestore()
      .collection("users")
      .doc(user.multiFactor.user.uid)
      .get()
      .then(res=>{return {...user,...res.data()};})
  }


  const updateUserDevices = (uid,devices) => {
    const newDevice=Bowser.getParser(window.navigator.userAgent).parsedResult;
    let shouldBeAdded=true;
    //check if device is already in devices and add it if not
    for(const device of devices)
      if(array.isEqual(device,newDevice))
        shouldBeAdded=false
    if(shouldBeAdded)
      devices.push(newDevice);
    //update devices property
    return firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        devices: devices
      });
  }

  const handlePresence=(uid) => {
    const rtdb=firebase.database();
    const db=firebase.firestore();
    rtdb.goOnline();
    var userStatusDatabaseRef = rtdb.ref('/status/' + uid);
    var userStatusFirestoreRef = db.doc(`users/${uid}/`);

    const newDevice=Bowser.getParser(window.navigator.userAgent).parsedResult;

    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      device: newDevice,
    };
        
    const isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
        device: newDevice,
    };
    
    
    const isOfflineForFirestore = {
        status: {
            state: 'offline',
            last_changed: firebase.firestore.FieldValue.serverTimestamp(),
        }
    };
  
    const isOnlineForFirestore = {
        status: {
            state: 'online',
            last_changed: firebase.firestore.FieldValue.serverTimestamp()
        }
    };


    rtdb.ref('.info/connected').on('value', function(snapshot) {
        if (snapshot.val() === false) {
            userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true }).catch(e=>console.log("244",e));
            return;
        }

        if (snapshot.val() === true){
          userStatusDatabaseRef.set(isOnlineForDatabase).catch(e=>console.log("249",e));
        }
        
        userStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            // .then(function() {
            //     userStatusDatabaseRef.set(isOnlineForDatabase);
            //     userStatusFirestoreRef.set(isOnlineForFirestore, { merge: true });
            // });
        });


  }

  //we can't rely on firestore's data being
  //accurate all the time on any given time
  //we must therefore check rtdb
  const userAlreadyOnline = (uid) =>{
    const rtdb=firebase.database();
    rtdb.goOnline();
    const userStatusDatabaseRef = rtdb.ref('/status/' + uid);
    return userStatusDatabaseRef.once('value')
      .then(dataSnapshot=>{
        console.log(dataSnapshot.exists())
        if(dataSnapshot.exists())
          return dataSnapshot.val().state==='online'
        else
          return false;
      })
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async user => {
      if (user) {
        fetchFirestoreUser(user)
          .then(newUser=>{
            // // const {state,last_changed}=newUser.status;
            handlePresence(user.multiFactor.user.uid);
            
            setUser(newUser);
            // //update devices data
            const devices=newUser.devices?newUser.devices:[];
            updateUserDevices(user.multiFactor.user.uid,devices);
          })
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  
  
  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateName,
    sendEmailVerification,
    firstLogin,
    isCurrentEmailLink,
    handleEmailLinkSignIn,
    refreshIdToken,
  }
}
