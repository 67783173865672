import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Navbar from '../navbar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../../auth/auth';
import { beautifyName } from '../../util/util';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import Header from '../pageheader';
import Alert from '../alert';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Divider from '@material-ui/core/Divider';
import DragAndDropFiles from '../dragdropfiles';
import CampaignsInfo from '../../campaignsinfo/campaignsinfo';

const useStyles = makeStyles((theme) => ({
    dragndrop: {
    },
    divider: {
        margin: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
    },
    inputWrapper: {
        height: "500px",
        width: "100%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
    },
         
    labelInput: {
        width: "100%",
        height: "100%",
        borderStyle: "dashed",
    }, 
    fileInput: {
        width:"100%",    
        height: "100%",
        display:"none",
    },

}));

const NewCampaign = () => {

    const classes = useStyles();
    const auth = useAuth();
    const [error,setError] = useState("");
    const [updated,setUpdated] = useState("");
    const [loading, setLoading] = useState(false);
    const history=useHistory();
    const {
        uploadCampaignFile,
    } = CampaignsInfo();

    const handleOnAccept = (files) => {
        const file = files[0];
        //console.log(file);
        setLoading(true);
        const name = "DOM201120";
        const path = `campaigns/${name}/${file.name}`
        uploadCampaignFile(file,path,auth.user.multiFactor.user.uid)
            .then(() => {
                setLoading(false);
                setUpdated("El archivo fue cargado exitosamente");
            })
            .catch(e => {
                console.log(e);
                // TODO: 
                // beautify error
                // to show user!!
                setLoading(false);
                setError("El archivo no se cargó correctamente");
            });
    }

    const promptText = "Arrastra el archivo con extensión json que contenga la campaña a crear. Recuerda que los contactos deberan de tener al menos uno de los siguientes campos además del telefono: RFC, CURP, Matricula";

    return (
        <Box>
            {/* <Navbar/> */}
            <Box mt={3}>
                <Container component="main">
                    <Paper className={classes.paper} elevation={3}>
                        <Header 
                            loading={false} 
                            pageName={"Crear Campaña"}
                            backPage={"/"}
                            pageDescription={"Crear una nueva campaña"}
                            descriptionColor={"#757575"}
                        />
                        <Divider className={classes.divider}/>
                        <Grid
                            container
                            direction = "row"
                            justify = "center"
                            alignItems = "center"
                        >
                            <Grid item xs={10} sm={8}>
                                <DragAndDropFiles
                                    className={classes.dragndrop}
                                    multiple={true}
                                    extensions={".json"}
                                    promptText = {promptText}
                                    acceptButtonText = {"subir campaña"}
                                    acceptButtonLoading = {loading}
                                    onAccept = {handleOnAccept}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Box>
            <Alert 
                show={updated!=="" || error!=="" } 
                setShow={() => {setUpdated("");setError("")}}
                type={updated!==""?"success":"error"}
                message={updated!==""?updated:error}
                duration={3000}
            ></Alert>
        </Box>
    )
}

export default NewCampaign;
