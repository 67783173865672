export class Option {
    constructor(option, category, alias) {
        this.option = option;
        this.category = category;
        this.alias = alias ? alias : [];
    }

    isAliasOf(option) {
        return this.alias.includes(option);
    }
}

export class Options {
    constructor(options){
        this.options = options ? options : [];
    }

    addOption(option){
       this.options.push(option); 
    }

    map(f){
        return this.options.map(f);
    }

    fromOption(option){
        for(let o of this.options){
            if(o.option === option || o.isAliasOf(option))
                return o;
        }
        return new Option(option, "undefined");
    }
    
}

const contactOptions = new Options([
    new Option("No tiene capacidad de crédito","negative"),
    new Option("No responde","negative"),
    new Option("El cliente ha fallecido","negative"),
    new Option("No es el cliente","negative"),
    new Option("El número no existe","negative"),
    new Option("No vive ahí","negative"),
    new Option("No quiere que le vuelvan a marcar","negative"),
    new Option("Validar capacidad de crédito","positive",["Se inicia trámite de crédito"]),
    new Option("Necesita más información","later"),
]);

export default contactOptions;

