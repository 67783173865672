import React, { useEffect, useState } from 'react';
import {KeyboardArrowLeft,KeyboardArrowRight,Assignment} from '@material-ui/icons';
import {Grid,
    Typography,
    Button,
    Divider,
    Box,
    List
} from '@material-ui/core';
import Header from './pageheader';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../firebase/firebaseInit';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import jsonexport from 'jsonexport';

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(2),
    }
}));

function convertAndDownloadCSV(jsonData, fileName) {
    // Convert JSON to CSV format
    jsonexport(jsonData, function (err, csv) {
      if (err) {
        console.error(err);
        return;
      }
  
      // Create a Blob object with CSV data
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);
  
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Trigger a click event on the link to initiate the download
      link.click();
  
      // Cleanup by removing the link and revoking the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  }
  


  const downloadUsersReport = async () => {
    const getAllUserStatus = firebase.functions()
    .httpsCallable('callable-user-getAllUserStatus');

    await getAllUserStatus().then(
        (response) => {
            convertAndDownloadCSV(JSON.parse(response.data.message), "userReport.csv")
        }).catch(e => {
            console.log(e);
        });


};

const Footer = ({next, disableNext, prev, disablePrev, downloadUsersReport2}) =>{
    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
        >
            <Grid item>
                <Button disabled={disablePrev} onClick={prev}>
                    <KeyboardArrowLeft/>
                    Anterior
                </Button>
            </Grid>
            {downloadUsersReport2 &&<Grid item>
                <Button onClick={downloadUsersReport}>
                    <Assignment/>
                    Reporte General de Usuarios
                </Button>
            </Grid>}
            <Grid item>
                <Button disabled={disableNext} onClick={next}>
                    Siguiente
                    <KeyboardArrowRight/>  
                </Button>
            </Grid>
        </Grid>
    )
}


/**
 * Paginate Component
 *
 * A reusable component for rendering paginated data with optional search functionality.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.getData - Function to fetch data for rendering. It is called with a limit and search criteria parameters. 
 *                                      Search criteria is optional, if you don't need to use it send {}
 * @param {number} props.limit - Maximum number of items per page.
 * @param {Function} props.renderBlock - Function to generate content as a block.
 * @param {Function} props.renderElem - Function to generate content for individual items.
 * @param {string} props.headerName - Name/title of the page.
 * @param {string} props.headerDescription - Description associated with the page.
 * @param {string} props.descriptionColor - Color of the description.
 * @param {Function|null} props.backPage - Callback function for the back button.
 * @param {string} props.emptyMessage - Message when there are no items to render.
 * @param {boolean} props.enableSearch - Enable search functionality.
 * @param {string} props.searchPlaceholder - Placeholder text for the search input.
 * @param {boolean} props.downloadUsersReport - Placeholder boolean for the report button.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const Paginate = (props) => {
    const {
            getData, 
            limit, 
            renderBlock,  
            renderElem, 
            headerName, 
            headerDescription, 
            descriptionColor,
            backPage,
            emptyMessage,
            enableSearch,
            searchPlaceholder,
            downloadUsersReport
    } = props;
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null); 
    const [hideNext, setHideNext] = useState(false);
    const [newPage, setNewPage] = useState(-1); 
    const [force, setForce] = useState(false);
    const [search, setSearch] = useState("");

    const handleSearch = (search) => {
        setSearch(search);    
        reload();
    }

    const reload = () => {
        setPage(0);
        setNewPage(-1); 
        setForce(!force); 
    }

    useEffect(() => {
        const handlePageChange = () => {
            const params = {}
            if(search !== "")
                params["searchBy"] = search;
            setLoading(true);
            if(newPage !== -1){
                if(newPage>page)
                    params["startAfter"] = data[data.length-1];
                else
                    params["endAt"] = data[0];

            }
            getData(limit + 1, params).then(arr=>{
                setLoading(false);
                setHideNext(arr.length !== limit+1 && !params['endAt']);
                if(newPage !== -1)
                    setPage(newPage);
                setData(arr.slice(0,limit));
            }).catch(e=>{
                setLoading(false);
            });
        }
        handlePageChange();
    },[newPage, force])


    let body; 
    if(data === null)
        body = <Box/>
    else if(data.length === 0)
        body = <Typography children={emptyMessage}/>
    else if(renderElem && !renderBlock)
        body = (
            <List> 
                {
                    data.map((e, i) => {
                        return renderElem(
                            e, 
                            (page * limit) + i,
                            reload
                        );
                    })
                }
            </List>
        );
    else
        body = renderBlock(data);
    return (
        <Box>
            <Header 
                loading={loading} 
                pageName={headerName}
                pageDescription={headerDescription}
                descriptionColor={descriptionColor}
                handleSearch={handleSearch}
                enableSearch={enableSearch}
                searchPlaceholder={searchPlaceholder}
                backPage={backPage}
            /> 
            <Divider className={classes.divider}/>
                {body}
            <Divider className={classes.divider}/>
            <Footer 
                disableNext={hideNext} 
                disablePrev={page === 0}
                prev={()=>{setNewPage(page - 1)}}
                next={()=>{setNewPage(page + 1)}}
                downloadUsersReport2 = {downloadUsersReport}
            />
        </Box>
    )
}

export default Paginate;
