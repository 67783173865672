import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ChipInput from 'material-ui-chip-input';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import {summarize,timeAgo} from '../util/util';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useViewport} from '../viewport/viewport.js';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    typographyMargin: {
        margin: 5,
    },
    menu: {
        minWidth: 200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cardroot: {
        minWidth: 275,
        marginTop: 8,
        bottomPadding: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    title: {
        marginTop: 10,
    },
    info: {
        marginDown: 0,
    },
    infoLabel: {
        marginTop: 0,
    },
    noBottomPadding: {
        paddingBottom: 2,
    },
    noYPadding: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    cpAdd: {
        marginLeft: 'auto',
    },
    noPadding: {
        padding: 2,
        '&:last-child': {
            paddingBottom: 2,
        },
    },
    number:{
        fontWeight:"bold",
        marginLeft:theme.spacing(1),
        marginRight:theme.spacing(1),
    },
    lastConection:{
        marginLeft:theme.spacing(1),
    },
    positive:{
        fontWeight:"bold",
        color:green[500],
        marginLeft:theme.spacing(1),
        marginRight:theme.spacing(1),

    },
    later:{
        fontWeight:"bold",
        color:amber[500],
        marginLeft:theme.spacing(1),
        marginRight:theme.spacing(1),
    },
    negative:{
        fontWeight:"bold",
        color:red[500],
        marginLeft:theme.spacing(1),
        marginRight:theme.spacing(1),
    },
}));

const GreenTextTypography = withStyles({
    root: {
      color: "green"
    }
  })(Typography);

  const RedTextTypography = withStyles({
    root: {
      color: "red"
    }
  })(Typography);

const formatTextToTypography = (key,number,neutral,other,cls) => {
    return (
        <Grid 
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
            key={key}
        >
            <Grid item >
                <Typography color="primary" className={cls} variant="h6" >
                    {number}
                </Typography>
            </Grid>
            <Grid item >
                <Typography >
                    {neutral} 
                </Typography>
            </Grid >
            {other&&
                <Grid item><Typography color="secondary" >
                {other}
            </Typography></Grid>}
        </Grid>
    );
}

const CoordCard = (props) => {

    const { asesor,uid,setPostalCodes,removeFromCoordList,setError,setUpdated} = props;    
    const { name,status,postalCodes,completedRecords,email,positiveAnswers,laterAnswers,negativeAnswers } = asesor
    const {state,last_changed}=status;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [wheel, setWheel] = useState(false);
    const [more, setMore] = useState(false);
    const [cps, setCps] = useState(postalCodes);
    const { width } = useViewport();

    const handleAddChip = (newChip)=>{
        if(newChip.length!==5){
            setError("El codigo postal debe de tener exactamente 5 caracteres.");
            return;
        }
        if(!(/^\d+$/.test(newChip))){
            setError("El codigo postal debe contener únicamente dígitos.")
            return
        }
        if(!cps.includes(newChip))
            setCps([...cps,newChip])
    }

    const handleDeleteChip = (index,chip)=>{
        setCps(cps.filter((e,i)=>{return i!==index}))
    }

    const handleSaveCps = () =>{
        setPostalCodes(uid,cps)
        .then(()=>{
            setUpdated("Se han actualizado los códigos postales correctamente");
            setMore(false)
        })
        .catch(e=>{
            setError("Ha ocurrido un error al actualizar codigos postales");
        })
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleRemoveAsesor = () => {
        setWheel(true);
        removeFromCoordList(uid)
            .then(() => {
                setUpdated("Se ha eliminado el asesor de tu lista.");
                setAnchorEl(null);
                setWheel(false);
            })
            .catch(e => {
                setError("Ha ocurrido un error al eliminar al asesor de tu lista");
                setWheel(false);
            });
    }

    return (
        <Card className={classes.cardroot}>
            <CardHeader
                className={classes.noBottomPadding}
                action={
                    <IconButton onClick={e => setAnchorEl(e.currentTarget)} aria-label="agregar codigo postal">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={name}
                subheader={summarize(email,25*(width/320)) }
            />
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={anchorEl !== null }
                onClose={handleClose}
            >
                <MenuItem className={classes.menu} onClick={handleRemoveAsesor}>
                    {wheel||anchorEl===null?<CircularProgress size={30}/>:"Eliminar de mis asesores"} 
                </MenuItem>
            </Menu>
            <CardActions className={classes.noYPadding} disableSpacing>
                {/* <Typography className={classes.lastContection} component="h1" variant="body2">última actividad hace 1 hora</Typography> */}
                    
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        {
                        state==="online"?
                            <GreenTextTypography variant="body2" className={classes.lastConection}>en línea</GreenTextTypography>
                        :
                            <RedTextTypography variant="body2" className={classes.lastConection}>{"última conexión hace "+timeAgo(last_changed.toDate())}</RedTextTypography>
                        }

                    </Grid>
                    <Grid item>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: more,
                            })}
                            onClick={(e) => {setMore(!more)}}
                            aria-expanded={more}
                            aria-label="mostrar mas"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardActions>
            <Collapse in={more} timeout="auto" unmountOnExit>
                <CardContent >
                    {completedRecords>=0 && formatTextToTypography(0,completedRecords," registros completados",null,classes.number)}
                    {positiveAnswers>=0 && formatTextToTypography(1,positiveAnswers," respuestas positivas de clientes",null,classes.positive)}
                    {laterAnswers>=0 && formatTextToTypography(2,laterAnswers," respuestas de interés de clientes",null,classes.later)}
                    {negativeAnswers>=0 && formatTextToTypography(3,negativeAnswers," respuestas negativas de clientes",null,classes.negative)}
                </CardContent>
                <Divider />
                <CardActions disableSpacing>
                    <ChipInput
                        value={cps}
                        placeholder="codigos postales"
                        fullWidth
                        onAdd={(chip)=>handleAddChip(chip)}
                        onDelete={(chip,index)=>handleDeleteChip(index,chip)}
                        newChipKeyCodes={[188,13,32]}
                        alwaysShowPlaceholder
                        chipRenderer={(
                            {
                                value,
                                handleClick,
                                handleDelete,
                                className
                            },
                            key
                        ) => (
                                <Chip
                                    color="primary"
                                    key={key}
                                    className={className}
                                    onClick={e => { console.log(cps); handleClick(e) }}
                                    onDelete={e => { setCps(cps.filter(e => e != value)); handleDelete(e) }}
                                    label={value}
                                />
                            )}
                    />
                    <IconButton onClick={handleSaveCps} className={classes.cpAdd}>
                        <CheckIcon />
                    </IconButton>
                </CardActions>
            </Collapse>
        </Card >
    )
}

export default CoordCard;
